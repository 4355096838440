import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import { Box } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "#F3F3F3",
    color: "#858585",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F7F3FC",
    color: "#1C1C1C",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#FFFFFF",
    color: "#1C1C1C",
    border: 0,
  },
  // hide last border
  "&:last-child td, &:last-child th, & td, & th": {
    border: 0,
    fontFamily: "'Aventa', sans-serif",
  },
}));

function createData(name, date, branch, asset, amount) {
  return { name, date, branch, asset, amount };
}

const rows = [
  createData(
    "Gbadamosi Taiwo",
    dayjs().format("DD/MM/YYYY"),
    "Idumota, Lagos",
    "Toyota corolla ‘07 | V6 | Sedan",
    "₦ 2,300,000.00"
  ),
  createData(
    "Gbadamosi Taiwo",
    dayjs().format("DD/MM/YYYY"),
    "Idumota, Lagos",
    "Toyota corolla ‘07 | V6 | Sedan",
    "₦ 2,300,000.00"
  ),
  createData(
    "Gbadamosi Taiwo",
    dayjs().format("DD/MM/YYYY"),
    "Idumota, Lagos",
    "Toyota corolla ‘07 | V6 | Sedan",
    "₦ 2,300,000.00"
  ),
];

export default function Members() {
  return (
    <TableContainer component={Paper} elevation={0} sx={{ height: "100%" }}>
      <Table
        aria-label="customized table"
        sx={{ height: "100%", minWidth: { xs: 900, md:1000, lg: "auto" } }}
      >
        <TableHead>
          <TableRow>
            <StyledTableCell align="left"></StyledTableCell>
            <StyledTableCell align="left">NAME</StyledTableCell>
            <StyledTableCell align="left">DATE</StyledTableCell>
            <StyledTableCell align="left">BRANCH</StyledTableCell>
            <StyledTableCell align="left">ASSET</StyledTableCell>
            <StyledTableCell align="left">AMOUNT</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.date}>
              <StyledTableCell align="left">
                <Box
                  sx={{
                    width: { xs: "20px", sm: "30px", lg: "40px" },
                    height: { xs: "20px", sm: "30px", lg: "40px" },
                    borderRadius: "50px",
                    backgroundColor: "#D9D9D9",
                  }}
                ></Box>
              </StyledTableCell>
              <StyledTableCell align="left" component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="left">{row.date}</StyledTableCell>
              <StyledTableCell align="left">{row.branch}</StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{ display: "inline-flex", alignItems: "center", height:"100%", gap:1 }}
              >
                <Box
                  sx={{
                    width: { xs: "20px", sm: "30px", lg: "40px" },
                    height: { xs: "20px", sm: "30px", lg: "40px" },
                    borderRadius: "50px",
                    backgroundColor: "#5634D1",
                  }}
                ></Box>
                {row.asset}
              </StyledTableCell>
              <StyledTableCell align="left">{row.amount}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
