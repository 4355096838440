export const navbarStyles = {
    drawer: {
        width: 320,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
            width: 320,
            paddingTop: '1rem',
            boxSizing: 'border-box',
            backgroundColor: '#ffffff',
            // color: 'rgba(255, 255, 255, 0.7)',
            color: 'black',
        },
        '& .Mui-selected': {
            color: 'blue',
        },
    },
    icons: {
        // color: 'rgba(255, 255, 255, 0.7)!important',
        marginLeft: '20px',
        color: "black"
    },
    text: {
        '& span': {
            marginLeft: '-10px',
            fontWeight: '600',
            fontSize: '16px',
        }
    }
};