import DashboardIcon from '@mui/icons-material/Dashboard';
import PaymentsIcon from '@mui/icons-material/Payments';
import PersonIcon from '@mui/icons-material/Person';

export const dashboardItems = [
    {
        id: 0,
        icon: <DashboardIcon />,
        label: 'Dashboard',
        route: "/account/cooperative/dashboard"
    },
    {
        id: 1,
        icon: <PaymentsIcon />,
        label: 'Payment',
        route: "/account/cooperative/payment"
    },
    {
        id: 2,
        icon: <PersonIcon/>,
        label: 'Profile',
        route: "/account/cooperative/profile"
    },
]