import { Box, Divider, Grid, Link, Paper, Typography } from "@mui/material";
import Nav from "../../../../components/navs/dasboardNav";
import Salutation from "../../../../components/salutation";
import PaymentAction from "./components/paymentActions";
import Members from "./components/members";


const CoopMembers = () => {
  const Comp = () => {
    return (
      <Box>
        <Salutation />
        <Typography
          variant="subtitle1"
          fontSize={"0.8rem"}
          fontWeight={600}
          mt={6}
        >
          QUICK ACTIONS
        </Typography>
        <PaymentAction />
        <Box mt={6}>
          <Grid
            container
            spacing={{ xs: 2, xl: 4 }}
            sx={{ paddingRight: { xs: 4, md: 5, lg: 5 } }}
          >
            <Grid item xs={12} xl={8} sx={{ height: "100%" }}>
              <Typography
                variant="subtitle1"
                fontSize={"0.8rem"}
                fontWeight={600}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span>Recent Transactions</span>
                <Link href="">SEE FULL</Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Grid
          container
          spacing={{ xs: 2, xl: 4 }}
          pt={2}
          sx={{ paddingRight: { xs: 4, md: 5, lg: 5 } }}
        >
          <Grid item xs={12} xl={8} sx={{ height: "100%" }}>
            <Paper
              square
              elevation={0}
              sx={{
                minHeight: {
                  xs: "15rem",
                  md: "18rem",
                  lg: "20rem",
                  xl: "20rem",
                },
                paddingRight: { xs: 0, sm: 2, lg: 5 },
                // paddingY: 3,
                display: "flex",
              }}
            >
              <Box
                sx={{
                  width: { xs: "2%", md: "3%" },
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#DBDBDB",
                    height: "80%",
                    width: { xs: "2rem", sm: "100%" },
                  }}
                ></Box>
              </Box>
              <Box width={"100%"}>
                <Members />
              </Box>
            </Paper>
          </Grid>
        </Grid>

      </Box>
    );
  };

  return <Nav active="payment" activePage={1} comp={Comp} />;
};

export default CoopMembers;
