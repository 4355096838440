import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: "#F3F3F3",
    color: "#858585",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  
  "&:nth-of-type(odd)": {
    backgroundColor: "#F7F3FC",
    color: "#1C1C1C",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#FFFFFF",
    color: "#1C1C1C",
    border: 0,
  },
  // hide last border
  "&:last-child td, &:last-child th, & td, & th": {
    border: 0,fontFamily: "'Aventa', sans-serif",
  },
}));

function createData(amount, date, balance) {
  return { amount, date, balance };
}

const rows = [
  createData("- ₦ 7,956", dayjs().format("DD/MM/YYYY"), "₦ 2,300,000.00"),
  createData("- ₦ 7,956", dayjs().format("DD/MM/YYYY"), "₦ 2,300,000.00"),
  createData("- ₦ 7,956", dayjs().format("DD/MM/YYYY"), "₦ 2,300,000.00"),
];

export default function Transactions() {
  return (
    <TableContainer component={Paper} elevation={0} sx={{ height: "100%" }}>
      <Table
        aria-label="customized table"
        sx={{ height: "100%", minWidth: { xs: 340, sm: "auto" } }}
      >
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">AMOUNT</StyledTableCell>
            <StyledTableCell align="center">DATE</StyledTableCell>
            <StyledTableCell align="center">BALANCE</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.date}>
              <StyledTableCell align="center" component="th" scope="row">
                {row.amount}
              </StyledTableCell>
              <StyledTableCell align="center">{row.date}</StyledTableCell>
              <StyledTableCell align="center">{row.balance}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
