import { Typography } from "@mui/material";
import Nav from "../../../components/navs/dasboardNav"


const IndProfile = () => {

    const Comp = () => {
        return (
            <>
                <Typography variant="h4" fontWeight={700}>
                    Welcome to Profile
                </Typography>
            </>
        )
    }

    return (
        <Nav active="profile" activePage={2} comp={Comp} />
    )
}


export default IndProfile;