import TopNavBar from "../components/navs/topNav";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
  TextField,
  Link,
  Container,
  Divider
} from "@mui/material";

import Car from "../assets/images/kraneCar.png";
import workCar1 from "../assets/images/workCar1.png";
import workCar2 from "../assets/images/workCar2.png";
import onboard from "../assets/images/onboard.png";
import drive from "../assets/images/drive.png";
import credit from "../assets/images/credit.png";
import Ellipse from "../assets/images/Ellipse.png";
import Uber from "../assets/images/Uber.png";
import Bolt_logo from "../assets/images/Bolt_logo.png";
import rida from "../assets/images/rida.png";
import inDriver from "../assets/images/inDriver.png";
import built1 from "../assets/images/built1.png";
import built2 from "../assets/images/built2.png";
import arrow from "../assets/images/arrow.png";
import arrowBg from "../assets/images/arrow_bg.png";
import logo from "../assets/images/krane-logo.png";
import footer from "../assets/images/footer.png";
import Faq from "../components/Faq";
import { CallMade } from "@mui/icons-material";

const Index = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box sx={{ bgcolor: "#F8F8F8" }}>
      <TopNavBar />
      <Container maxWidth="xl" sx={{ paddingLeft: "0!important", paddingRight: "0!important" }}>
        <Box sx={{ paddingTop: "2rem" }}>
          {/* mainscreen */}
          <Box sx={{
            display: "flex", flexDirection: "column",

          }}>
            <Typography
              sx={{
                fontSize: { xs: "2.75rem", md: "4rem", lg: "5rem", xl: "7rem" },
                fontWeight: "800", display: "flex", flexDirection: "column",
                lineHeight: "100%", marginTop: { xs: 2, md: 3 },
                paddingX: { xs: "2rem", md: "3rem", xl: "5rem" },
              }}
            >
              We buy it! <span>You <span style={{ color: "#E1BF41" }}>own</span> it</span>
            </Typography>

            <Typography
              variant="body1"
              sx={{
                fontSize: { xs: "1.5rem", sm: "1.25rem" }, maxWidth: { xs: "100%", sm: "16rem", xl: "30rem" }, color: "#8F8F8F",
                mt: 2, fontFamily: "Aventa", fontWeight: "bold",
                paddingX: { xs: "2rem", md: "3rem", xl: "5rem" },
              }}
            >
              Finance Your{" "}
              <Box component="span" style={{ color: "#5634D1" }}>
                No 1. asset
              </Box>{" "}
              as a{" "}
              <Box component="span" style={{ color: "#5634D1" }}>
                transport entrepreneur
              </Box>
            </Typography>

            <Box sx={{ marginTop: "1rem", paddingX: { xs: "2rem", md: "3rem", xl: "5rem" }, }}>
              <Button
                className="text-capitalize"
                variant="contained"
                disableElevation
                sx={{
                  padding: { xs: "1rem 5rem", md: "1.5rem 8rem", xl: "1.5rem 10rem" }, fontSize: "1.25rem",
                  mt: { xs: "1.5rem", md: "2rem", xl: "3rem" }, borderRadius: 0
                }}
              >
                Get Started
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                justifySelf: "flex-end",
                width: { xs: "100%", md: "90%", xl: "100%" },
                height: { xs: "23rem", xl: "30rem" },
                backgroundImage: `url(${Car})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "left",
                marginLeft: "auto", marginTop: { xs: "1em", sm: "-3em" },
                paddingX: { xs: 0, md: "3rem", xl: "5rem" },
              }}
            >
            </Box>
          </Box>

          {/* work */}
          <Box sx={{
            marginTop: { xs: "2rem", md: "4rem" }, bgcolor: "#F7F3FC",
            py: { xs: "2rem", md: "5rem" }, display: "flex",
            flexDirection: "column", alignItems: "center", width: "100%"
          }}>
            <Box
              sx={{
                maxWidth: { xs: "90%", md: "60%", lg: "43%" },
                textAlign: "center", display: "flex",
                flexDirection: "column", alignItems: "center", width: "100%"
              }}
            >
              <Typography
                variant="subtitle1"
                component="h3"
                color={"primary"}
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  // color: "#5806C3",
                  marginBottom: "5rem",
                  fontFamily: "Aventa",
                  textTransform: "uppercase"
                }}
              >
                How it Works
              </Typography>

              <Typography
                variant="h4"
                component="h3"
                sx={{
                  fontSize: { xs: "1.5rem", md: "2.25rem", lg: "2.5rem" },
                  fontWeight: "bold",
                  marginBottom: "1rem",
                }}
              >
                Krane consistently and More efficiently
              </Typography>
              <Typography
                variant="body2"
                component="body1"
                sx={{
                  fontSize: { xs: "1.25rem", md: "1.25rem" },
                  fontWeight: "light",
                  color: "#8F8F8F",
                  fontFamily: "Aventa",
                }}
              >
                Enjoy asset financing to purchase the most durable, authentic
                and popular sedans like Toyota Corollas, Hyundais and so on.
              </Typography>

              <Button
                className=""
                variant="contained"
                disableElevation
                sx={{
                  backgroundColor: "#5806C3",
                  padding: { xs: "1rem 5rem", md: "1.5rem 8rem", xl: "1.5rem 10rem" },
                  marginTop: "3rem", borderRadius: 0
                }}
              >
                Get Started
              </Button>
            </Box>
            <Box
              sx={{ width: { xs: "100%", md: "90%", display: "flex" }, mt: { xs: 5, sm: 0 } }}
            // className="flex-center"
            >
              <Box sx={{
                height: { xs: "10rem", sm: "20rem" },
                backgroundImage: `url(${workCar1})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: { xs: "cover", sm: "contain" },
                width: "60%",
                backgroundPosition: "right"
              }}></Box>
              <Box sx={{
                height: { xs: "10rem", sm: "20rem" },
                backgroundImage: `url(${workCar2})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: { xs: "cover", sm: "contain" },
                width: "60%",
                mt: { xs: 0, sm: 8, md: 5.5, lg:4, xl: 0 }
              }}></Box>
            </Box>
          </Box>

          {/* how */}
          <Box
            sx={{
              backgroundColor: "#5806C3",
              p: { xs: "2rem", md: "5rem" },
              flexDirection: "column"
            }}
            className="flex-center"
          >
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  maxWidth: { xs: "90%", md: "60%", lg: "39%" },
                  textAlign: "center",
                  marginX: "auto",
                }}
              >
                <Typography
                  variant="subtitle1"
                  component="h3"
                  sx={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "#E1BF41",
                    fontFamily: "Aventa",
                    textTransform: "uppercase"
                  }}
                >
                  How it Works
                </Typography>
                <Typography
                  variant="h4"
                  component="h3"
                  sx={{
                    fontSize: isMobile ? "31px" : "48px",
                    fontWeight: "bold",
                    my: "4rem",
                    color: "#ffffff",
                  }}
                >
                  Krane your car in 3 simple steps
                </Typography>
              </Box>

              <Box
                // className={isMobile ? "flex-col" : "flex-center"}
                sx={{
                  display: "grid", gap: 2,
                  gridTemplateColumns: { xs: "repeat(1, minmax(0, 1fr))", sm: "repeat(2, minmax(0, 1fr))", md: "repeat(3, minmax(0, 1fr))" },
                }}
              >
                <Box
                  sx={{
                    // width: isMobile ? "90%" : "447px",
                    padding: { xs: "7rem 2rem 3.5rem 2rem", lg:"7rem 7rem 3.5rem 2rem" },
                    backgroundColor: "#3C008B",
                    border: "1px solid #3C008B",
                  }}
                >
                  <Box sx={{ position: "relative" }}>
                  <img
                      src={Ellipse}
                      style={{ position: "absolute", zIndex: "1", bottom:0 }}
                      alt="ellipse"
                    />
                    <img
                      src={onboard}
                      style={{ position: "relative", zIndex: "2", left: 40 }}
                      alt="form"
                    />
                  </Box>
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: { xs: "2rem", md: "1.75rem" },
                      fontWeight: 700,
                      mb: "1rem",
                      mt: "2rem",
                      color: "#ffffff",
                      maxWidth: "60%",
                      fontFamily: "Aventa"
                    }}
                  >
                    Onboard on Krane
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: { xs: "1rem" },
                      fontWeight: "bold",
                      marginBottom: "1rem",
                      color: "#954EF1",
                      lineHeight: "120%",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      fontFamily: "Aventa",
                      maxWidth: {xs:"90%", xl:"75%"}
                    }}
                  >
                    Seamlessly get on board Krane with our intuitive onboarding process.
                  </Typography>
                </Box>

                <Box
                  sx={{
                    // width: isMobile ? "90%" : "447px",
                    padding: { xs: "7rem 2rem 3.5rem 2rem", lg:"7rem 7rem 3.5rem 2rem" },
                    backgroundColor: "#3C008B",
                    border: "1px solid #3C008B",
                  }}
                >
                  <Box sx={{ position: "relative" }}>
                  <img
                      src={Ellipse}
                      style={{ position: "absolute", zIndex: "1", bottom:0 }}
                      alt="ellipse"
                    />
                    <img
                      src={credit}
                      style={{ position: "relative", zIndex: "2", left: 40, marginTop: "3rem" }}
                      alt="coins"
                    />
                  </Box>
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: { xs: "2rem", md: "1.75rem" },
                      fontWeight: 700,
                      mb: "1rem",
                      mt: "2rem",
                      color: "#ffffff",
                      maxWidth: "60%",
                      fontFamily: "Aventa"
                    }}
                  >
                    Apply for credit
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="body1"
                    sx={{
                      fontSize: { xs: "1rem" },
                      fontWeight: "bold",
                      marginBottom: "1rem",
                      color: "#954EF1",
                      lineHeight: "120%",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      fontFamily: "Aventa",
                      maxWidth: {xs:"90%", xl:"75%"}
                    }}
                  >
                    Finance your asset with ease by applying for credit through Krane.
                  </Typography>
                </Box>

                <Box
                  sx={{
                    // width: isMobile ? "90%" : "447px",
                    padding: { xs: "7rem 2rem 3.5rem 2rem", lg:"7rem 7rem 3.5rem 2rem" },
                    backgroundColor: "#3C008B",
                    border: "1px solid #3C008B",
                  }}
                >
                  <Box sx={{ position: "relative" }}>
                    <img
                      src={Ellipse}
                      style={{ position: "absolute", zIndex: "1", bottom:0 }}
                      alt="ellipse"
                    />
                    <img
                      src={drive}
                      style={{ position: "relative", zIndex: "2", left: 40, marginTop: "4.5rem" }}
                      alt="car"
                    />
                  </Box>
                  <Typography
                    variant="h4"
                    sx={{
                      fontSize: { xs: "2rem", md: "1.75rem" },
                      fontWeight: 700,
                      mb: "1rem",
                      mt: "2rem",
                      color: "#ffffff",
                      maxWidth: {xs:"60%", sm:"50%"},
                      fontFamily: "Aventa"
                    }}
                  >
                    Start Driving
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="body1"
                    sx={{
                      fontSize: { xs: "1rem" },
                      fontWeight: "bold",
                      marginBottom: "1rem",
                      color: "#954EF1",
                      lineHeight: "120%",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      fontFamily: "Aventa",
                      maxWidth: {xs:"90%", xl:"75%"}
                    }}
                  >
                    Take the wheel and begin your flexible journey to growth with Krane's supportive platform.
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Link href="/" underline="always"
              sx={{
                display: "flex", alignItems: "center", textAlign: "center",
                color: "#fff", mt: 8, textDecoration: "underline", fontWeight: "bold",
                fontFamily: "Aventa"
              }}>
              Get Started <CallMade />
            </Link>
          </Box>

          {/* built */}
          <Box
            sx={{
              // marginTop: isMobile ? "2rem" : "4rem",
              backgroundColor: "#F7F3FC",
              p: { xs: "2rem", md: "5rem" },
            }}
          >
            <Box sx={{ width: "100%", flexDirection: "column" }}
              className="flex-center">
              <Box
                sx={{
                  maxWidth: isMobile ? "316px" : "638px",
                  textAlign: "center",
                  fontFamily: "Syne",
                  marginX: "auto",
                }}
              >
                <Typography
                  variant="subtitle1"
                  component="h3"
                  sx={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "#E1BF41",
                    marginBottom: "2rem",
                    fontFamily: "Aventa"
                  }}
                >
                  BUILT FOR
                </Typography>
                <Typography
                  variant="h4"
                  component="h3"
                  sx={{
                    fontSize: isMobile ? "31px" : "48px",
                    fontWeight: "bold",
                    marginBottom: "1rem",
                    color: "#000000",
                  }}
                >
                  Transport Entrepreneurs
                </Typography>
              </Box>

              <Box
                sx={{
                  backgroundColor: "#ffffff",
                  width: isMobile ? "100%" : "650px",
                  borderRadius: "82px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  padding: "1.5rem 0.5rem"
                }}
              >
                <img src={Uber} width={isMobile ? "10%" : "auto"} />
                <Divider flexItem variant="center" orientation="vertical" sx={{ borderRightWidth: { xs: "2px", md: "4px" } }} />
                <img src={Bolt_logo} width={isMobile ? "10%" : "auto"} />
                <Divider flexItem variant="center" orientation="vertical" sx={{ borderRightWidth: { xs: "2px", md: "4px" } }} />
                <img src={rida} width={isMobile ? "10%" : "auto"} />
                <Divider flexItem variant="center" orientation="vertical" sx={{ borderRightWidth: { xs: "2px", md: "4px" } }} />
                <img src={inDriver} width={isMobile ? "10%" : "auto"} />
                <Divider flexItem variant="center" orientation="vertical" sx={{ borderRightWidth: { xs: "2px", md: "4px" } }} />
                <Typography variant="subtitle1" color={"primary"}
                  sx={{ fontSize: { xs: "0.5rem", sm: "1rem" }, fontWeight: "bold", fontFamily: "Aventa", lineHeight: "90%" }}>
                  and many<br />others
                </Typography>
              </Box>

              <Box sx={{ marginTop: "3rem", display: "flex", flexDirection: "column", width: {xs:"100%", sm:"90%"} }}>
                <Box>
                  <Box
                    sx={{
                      width: { xs: "100%", sm: "60%" },
                      height: "23rem",
                      backgroundImage: `url(${built1})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      border: "15px solid #fff",
                      borderWidth: { xs: "15px 0px 0px 15px", md: "15px" }
                    }}
                  >
                  </Box>
                  <Box sx={{ width: isMobile ? "100%" : "350px" }}>
                    <Typography
                      variant="h4"
                      sx={{
                        fontSize: isMobile ? "31px" : "36px",
                        fontWeight: "bold",
                        margin: "3rem 0rem 1rem",
                        color: "#000000",
                        maxWidth: "278px",
                      }}
                    >
                      Cooperative societies
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      // component="body1"
                      sx={{
                        fontSize: { xs: "0.75rem", md: "1.25rem" },
                        fontWeight: "bold",
                        marginBottom: "1rem",
                        maxWidth: "320px",
                        fontFamily: "Aventa",
                        color: "#8F8F8F",
                        lineHeight: "140%"
                      }}
                    >
                      For every cooperative and association dedicated to drivers
                      that work with Sedan or SUV automobile vehicles
                    </Typography>

                    <Box sx={{ fontFamily: "Aventa", display: "flex", gap: 1, alignItems: "center", fontWeight: "bold" }}>
                      <Link href="/signup">
                        Sign Up
                      </Link>
                      <CallMade color="primary" sx={{ fontSize: "0.75rem" }} />
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: isMobile ? "flex-center" : "flex-end",
                    marginTop: isMobile ? "2rem" : "-2rem",
                    mb: { xs: "2rem", md: "8rem" }
                  }}
                >
                  <Box sx={{ width: { xs: "100%", sm: "60%" }, }}>
                    <Box
                      sx={{

                        height: "23rem",
                        backgroundImage: `url(${built2})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        border: "15px solid #fff",
                        borderWidth: { xs: "15px 0px 0px 15px", md: "15px" }
                      }}
                    >
                    </Box>
                    <Box sx={{ width: isMobile ? "100%" : "400px" }}>
                      <Typography
                        variant="h4"
                        component="h3"
                        sx={{
                          fontSize: isMobile ? "31px" : "36px",
                          fontWeight: "bold",
                          margin: "3rem 0rem 1rem",
                          color: "#000000",
                          maxWidth: "278px",
                        }}
                      >
                        Transport workers
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        component="body1"
                        sx={{
                          fontSize: { xs: "0.75rem", md: "1.25rem" },
                          fontWeight: "bold",
                          marginBottom: "1rem",
                          maxWidth: "320px",
                          fontFamily: "Aventa",
                          color: "#8F8F8F",
                          lineHeight: "140%"
                        }}
                      >
                        For every driver that currently works with any car hailing
                        (Uber, Bolt, indriver and so on) platforms.
                      </Typography>
                    </Box>

                    <Box sx={{ fontFamily: "Aventa", display: "flex", gap: 1, alignItems: "center", fontWeight: "bold" }}>
                      <Link href="/signup">
                        Sign Up
                      </Link>
                      <CallMade color="primary" sx={{ fontSize: "0.75rem" }} />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {/*  */}
          <Box
            sx={{
              // marginTop: isMobile ? "2rem" : "4rem",
              backgroundColor: "#5806C3",
              py: "5rem"
            }}
            className="fle-center"
          >
            <Box sx={{
              display: isMobile ? "" : "flex",
              // height: "23rem",
              backgroundImage: `url(${arrowBg})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              paddingTop: isMobile ? "1rem" : "4rem",
              paddingLeft: isMobile ? "1rem" : "5rem",
            }}>
              <Box>
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: { xs: "1.75rem", md: "2rem" },
                    fontWeight: "bold",
                    marginY: "1rem",
                    color: "#E1BF41",
                    maxWidth: { xs: "100%", sm: "500px", md: "805px" },
                  }}
                >
                  For Partnerships, sponsorships and enquiries
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: isMobile ? "14px" : "16px",
                    fontWeight: "bold",
                    color: "#fff",
                    width: "410px",
                    fontFamily: "Aventa"
                  }}
                >
                  If you have any enquiries about any part of our solution or
                  might want to partner with us.
                  <br />Kindly reach out here
                </Typography>

                <form className="flex-colStart">
                  <TextField
                    placeholder="Input Email"
                    // value={inputValue}
                    // onChange={handleInputChange}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    sx={{
                      width: { xs: "70%", sm: "380px", md: "543px" },
                      backgroundColor: "#813ED8",
                      color: "#fff",
                      marginTop: "3rem",
                      "& .MuiOutlinedInput-root.Mui-focused": {
                        outline: "none",
                      },
                      "& .MuiInputBase-input": {
                        color: "#fff",
                      },
                    }}
                  />

                  <Button
                    type="submit"
                    variant="contained"
                    disableElevation
                    sx={{
                      backgroundColor: "white",
                      paddingX: "5rem",
                      paddingY: "1rem",
                      color: "#5806C3",
                      marginTop: "0.75rem",
                      borderRadius: 0,
                      textTransform: "capitalize"
                    }}
                  >
                    Submit
                  </Button>
                </form>
              </Box>
              <Box>
                <img
                  src={arrow}
                  style={{ width: isMobile ? "100%" : "100%" }}
                />
              </Box>
            </Box>
          </Box>

          {/*FAQ  */}

          <Box
            sx={{
              // marginTop: isMobile ? "2rem" : "4rem",
              backgroundColor: "#E3E3E3",
              paddingX: isMobile ? "1rem" : "5rem",
              paddingY: isMobile ? "2rem" : "4rem",
            }}
            className="fle-center"
          >
            <Box>
              <Typography variant="h3" sx={{ color: "#1C1C1C", fontWeight: "medium" }}>
                FAQs
              </Typography>
            </Box>

            <Box sx={{ marginTop: "2rem" }}>
              <Faq />
            </Box>
          </Box>

          {/* footer */}
          <Box
            sx={{
              // marginTop: isMobile ? "2rem" : "4rem",
              backgroundColor: "#1C1C1C",
              paddingX: { xs: "1rem", md: "8rem" },
              paddingY: isMobile ? "2rem" : "4rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "initial", sm: "space-between" },
                paddingX: { xs: 0, sm: "5rem" },
                flexWrap: "wrap",
                columnGap: { xs: "10rem", sm: 0 },
                rowGap: { xs: "1rem", sm: 0 }
              }}
            >
              <Box>
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: isMobile ? "16px" : "16px",
                    fontWeight: "bold",
                    marginTop: isMobile ? "0.9rem" : "1rem",
                    color: "#E1BF41",
                    maxWidth: isMobile ? "100%" : "805px",
                    fontFamily: "Aventa"
                  }}
                >
                  Legal
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: { xs: "0.75rem", md: "0.85rem" },
                    marginTop: "1rem",
                    color: "#ffffff",
                    fontFamily: "Aventa"
                  }}
                >
                  Terms of service
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: { xs: "0.75rem", md: "0.85rem" },
                    color: "#ffffff",
                    fontFamily: "Aventa"
                  }}
                >
                  Privacy policy
                </Typography>
              </Box>

              <Box>
                <Typography
                  variant="h4"
                  component="h3"
                  sx={{
                    fontSize: isMobile ? "16px" : "16px",
                    fontWeight: "bold",
                    marginTop: isMobile ? "0.9rem" : "1rem",
                    color: "#E1BF41",
                    maxWidth: isMobile ? "100%" : "805px",
                    fontFamily: "Aventa"
                  }}
                >
                  Follow
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: { xs: "0.75rem", md: "0.85rem" },
                    marginTop: "1rem",
                    color: "#ffffff",
                    fontFamily: "Aventa"
                  }}
                >
                  Twitter <br /> LinkedIn <br /> Instagram <br /> Youtube
                </Typography>
              </Box>

              <Box>
                <Typography
                  variant="h4"
                  component="h3"
                  sx={{
                    fontSize: isMobile ? "16px" : "16px",
                    fontWeight: "bold",
                    marginTop: isMobile ? "0.9rem" : "1rem",
                    color: "#E1BF41",
                    maxWidth: isMobile ? "100%" : "805px",
                    fontFamily: "Aventa"
                  }}
                >
                  Contact
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: { xs: "0.75rem", md: "0.85rem" },
                    marginTop: "1rem",
                    color: "#ffffff",
                    fontFamily: "Aventa"
                  }}
                >
                  01-700-2000 <br /> sales@krane.finance <br />{" "}
                  help@krane.finance <br /> business@krane.finance
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: isMobile ? "" : "flex",
                justifyContent: "space-between",
                alignContent: "center",
                alignItems: "center",
                marginTop: "2rem",
                marginBottom: "2rem",
                paddingX: { xs: 0, sm: "5rem" },
              }}
            >
              <Box sx={{ marginBottom: isMobile ? "1rem" : "" }}>
                <img src={logo} />
              </Box>

              <img src={footer} />
            </Box>

            <Box sx={{
              display: "flex",
              alignItems: "center",
              marginTop: "4rem",
              paddingX: { xs: "1rem", sm: "5rem", md: 0 }
            }}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: isMobile ? "14px" : "16px",
                  fontWeight: "light",
                  color: "#959595",
                  fontFamily: "Aventa",
                  minWidth: { xs: "280px", sm: "320px" }
                }}
              >
                Built by Krane Technologies Limited 🚀🚀
              </Typography>
              <Divider flexItem sx={{ borderColor: "#959595", width: { xs: "20%", sm: "50%", md: "70%" }, height: "fit-content", marginY: "auto" }} />
            </Box>
          </Box>
        </Box>
      </Container >
    </Box >
  );
};

export default Index;
