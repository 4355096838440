import { Box, Grid, Paper, Typography } from "@mui/material";
import { Badge, BadgeDelta } from "@tremor/react";
import { ReactComponent as IncreaseIcon } from "../../../../../assets/images/increase.svg";

export default function PaymentAction() {
  return (
    <Grid
      container
      spacing={{ xs: 2, xl: 4 }}
      pt={2}
      sx={{ paddingRight: { xs: 4, md: 5, lg: 5 } }}
    >
      <Grid item xs={12} sm={4}>
        <Paper
          square
          elevation={0}
          sx={{
            minHeight: {
              xs: "10rem",
              md: "10rem",
              lg: "12rem",
              xl: "15rem",
            },
            paddingRight: { xs: 0, sm: 2, lg: 5 },
            // paddingY: 3,
            display: "flex",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              p: 4,
              justifyContent: "space-between",
            }}
          >
            <Typography color={"#858585"}>Total amount of Member</Typography>
            <Typography
              color={"primary"}
              fontFamily={"'Outfit', sans-serif"}
              fontSize={{ xs: "1.5rem", md: "2rem", lg: "3rem" }}
            >
              300
            </Typography>
            <Box
              sx={{
                // width: { xs: "70%", sm: "50%", lg: "40%", xl: "35%" },
                paddingX: "0.75rem",
                width: "fit-content",
                paddingY: { xs: "0.25rem", md: "0.5rem" },
                bgcolor: "#F7F3FC",
                borderRadius: "50px",
                color: "#5806C3",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "'Outfit', sans-serif",
                fontWeight: 600,
                gap: 1,
                fontSize: { xs: "0.75rem", sm: "1rem", lg: "1.25rem" },
              }}
            >
              30 new members <IncreaseIcon />
            </Box>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Paper
          square
          elevation={0}
          sx={{
            minHeight: {
              xs: "10rem",
              md: "10rem",
              lg: "12rem",
              xl: "15rem",
            },
            paddingRight: { xs: 0, sm: 2, lg: 5 },
            // paddingY: 3,
            display: "flex",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              p: 4,
              justifyContent: "space-between",
            }}
          >
            <Typography color={"#858585"}>Analytics</Typography>
            <Typography
              color={"primary"}
              fontFamily={"'Outfit', sans-serif"}
              fontSize={{ xs: "1.5rem", md: "2rem", lg: "3rem" }}
            >
              2,500,000
            </Typography>
            <Box
              sx={{
                // width: { xs: "70%", sm: "50%", lg: "40%", xl: "35%" },
                paddingX: "0.75rem",
                width: "fit-content",
                paddingY: { xs: "0.25rem", md: "0.5rem" },
                bgcolor: "#F7F3FC",
                borderRadius: "50px",
                color: "#5806C3",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "'Outfit', sans-serif",
                fontWeight: 600,
                gap: 1,
                fontSize: { xs: "0.75rem", sm: "1rem", lg: "1.25rem" },
              }}
            >
              Current stat <IncreaseIcon />
            </Box>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Paper
          square
          elevation={0}
          sx={{
            minHeight: {
              xs: "10rem",
              md: "10rem",
              lg: "12rem",
              xl: "15rem",
            },
            paddingRight: { xs: 0, sm: 2, lg: 5 },
            // paddingY: 3,
            display: "flex",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              p: 4,
              justifyContent: "space-between",
            }}
          >
            <Typography color={"#858585"}>Analytics</Typography>
            <Typography
              color={"primary"}
              fontFamily={"'Outfit', sans-serif"}
              fontSize={{ xs: "1.5rem", md: "2rem", lg: "3rem" }}
            >
              2,500,000
            </Typography>
            <Box
              sx={{
                // width: { xs: "70%", sm: "50%", lg: "40%", xl: "35%" },
                paddingX: "0.75rem",
                width: "fit-content",
                paddingY: { xs: "0.25rem", md: "0.5rem" },
                bgcolor: "#F7F3FC",
                borderRadius: "50px",
                color: "#5806C3",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "'Outfit', sans-serif",
                fontWeight: 600,
                gap: 1,
                fontSize: { xs: "0.75rem", sm: "1rem", lg: "1.25rem" },
              }}
            >
              Current stat <IncreaseIcon />
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}
