import React, { useState } from "react";
import PropTypes from 'prop-types';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Scrollbar from 'perfect-scrollbar-react';
import { PatternFormat } from 'react-number-format';

import {
    Box, Grid, Typography,
    Link, Button, FormControl,
    InputAdornment, InputLabel,
    IconButton, Select, MenuItem,
    CircularProgress, FormHelperText
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { KraneInput } from '../../components/inputs';
import Side from "../../components/coop-side";
import toast from 'react-hot-toast'
import logoDark from "../../assets/images/logo2.png";


const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
    props,
    ref,
    no
) {
    const { onChange, ...other } = props;

    return (
        <PatternFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            format="###########"
        />
    );
});

NumericFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const CoopSignup = () => {

    const navigate = useNavigate();
    const [coopName, setCoopName] = useState("");
    const [contactName, setContactName] = useState("");
    const [contactPhone, setContactPhone] = useState("");
    const [contactPosition, setContactPosition] = useState("");
    const [noMembers, setNoMembers] = useState("");
    const [email, setEmail] = useState("");
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");

    const [coopNameError, setCoopNameError] = useState("");
    const [contactNameError, setContactNameError] = useState("");
    const [contactPhoneError, setContactPhoneError] = useState("");
    const [contactPositionError, setContactPositionError] = useState("");
    const [noMembersError, setNoMembersError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [password1Error, setPassword1Error] = useState("");
    const [password2Error, setPassword2Error] = useState("");

    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const [loading, setLoading] = useState(false);

    const positions = ['President', 'Treasurer', 'General Secretary', 'CFO']

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const handleSignup = async () => {
        setLoading(true);
        setCoopNameError("");
        setContactPhoneError("");
        setContactNameError("");
        setEmailError("");
        setContactPositionError("");
        setNoMembersError("");
        setPassword1Error("");
        setPassword2Error("");

        toast.success("Your account has been successfully created");
        navigate('/account/cooperative/registration/verification');

        // if (password1 === password2)
        // {
        //     const userData = {
        //         first_name: coopName,
        //         last_name: contactPhone,
        //         middle_name: contactName,
        //         email: email,
        //         password: password1,
        //         contactPosition: contactPosition,
        //         state: noMembers
        //     }
        //     try {
        //         await axios
        //         .post(`https://krane-staging.herokuapp.com/auth/signup`, userData)
        //         .then((res) => {
        //           setLoading(false);
        //           toast.success("Account successfully created");
        //         });
        //       } catch (error) {
        //         let errorData = (error.response.data.message);
        //         toast.error("You have errors in your form");
        //         setLoading(false);
        //         if (errorData.indexOf('first_name')>=0)
        //         {
        //             setCoopNameError(errorData[errorData.indexOf('first_name')+1])
        //         }
        //         if (errorData.indexOf('last_name')>=0)
        //         {
        //             setCoopNameError(errorData[errorData.indexOf('last_name')+1])
        //         }
        //         if (errorData.indexOf('middle_name')>=0)
        //         {
        //             setCoopNameError(errorData[errorData.indexOf('middle_name')+1])
        //         }
        //       }
        // } else
        // {
        //     setPassword1Error("Passwords do not match");
        //     setPassword2Error("Passwords do not match");
        //     toast.error("You have errors in your form");
        //     setLoading(false);
        // }
    }

    return (
        <Box>
            <Scrollbar>
                <Grid container spacing={0} className="container-full  vh-100">
                    {/* <TopNavBar /> */}
                    <Grid item xs={12} sm={8} className="form-outer">
                        <Box pr={6} pl={25} py={5} className="form-container" component="form" onSubmit={handleSignup}>
                            <Typography sx={{ display: { xs: 'none', sm: 'block' } }} className="text-end" variant="subtitle2" fontSize="1rem">
                                <Link href='/account/individual/registration' underline="none">
                                    Sign up as an individual
                                </Link>
                            </Typography>
                            <Box mt={{ xs: 2 }} sx={{ display: { xs: 'block', sm: 'none' } }}>
                                <Link href="/" underline="none">
                                    <img src={logoDark} alt="Krane logo dark" width='40%' height={'auto'} />
                                </Link>
                            </Box>
                            <Box mt={10} height={"auto"} className="form-text">
                                <Typography variant="h4" color="primary" fontWeight={500}>
                                    Register as a Cooperative
                                </Typography>
                                <Typography variant="subtitle1" fontWeight={500} mt={1}>
                                    Kindly input the following information to create your account
                                </Typography>
                            </Box>
                            <Grid container mt={2} spacing={3}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl variant="standard" fullWidth error={!!coopNameError}>
                                        <InputLabel shrink htmlFor={'coopNameInput'} sx={{ fontSize: '1.2rem' }} >
                                            Cooperative Name
                                        </InputLabel>
                                        <KraneInput id={'coopNameInput'} value={coopName}
                                            onChange={e => setCoopName(e.target.value)}
                                        />
                                        <FormHelperText>{!!coopNameError ?
                                            (coopNameError) : ('')}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl variant="standard" fullWidth error={!!contactNameError}>
                                        <InputLabel shrink htmlFor={'contactNameInput'} sx={{ fontSize: '1.2rem' }} >
                                            Contact Person Name
                                        </InputLabel>
                                        <KraneInput id={'contactNameInput'} value={contactName}
                                            onChange={e => setContactName(e.target.value)}
                                        />
                                        <FormHelperText>{!!contactNameError ?
                                            (contactNameError) : ('')}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl variant="standard" fullWidth error={!!contactPhoneError}>
                                        <InputLabel shrink htmlFor={'contactPhoneInput'} sx={{ fontSize: '1.2rem' }} >
                                            Contact Person Phone
                                        </InputLabel>
                                        <KraneInput id={'contactPhoneInput'} value={contactPhone}
                                            onChange={e => setContactPhone(e.target.value)}
                                            inputComponent={NumericFormatCustom}
                                        />
                                        <FormHelperText>{!!contactPhoneError ?
                                            (contactPhoneError) : ('')}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl variant="standard" fullWidth error={!!emailError}>
                                        <InputLabel shrink htmlFor={'emailInput'} sx={{ fontSize: '1.2rem' }} >
                                            Email
                                        </InputLabel>
                                        <KraneInput id={'emailInput'} value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            type="email"
                                            autoComplete="off"
                                            inputProps={{
                                                autoComplete: 'new-password',
                                            }} />
                                        <FormHelperText>{!!emailError ?
                                            (emailError) : ('')}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl variant="standard" fullWidth error={!!contactPositionError}>
                                        <InputLabel shrink htmlFor={'contactPositionInput'} sx={{ fontSize: '1.2rem' }} >
                                            Contact Person Position
                                        </InputLabel>
                                        <Select id={'contactPositionInput'} value={contactPosition}
                                            onChange={e => { setContactPosition(e.target.value) }}
                                            input={<KraneInput />}>
                                            {positions.map((position) => (
                                                <MenuItem value={position} key={position} name={position}>
                                                    {position}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <FormHelperText>{!!contactPositionError ?
                                            (contactPositionError) : ('')}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl variant="standard" fullWidth error={!!noMembersError}>
                                        <InputLabel shrink htmlFor={'noMembersInput'} sx={{ fontSize: '1.2rem' }} >
                                            Number of Members
                                        </InputLabel>
                                        <KraneInput id={'noMembersInput'} type="number" value={noMembers}
                                            onChange={e => { setNoMembers(e.target.value) }}
                                        />
                                        <FormHelperText>{!!noMembersError ?
                                            (noMembersError) : ('')}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl variant="standard" fullWidth error={!!password1Error}>
                                        <InputLabel shrink htmlFor='password1Input' sx={{ fontSize: '1.2rem' }} >
                                            Password
                                        </InputLabel>
                                        <KraneInput id='password1Input'
                                            type={showPassword ? 'text' : 'password'}
                                            value={password1}
                                            // input={<OutlinedInput />}
                                            autoComplete="off"
                                            onChange={e => setPassword1(e.target.value)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end">
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>}
                                            inputProps={{
                                                autoComplete: 'new-password',
                                            }}
                                        />
                                        <FormHelperText className="Mui-error">{!!password1Error ? (password1Error) : ('')}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl variant="standard" fullWidth error={!!password2Error}>
                                        <InputLabel shrink htmlFor='password2Input' sx={{ fontSize: '1.2rem' }} >
                                            Password
                                        </InputLabel>
                                        <KraneInput id='password2Input'
                                            type={showPassword2 ? 'text' : 'password'}
                                            value={password2}
                                            autoComplete="off"
                                            onChange={e => setPassword2(e.target.value)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword2}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword2 ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>}
                                            inputProps={{
                                                autoComplete: 'new-password',
                                            }}
                                            aria-describedby="password2-error-text"
                                        />
                                        <FormHelperText className="Mui-error">{!!password2Error ?
                                            (password2Error) : ('')}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container mt={4} spacing={3}>
                                <Grid item xs={0} sm={0} md={6}></Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Button sx={{ height: { xs: '70px', sm: '50px' } }} className="w-100 text-capitalize" disabled={loading} variant="contained" onClick={handleSignup}>
                                        {loading ? (
                                            <CircularProgress size={20} />
                                        ) : (
                                            'Submit'
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>
                            <Typography sx={{ display: { xs: 'block', sm: 'none' } }} className="text-center mt-3" variant="subtitle2" fontSize="1rem">
                                <Link href='/account/individual/registration' underline="none">
                                    Sign up as an individual
                                </Link>
                            </Typography>
                        </Box>
                    </Grid>
                    <Side />
                </Grid>
            </Scrollbar>
        </Box>
    )
}

export default CoopSignup;