import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
    Box, Grid, Paper, BottomNavigation,
    BottomNavigationAction, Typography, Link,
    Badge, Divider
} from "@mui/material";

import logo from "../../assets/images/logo2.png";
import { ReactComponent as DashIcon } from "../../assets/images/dashboardIcon.svg";
import { ReactComponent as PayIcon } from "../../assets/images/paymentIcon.svg";
import { ReactComponent as ProfileIcon } from "../../assets/images/profileIcon.svg";
import { ReactComponent as NotificationIcon } from "../../assets/images/notification-bing.svg"
import { ReactComponent as LogoutIcon } from "../../assets/images/logout.svg";
import { ReactComponent as SettingsIcon } from "../../assets/images/settings.svg";


const Nav = ({ comp: Comp, active, activePage }) => {

    const [page, setPage] = useState(activePage);
    const navigate = useNavigate();

    return (
        <Box bgcolor={'#F8F8F8'} width={"100%"} height={"100%"}>
            <Grid container>
                <Grid item xs={3} md={2} xl={3} sx={{ display: { xs: 'none', md: 'flex' }, position: "relative" }}
                    py={6} className="flex-column align-items-start ps-0 h-100 vh-100" bgcolor={"#FFFFFF"}>
                    <Box sx={{pl:{xs:3, xl:10}}}>
                        <img src={logo} alt="Krane logo" width={'120%'} height={'auto'} />
                    </Box>
                    <Box mt={10} sx={{pl:{xs:3, xl:10}}} py={3} width={"100%"} className="d-flex align-items-center"
                        bgcolor={active === 'dashboard' ? "#F8F8F8" : "transparent"}>
                        <Box className="me-2" color={active === 'dashboard' ? "#5806C3" : '#1C1C1C'}>
                            <DashIcon className="no-color" />
                        </Box>
                        <Link variant="h5" fontWeight={600} underline="none" fontSize={{ xs: "1rem", md: "1.25rem", lg: "1.5rem" }}
                            color={active === 'dashboard' ? "primary" : 'inherit'}
                            href="/account/individual/dashboard">
                            Dashboard
                        </Link>
                    </Box>
                    <Box mt={2} py={2} sx={{pl:{xs:3, xl:10}}} width={"100%"} className="d-flex align-items-center"
                        bgcolor={active === 'payment' ? "#F8F8F8" : "transparent"}>
                        <Box className="me-2" color={active === 'payment' ? "#5806C3" : '#1C1C1C'}>
                            <PayIcon className="no-color" />
                        </Box>
                        <Link variant="h5" fontWeight={600} underline="none" fontSize={{ xs: "1rem", md: "1.25rem", lg: "1.5rem" }}
                            color={active === 'payment' ? "primary" : 'inherit'}
                            className="d-flex justify-items-center" href="/account/individual/payment">
                            Payment
                        </Link>
                    </Box>
                    <Box mt={2} py={2} sx={{pl:{xs:3, xl:10}}} width={"100%"} className="d-flex align-items-center"
                        bgcolor={active === 'profile' ? "#F8F8F8" : "transparent"}>
                        <Box className="me-2" color={active === 'profile' ? "#5806C3" : '#1C1C1C'}>
                            <ProfileIcon className="no-color" />
                        </Box>
                        <Link variant="h5" fontWeight={600} underline="none" fontSize={{ xs: "1rem", md: "1.25rem", lg: "1.5rem" }}
                            color={active === 'profile' ? "primary" : 'inherit'}
                            className="d-flex justify-items-center" href="/account/individual/profile">
                            Profile
                        </Link>
                    </Box>
                    <Box mt={"auto"} width={"100%"}>
                        <Divider variant="middle" />
                        <Link variant="subtitle1" lineHeight={"100%"} py={2} underline="none" color={"inherit"}
                            fontWeight={600} sx={{ display: "flex", pl:{xs:3, xl:10}, alignItems: "center" }}
                            fontSize={{ xs: "0.8rem", md: "1rem", lg: "1.25rem" }}>
                            <LogoutIcon className="me-2" />Log out
                        </Link>
                    </Box>
                </Grid>
                <Grid item xs={12} md={10} xl={9} className="w-100 h-100"
                    sx={{ minHeight: "100vh", pl: { xs: 0, md: 3 }, position: "fixed", overflowY: "scroll", right: 0 }}>
                    <Box bgcolor={"#ffffff"} height={"7rem"} p={1} className="d-flex">
                        <Box bgcolor={"#F8F8F8"} width={"6rem"} height={"100%"} mr={1} ml={"auto"}
                            className="d-flex align-items-center justify-content-around">
                            <Badge overlap="circular" variant="dot" color="error">
                                <NotificationIcon />
                            </Badge>
                        </Box>
                        <Box bgcolor={"#F8F8F8"} width={"25rem"} height={"100%"} p={1.5} className="d-flex">
                            <Box mr={3} bgcolor={"#FFFFFF"} width={"6rem"} height={"100%"} color={"#292D32"}
                                className="d-flex align-items-center justify-content-around">
                                <ProfileIcon />
                            </Box>
                            <span className="d-flex flex-column w-100 h-100 justify-content-between">
                                <Typography variant="h6" fontWeight={700} sx={{ fontSize: { xs: "1rem", md: "1.25rem" } }}>
                                    Damilola Olayiwola
                                </Typography>
                                <Typography variant="h6" className="text-muted">
                                    user
                                </Typography>
                            </span>
                        </Box>
                    </Box>
                    <Box bgcolor={'#F8F8F8'} sx={{ pl: { xs: 2, md: 0 }, pt: { xs: 3, md: 5, lg: 10 }, pb: 10 }}>
                        <Comp />
                    </Box>
                </Grid>
            </Grid>
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, display: { xs: 'block', md: 'none' }, borderTop: "1px solid #" }} elevation={0}>
                <BottomNavigation
                    showLabels
                    value={page}
                    onChange={(event, newValue) => {
                        if (newValue === 0) navigate('/account/individual/dashboard');
                        if (newValue === 1) navigate('/account/individual/payment');
                        if (newValue === 2) navigate('/account/individual/profile');
                    }}
                >
                    <BottomNavigationAction label="Dashboard" icon={<DashIcon className="no-color" />} />
                    <BottomNavigationAction label="Payment" icon={<PayIcon className="no-color" />} />
                    <BottomNavigationAction label="Settings" icon={<SettingsIcon className="no-color" />} />
                    <BottomNavigationAction label="Notifications" icon={<NotificationIcon className="no-color" />} />
                    <BottomNavigationAction label="Profile" icon={<ProfileIcon className="no-color" />} />
                </BottomNavigation>
            </Paper>
        </Box>
    )
}

export default Nav;