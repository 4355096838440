import React from "react";

import {
    Typography, Box, Grid, FormControl,
    InputLabel, FormHelperText, Select,
    Button, Divider, MenuItem, Card,
    ListItem, ListItemText, Checkbox,
    FormControlLabel, Paper
} from "@mui/material";

import { KraneInput } from "../../../components/inputs";
import masterCard from "../../../assets/images/mastercard.svg";
import accessBank from "../../../assets/images/access-bank.svg";



const LoanSummary = (loanData, loanErrors, handleChange) => {
    
    const [selected, setSelected] = React.useState(false);

    return (
        <>

            <Box mt={5} className="form-text">
                <Typography variant="h4" color="#000" fontWeight={500} lineHeight={1}>
                    Loan summary
                </Typography>
                <Typography variant="subtitle1" fontWeight={500} mt={1} lineHeight={1.2}>
                    Provide the following information to get your vehicle
                </Typography>
            </Box>
            <Box sx={{ mt: { xs: 2, md: 5, lg: 8 } }}>
                <Typography variant="subtitle1" fontWeight={700} lineHeight={1.2}>
                    SUMMARY AND REPAYMENT INFO
                </Typography>
                <Card variant="outlined" sx={{ mt: { xs: 2, md: 3, lg: 5 }, p: { xs: 1, md: 2, lg: 5 }, width: "100%", overflowX: {xs: "scroll", lg: "hidden"} }}>
                    <Box sx={{minWidth:600}}>
                    <ListItem sx={{ px: { xs: 1, md: 2, lg: 5, xl: 10 }, py: 1, bgcolor: "#F8F8F8" }}>
                        <ListItemText>Request amount</ListItemText>
                        <Typography variant="h6" fontWeight={600}>N 3,000,000.00</Typography>
                    </ListItem>
                    <ListItem sx={{ px: { xs: 1, md: 2, lg: 5, xl: 10 }, py: 1, bgcolor: "#F8F8F8", mt: 1 }}>
                        <ListItemText>Total Repayment</ListItemText>
                        <Typography variant="h6" fontWeight={600}>N 4,800,000.00</Typography>
                    </ListItem>
                    <ListItem sx={{ px: { xs: 1, md: 2, lg: 5, xl: 10 }, py: 1, bgcolor: "#F8F8F8", mt: 1 }}>
                        <ListItemText>Start Date</ListItemText>
                        <Typography variant="h6" fontWeight={600}>12, June 2023</Typography>
                    </ListItem>
                    <ListItem sx={{ px: { xs: 1, md: 2, lg: 5, xl: 10 }, py: 1, bgcolor: "#F8F8F8", mt: 1 }}>
                        <ListItemText>End Date</ListItemText>
                        <Typography variant="h6" fontWeight={600}>12, June 2024</Typography>
                    </ListItem>
                    <ListItem sx={{ px: { xs: 1, md: 2, lg: 5, xl: 10 }, py: 1, bgcolor: "#F8F8F8", mt: 1 }}>
                        <ListItemText>Interest Rate</ListItemText>
                        <Typography variant="h6" fontWeight={600}>2.5%</Typography>
                    </ListItem>
                    <ListItem sx={{ px: { xs: 1, md: 2, lg: 5, xl: 10 }, py: 1, bgcolor: "#F8F8F8", mt: 1 }}>
                        <ListItemText>Management Fee</ListItemText>
                        <Typography variant="h6" fontWeight={600}>0.5%</Typography>
                    </ListItem>
                    <ListItem sx={{ px: { xs: 1, md: 2, lg: 5, xl: 10 }, py: 1, bgcolor: "#F8F8F8", mt: 1 }}>
                        <ListItemText>Deduction Date</ListItemText>
                        <Typography variant="h6" fontWeight={600}>Every Friday</Typography>
                    </ListItem>
                    <ListItem sx={{ px: { xs: 1, md: 2, lg: 5, xl: 10 }, py: 1, bgcolor: "#F8F8F8", mt: 1 }}>
                        <ListItemText>Timely Deduction</ListItemText>
                        <Typography variant="h6" fontWeight={600}>N 7,675</Typography>/week
                    </ListItem>
                    </Box>
                </Card>
            </Box>
            <Divider sx={{ border: '1px solid #C4C4C4', mb: { xs: 3, md: 5 } }} />
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormControl variant="standard" fullWidth error={!!loanErrors.FODError}>
                        <InputLabel shrink htmlFor={'FODInput'} sx={{ fontSize: '1.25rem', display: "flex", width: "100%" }}>
                            Frequency of Deduction
                        </InputLabel>
                        <Select id={'FODInput'} value={loanData.FOD}
                            onChange={handleChange}
                            name="FOD"
                            input={<KraneInput />} >
                            <MenuItem value={"weekly"} name={"weekly"}>
                                Weekly
                            </MenuItem>
                            <MenuItem value={"bi-weekly"} name={"bi-weekly"}>
                                Bi-Weekly
                            </MenuItem>
                            <MenuItem value={"monthly"} name={"monthly"}>
                                Monthly
                            </MenuItem>
                            <MenuItem value={"bi-monthly"} name={"bi-monthly"}>
                                Bi-Monthly
                            </MenuItem>
                            <MenuItem value={"quarterly"} name={"qurterly"}>
                                Quarterly
                            </MenuItem>
                        </Select>
                        <FormHelperText>{!!loanErrors.FODError ?
                            (loanErrors.FODError) : ('')}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl variant="standard" fullWidth error={!!loanErrors.DODError}>
                        <InputLabel shrink htmlFor={'DODInput'} sx={{ fontSize: '1.25rem', display: "flex", width: "100%" }}>
                            Date/days of Deduction
                        </InputLabel>
                        <Select id={'DODInput'} value={loanData.DOD}
                            onChange={handleChange}
                            name="DOD"
                            input={<KraneInput />} >
                            <MenuItem value={"fridays"} name={"fridays"}>
                                Fridays
                            </MenuItem>
                            <MenuItem value={"saturdays"} name={"saturdays"}>
                                Saturdays
                            </MenuItem>
                            <MenuItem value={"sundays"} name={"sundays"}>
                                Sundays
                            </MenuItem>
                        </Select>
                        <FormHelperText>{!!loanErrors.DODError ?
                            (loanErrors.DODError) : ('')}</FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
            <Typography variant="subtitle1" fontWeight={500} mt={4} lineHeight={1.2}>
                The Card below will be used for deductions. Kindly confirm that the card details below are for your major
            </Typography>
            <Grid container spacing={1.5} mt={2}>
                <Grid item xs={12} md={6}>
                    <Box sx={{ bgcolor: "#F7F3FC", width: "100%", height: "4rem", p: "0.5rem", display: "flex" }}>
                        <Paper elevation={0} sx={{ width: "4rem", height: "3rem", display: "flex", justifyContent: "center" }}>
                            <img src={accessBank} />
                        </Paper>
                        <Box sx={{ ml: "1rem", display: "flex", flexDirection: "column" }}>
                            <Typography variant="h5" sx={{ fontSize: { xs: "0.8rem", lg: "1.25rem", xl: "1.5rem" }, fontWeight: 700, fontFamily: "'Outfit', sans-serif" }}>
                                2093 8923 xxxx 3940
                            </Typography>
                            <Typography variant="h6"
                                sx={{
                                    fontSize: "0.8rem", display: "flex", alignItems: "center",
                                    justifyContent: "space-between", fontWeight: 700
                                }}>
                                Access Bank
                                <Typography variant="subtitle1" sx={{ fontSize: "0.6rem", fontWeight: 200, ml: 1, fontFamily: "'Outfit', sans-serif" }}>
                                    009473824
                                </Typography>
                            </Typography>
                        </Box>
                        <Box sx={{ ml: "auto", height: "100%", display: "flex", alignItems: "center" }}>
                            <img src={masterCard} />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Button size="large" variant="contained" sx={{ height: "100%", textTransform: "capitalize", width: { xs: "100%", md: "70%", lg: "50%", xl: "40%" } }} >Change</Button>
                </Grid>
            </Grid>
            <Grid container mt={1}>
                <Grid item>
                    <FormControlLabel control={<Checkbox />}
                        checked={selected} onChange={() => {
                            setSelected(!selected);
                        }}
                        label="Yes, I consent to the terms and conditions of getting financing with krane finance" />
                </Grid>
            </Grid>
        </>
    )
}

export default LoanSummary;