import { Input, InputBase, OutlinedInput, TextField } from "@mui/material";
import { styled } from '@mui/material/styles';

const KraneInput = styled(OutlinedInput)(({ theme }) => ({
    background: '#F8F8F8',
    border: '1px solid #EEEEEE',
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 3,
      position: 'relative',
      background: '#EEEEEE',
      border: '1px solid #EEEEEE',
    //   fontSize: 16,
    //   width: 'auto',
      padding: '10px 12px',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      '&:hover': {
        backgroundColor: 'transparent',
        borderColor: theme.palette.primary.main,
      },
      '&:focus': {
        // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
        backgroundColor: 'transparent',
      },
    },
  }));

// const TextField = ({label}) => {
//     const labelId = label.replace(/\s+/g, '');

//     return (
//         <FormControl variant="standard" fullWidth>
//             <InputLabel shrink htmlFor={`${labelId}-input`} sx={{fontSize:'1.2rem'}} >{label}</InputLabel>
//             <KraneInput id={`${labelId}-input`} />
//         </FormControl>
//     )
// }

// const PasswordField = ({label}) => {
//     const [showPassword, setShowPassword] = useState(false);
//     const labelId = label.replace(/\s+/g, '');

//     const handleClickShowPassword = () => setShowPassword((show) => !show);
//     const handleMouseDownPassword = (event) => {
//         event.preventDefault();
//     };

//     return (
//         <FormControl variant="standard" fullWidth>
//             <InputLabel shrink htmlFor={`${labelId}-input`} sx={{fontSize:'1.2rem'}} >{label}</InputLabel>
//             <KraneInput id={`${labelId}-input`} 
//                 type={showPassword ? 'text' : 'password'}
//                 endAdornment={
//                   <InputAdornment position="end">
//                     <IconButton
//                       aria-label="toggle password visibility"
//                       onClick={handleClickShowPassword}
//                       onMouseDown={handleMouseDownPassword}
//                       edge="end"
//                     >
//                       {showPassword ? <VisibilityOff /> : <Visibility />}
//                     </IconButton>
//                   </InputAdornment>}
//             />
//         </FormControl>
//     )

// }

export {KraneInput};