import React, { useState } from "react";
import PropTypes from "prop-types";
import { PatternFormat } from "react-number-format";

import {
  Typography,
  Box,
  Grid,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  Button,
} from "@mui/material";

import { KraneInput } from "../../../components/inputs";
import { UploadButton } from "../../../components/buttons";
import Upload from "../../../assets/images/upload.svg";

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="###########"
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const Guarantors = ({
  guarantorData,
  guarantorErrors,
  handleChange,
  handleFiles,
}) => {
  function openStatement1Dialog() {
    document.getElementById("statement1Input").click();
  }

  function openStatement2Dialog() {
    document.getElementById("statement2Input").click();
  }

  return (
    <>
      <Box mt={5} className="form-text">
        <Typography variant="h4" color="#000" fontWeight={500} lineHeight={2}>
          Guarantors <br /> information
        </Typography>
        <Typography
          variant="subtitle2"
          fontWeight={500}
          mt={2}
          lineHeight={2.2}
        >
          Provide the following information to get your vehicle
        </Typography>
      </Box>
      <Grid container mt={5} spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="subtitle2"
            color={"primary"}
            fontWeight={500}
            mt={2}
            lineHeight={2.2}
          >
            GUARANTOR INFO (1)
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormControl
            variant="standard"
            fullWidth
            error={!!guarantorErrors.name1Error}
          >
            <InputLabel
              shrink
              htmlFor={"name1Input"}
              sx={{ fontSize: "1.2rem" }}
            >
              Full Name
            </InputLabel>
            <KraneInput
              id={"name1Input"}
              name="name1"
              value={guarantorData.name1}
              onChange={handleChange}
            />
            <FormHelperText>
              {!!guarantorErrors.name1Error ? guarantorErrors.name1Error : ""}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormControl
            variant="standard"
            fullWidth
            error={!!guarantorErrors.phone1Error}
          >
            <InputLabel
              shrink
              htmlFor={"phone1Input"}
              sx={{ fontSize: "1.2rem" }}
            >
              Phone Number
            </InputLabel>
            <KraneInput
              id={"phone1Input"}
              value={guarantorData.phone1}
              name="phone1"
              onChange={handleChange}
              inputComponent={NumericFormatCustom}
            />
            <FormHelperText>
              {!!guarantorErrors.phone1Error ? guarantorErrors.phone1Error : ""}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormControl
            variant="standard"
            fullWidth
            error={!!guarantorErrors.email1Error}
          >
            <InputLabel
              shrink
              htmlFor={"email1Input"}
              sx={{ fontSize: "1.2rem" }}
            >
              Email
            </InputLabel>
            <KraneInput
              id={"email1Input"}
              name="email1"
              value={guarantorData.email1}
              type="email"
              onChange={handleChange}
            />
            <FormHelperText>
              {!!guarantorErrors.email1Error ? guarantorErrors.email1Error : ""}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormControl
            variant="standard"
            fullWidth
            error={!!guarantorErrors.profession1Error}
          >
            <InputLabel
              shrink
              htmlFor={"profession1Input"}
              sx={{ fontSize: "1.2rem" }}
            >
              Profession/Occupation
            </InputLabel>
            <Select
              id={"profession1Input"}
              name="profession1"
              value={guarantorData.profession1}
              onChange={handleChange}
              input={<KraneInput />}
            ></Select>
            <FormHelperText>
              {!!guarantorErrors.profession1Error
                ? guarantorErrors.profession1Error
                : ""}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <InputLabel
            htmlFor={"statement1Input"}
            shrink
            sx={{ fontSize: "1.2rem" }}
          >
            Upload Bank Statement
          </InputLabel>
          <UploadButton
            id={"statement1Button"}
            htmlFor={"statement1Input"}
            error={!!guarantorErrors.statement1Error}
            fullWidth
            onClick={openStatement1Dialog}
          >
            <img src={Upload} alt="upload icon" className="me-2" />{" "}
            {guarantorData.statement1
              ? guarantorData.statement1.name
              : "Upload"}
          </UploadButton>
          <KraneInput
            onChange={handleFiles}
            name="statement1"
            hidden
            id={"statement1Input"}
            type="file"
          />
          <FormHelperText>
            {!!guarantorErrors.statement1Error
              ? guarantorErrors.statement1Error
              : ""}
          </FormHelperText>
        </Grid>
      </Grid>
      <Grid container mt={5} spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="subtitle2"
            color={"primary"}
            fontWeight={500}
            mt={2}
            lineHeight={2.2}
          >
            GUARANTOR INFO (2)
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormControl
            variant="standard"
            fullWidth
            error={!!guarantorErrors.name2Error}
          >
            <InputLabel
              shrink
              htmlFor={"name2Input"}
              sx={{ fontSize: "1.2rem" }}
            >
              Full Name
            </InputLabel>
            <KraneInput
              id={"name2Input"}
              value={guarantorData.name2}
              name="name2"
              onChange={handleChange}
            />
            <FormHelperText>
              {!!guarantorErrors.name2Error ? guarantorErrors.name2Error : ""}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormControl
            variant="standard"
            fullWidth
            error={!!guarantorErrors.phone2Error}
          >
            <InputLabel
              shrink
              htmlFor={"phone2Input"}
              sx={{ fontSize: "1.2rem" }}
            >
              Phone Number
            </InputLabel>
            <KraneInput
              id={"phone2Input"}
              value={guarantorData.phone2}
              onChange={handleChange}
              name="phone2"
              inputComponent={NumericFormatCustom}
            />
            <FormHelperText>
              {!!guarantorErrors.phone2Error ? guarantorErrors.phone2Error : ""}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormControl
            variant="standard"
            fullWidth
            error={!!guarantorErrors.email2Error}
          >
            <InputLabel
              shrink
              htmlFor={"email2Input"}
              sx={{ fontSize: "1.2rem" }}
            >
              Email
            </InputLabel>
            <KraneInput
              id={"email2Input"}
              value={guarantorData.email2}
              type="email"
              onChange={handleChange}
              name="email2"
            />
            <FormHelperText>
              {!!guarantorErrors.email2Error ? guarantorErrors.email2Error : ""}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormControl
            variant="standard"
            fullWidth
            error={!!guarantorErrors.profession2Error}
          >
            <InputLabel
              shrink
              htmlFor={"profession2Input"}
              sx={{ fontSize: "1.2rem" }}
            >
              Profession/Occupation
            </InputLabel>
            <Select
              id={"profession2Input"}
              value={guarantorData.profession2}
              onChange={handleChange}
              name="profession2"
              input={<KraneInput />}
            ></Select>
            <FormHelperText>
              {!!guarantorErrors.profession2Error
                ? guarantorErrors.profession2Error
                : ""}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <InputLabel
            htmlFor={"statement2Input"}
            shrink
            sx={{ fontSize: "1.2rem" }}
          >
            Upload Bank Statement
          </InputLabel>
          <UploadButton
            id={"statement2Button"}
            htmlFor={"statement2Input"}
            error={!!guarantorErrors.statement2Error}
            fullWidth
            onClick={openStatement2Dialog}
          >
            <img src={Upload} alt="upload icon" className="me-2" />{" "}
            {guarantorData.statement2
              ? guarantorData.statement2.name
              : "Upload"}
          </UploadButton>
          <KraneInput
            onChange={handleFiles}
            hidden
            id={"statement2Input"}
            type="file"
            name="statement2"
          />
          <FormHelperText>
            {!!guarantorErrors.statement2Error
              ? guarantorErrors.statement2Error
              : ""}
          </FormHelperText>
        </Grid>
      </Grid>
    </>
  );
};

export default Guarantors;
