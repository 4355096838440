import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Check from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

const KraneConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#5806C3',
      border: 0
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#5806C3',
      border: 0
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
    border: 0,
    // width: 10
  },
}));

const KraneStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: '#eaeaf0',
  display: 'flex',
//   height: 22,
  width: '100%',
  height: 5,
  backgroundColor: '#5806C3',
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#5806C3',
  }),
  '& .stepicon-completed': {
    color: '#5806C3',
    zIndex: 1,
    fontSize: 1,
    width: '100%',
    height: 5,
    backgroundColor: '#5806C3'
  },
  '& .step-icon': {
    width: '100%',
    height: 5,
    backgroundColor: 'currentColor',
  },
}));

function KraneStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <KraneStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <div className="StepIcon-completedIcon" />
      ) : (
        <div className="step-icon" />
      )}
    </KraneStepIconRoot>
  );
}

KraneStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

export { KraneConnector, KraneStepIcon};