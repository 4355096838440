import React from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import Signup from "./views/individual/signup";
import Login from "./views/login";
import Verification from "./views/individual/verification";
import Information from "./views/individual/information";
import CoopSignup from "./views/cooperative/signup";
import CoopVerification from "./views/cooperative/coop-verification";
import CoopInformation from "./views/cooperative/information";
import toast, { Toaster } from "react-hot-toast";

import Payment from "./views/cooperative/Dashboard/dashboardPage/Payment";
import Profile from "./views/cooperative/Dashboard/dashboardPage/Profile";

import "./App.css";
import 'perfect-scrollbar-react/dist/style.min.css'; import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import Index from "./views";
import Dashboards from "./views/cooperative/Dashboard/dashboardPage/Dashboards";
import Dashboard from "./views/individual/Dashboard/dashboard";
import IndProfile from "./views/individual/Dashboard/profile";
import IndPayment from "./views/individual/Dashboard/payments";
import CreditApplication from "./views/individual/credit/information";
import CoopDashboard from "./views/cooperative/Dashboard/dashboardPage/Dashboards";
import CoopMembers from "./views/cooperative/Dashboard/dashboardPage/Payment";


const theme = createTheme({
  palette: {
    primary: {
      light: "#7937cf",
      main: "#5806C3",
      dark: "#3d0488",
      contrastText: '#fff',
    },
    secondary: {
      light: "#e7cb67",
      main: '#E1BF41',
      dark: "#9d852d",
      contrastText: '#000',
    },
  },
  typography: {
    fontFamily: "'Syne', 'Aventa', 'Outfit', sans-serif",
  },
});


function App() {
  return (
    <Router>
      <Toaster
        position="top-right"
        reverseOrder={true}
        toastOptions={{
          duration: 4000,
          success: {
            style: {
              background: "#07bc0c",
              color: "#fff",
            },
          },
          error: {
            style: {
              background: "#e74c3c",
              color: "#fff",
            },
          },
          custom: {
            style: {
              background: "#15008b",
              color: "#fff",
            },
            duration: 6000,
          },
          // info: #3498db
          // warning: #f1c40f
        }}
      />
      <ThemeProvider theme={responsiveFontSizes(theme)}>
        <Routes>
          {/* Individual credit application */}
          <Route path="/account/individual/credit-application" element={<CreditApplication />} ></Route>

          {/* individual dashboard */}
          <Route path="/account/individual/profile" element={<IndProfile />} ></Route>
          <Route path="/account/individual/payment" element={<IndPayment />} ></Route>
          <Route path="/account/individual/dashboard" element={<Dashboard />} ></Route>

          {/* dashboard cooperative */}
          <Route path="/account/cooperative/dashboard" element={<CoopDashboard />} />
          <Route path="/account/cooperative/members" element={<CoopMembers />} />
          <Route path="/account/cooperative/profile" element={<Profile />} />

          {/* cooperative onboarding */}
          <Route path="/account/cooperative/registration/information" element={<CoopInformation />}></Route>
          <Route path="/account/cooperative/registration/verification" element={<CoopVerification />}></Route>
          <Route path="/account/cooperative/registration" element={<CoopSignup />}></Route>

          {/* individual onboarding */}
          <Route path="/account/individual/registration/information" element={<Information />}></Route>
          <Route path="/account/individual/registration/verification" element={<Verification />}></Route>
          <Route path="/account/individual/registration" element={<Signup />}></Route>

          {/* General */}
          <Route path="/account/login" element={<Login />}></Route>
          <Route path="/" element={<Index />}></Route>
        </Routes>
      </ThemeProvider>
    </Router>
  );
}

export default App;
