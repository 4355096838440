import React, { useState } from "react";
import toast from 'react-hot-toast'
import Scrollbar from 'perfect-scrollbar-react';
import { useNavigate } from "react-router-dom";

import { Box, Grid, Typography, 
    Button, FormControl, FormHelperText } from '@mui/material';
import { KraneInput } from '../../components/inputs';
import Side from "../../components/ind-sidecar";
import illustration from "../../assets/images/ver-illustration.svg";
import TopNavBar from "../../components/navs/topNav";
import logoDark from "../../assets/images/logo2.png";

const Verification = () => {
    const navigate = useNavigate();
    const [code, setCode] = useState("");

    const [codeError, setCodeError] = useState("");

    const verifyCode = () => {        
        navigate('/account/individual/registration/information');
    }

    return (
        <Box>
            <Scrollbar>
                <Grid container spacing={0} className="container-full  vh-100">
                {/* <TopNavBar /> */}
                    <Grid item xs={12} sm={8} className="form-outer">
                            <Box component="form" noValidate autoComplete="off" 
                                pr={6} pl={25} py={5} className="form-container">
                                    <Box mt={{xs: 2}} sx={{ display: {xs: 'block', sm: 'none'} }}>
                                        <img src={logoDark} alt="Krane logo dark" width='40%' height={'auto'}/>
                                    </Box>
                                    <Box mt={{xs: 7, sm: 10}}>
                                        <img src={illustration} alt="verification illustration" width="20%" height="20%"/>
                                    </Box>
                                    <Box mt={5} className="form-text">
                                        <Typography variant="h4" color="primary" fontWeight={500}>
                                            Account verification
                                        </Typography>
                                        <Typography variant="subtitle1" fontWeight={500} mt={1}>
                                        Kindly input the OTP that has been sent to your mail and phone number:<br />
                                        <Typography color={"#000"} fontWeight={700}>user****@email.com & 081******89</Typography>
                                        </Typography>
                                    </Box>
                                    <Grid container mt={2} spacing={3}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <FormControl variant="standard" fullWidth error={!!codeError}>
                                                <KraneInput id={'codeInput'} value={code}
                                                    onChange={e => setCode(e.target.value)}
                                                    type="text"
                                                />
                                                <FormHelperText>{!!codeError ? 
                                                    (codeError) : ('')}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container mt={4} spacing={3}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <Button 
                                                onClick={verifyCode} 
                                                className="w-100 text-capitalize" 
                                                variant="contained"
                                                sx= {{height: {xs: '70px', sm: '50px'}}}>Next</Button>
                                        </Grid>
                                    </Grid>
                            </Box>
                    </Grid>
                    <Side />
                </Grid>            
            </Scrollbar>
        </Box>
    )
}

export default Verification;