import React from "react";

import {
    Typography, Box, Grid, FormControl,
    InputLabel, FormHelperText, Select,
    Divider, MenuItem
} from "@mui/material";

import { KraneInput } from "../../../components/inputs";
import noImage from "../../../assets/images/no-image.svg";



const Finance = (financeData, financeErrors, handleChange, handleFiles) => {

    function openImageDialog(e) {
        e.preventDefault();
        document.getElementById('imageInput').click();
    }

    return (
        <>
            <Box mt={5} className="form-text">
                <Typography variant="h4" color="#000" fontWeight={500} lineHeight={1}>
                    Apply for <br /> Financing
                </Typography>
                <Typography variant="subtitle1" fontWeight={500} mt={1} lineHeight={1.2}>
                    Provide the following information to get your vehicle
                </Typography>
            </Box>
            <Grid container mt={5} spacing={3}>
                <Grid item xs={12} sm={12} sx={{ display: "flex", flexDirection: "row", alignItems: "end" }}>
                    <Box>
                        <InputLabel htmlFor={'bvnImageInput'} shrink sx={{ fontSize: '1.2rem' }} >
                            Image
                        </InputLabel>
                        <Box component={"button"} onClick={e => openImageDialog(e)}
                            bgcolor={'#5806C3'} border={0} width={'8rem'} height={'8rem'}
                            error={!!financeErrors.imageError} htmlFor={'imageInput'}>
                            <img src={noImage} alt="upload icon"
                                className="" />
                        </Box>
                        <KraneInput onChange={handleFiles} hidden name="image"
                            id={'imageInput'} type="file" accept="image/*" />
                        <FormHelperText>{!!financeErrors.imageError ?
                            (financeErrors.imageError) : ('')}</FormHelperText>
                    </Box>
                    <Box>
                        <Box component={"button"} htmlFor={'imageInput'} bgcolor={"rgba(88, 6, 195, 0.05)"}
                            ml={2} border={0} py={2} px={4} sx={{ textTransform: "none", color: "#000000", height: "30%" }}>
                            Take a snap
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <FormControl variant="standard" fullWidth error={!!financeErrors.profileError}>
                        <InputLabel shrink htmlFor={'profileInput'} sx={{ fontSize: '1.2rem' }} >
                            Link to Uber/Bolt Profile
                        </InputLabel>
                        <KraneInput id={'profileInput'} value={financeData.profile} name="profile"
                            onChange={handleChange}
                        />
                        <FormHelperText>{!!financeErrors.profileError ?
                            (financeErrors.profileError) : ('')}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <FormControl variant="standard" fullWidth error={!!financeErrors.downPaymentError}>
                        <InputLabel shrink htmlFor={'downPaymentInput'} sx={{ fontSize: '1.2rem' }} >
                            Down Payment
                        </InputLabel>
                        <Select id={'downPaymentInput'} value={financeData.downPayment}
                            onChange={handleChange}
                            input={<KraneInput />}
                            name="downPayment"
                            placeholder="Compulsory payment"
                        >
                            <MenuItem value={"Yes"} name={"yes"}>
                                Yes
                            </MenuItem>
                            <MenuItem value={"No"} name={"no"}>
                                No
                            </MenuItem>
                        </Select>
                        <FormHelperText>{!!financeErrors.downPaymentError ?
                            (financeErrors.downPaymentError) : ('')}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} mt={1}>
                    <Divider sx={{ border: '1px solid #C4C4C4' }} />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <FormControl variant="standard" fullWidth error={!!financeErrors.maxOfferError}>
                        <InputLabel shrink htmlFor={'maxOfferInput'} sx={{ fontSize: '1.2rem' }} >
                            Max offer
                        </InputLabel>
                        <KraneInput id={'maxOfferInput'} value={financeData.maxOffer} name="maxOffer"
                            onChange={handleChange}
                        />
                        <FormHelperText>{!!financeErrors.maxOfferError ?
                            (financeErrors.maxOfferError) : ('')}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    {financeData.downPayment === "Yes" ? (
                        <FormControl variant="standard" fullWidth error={!!financeErrors.downPaymentAmount}>
                            <InputLabel shrink htmlFor={'downPaymentAmountInput'} sx={{ fontSize: '1.2rem' }} >
                                Down Payment Amount
                            </InputLabel>
                            <KraneInput id={'downPaymentAmountInput'} name="downPaymentAmount" value={financeData.downPaymentAmount}
                                onChange={handleChange}
                            />
                            <FormHelperText>{!!financeErrors.downPaymentAmountError ?
                                (financeErrors.downPaymentAmountError) : ('')}</FormHelperText>
                        </FormControl>
                    ) : (<></>)}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <FormControl variant="standard" fullWidth error={!!financeErrors.amountError}>
                        <InputLabel shrink htmlFor={'amountInput'} sx={{ fontSize: '1.2rem' }} >
                            Request Amount
                        </InputLabel>
                        <KraneInput id={'amountInput'} value={financeData.amount} name="amount"
                            onChange={handleChange}
                        />
                        <FormHelperText>{!!financeErrors.amountError ?
                            (financeErrors.amountError) : ('')}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <FormControl variant="standard" fullWidth error={!!financeErrors.tenorError}>
                        <InputLabel shrink htmlFor={'tenorInput'} sx={{ fontSize: '1.25rem', display: "flex", width: "100%" }}>
                            Tenor
                            <Typography variant="subtitle1" textAlign={"end"} color="primary" ml={"auto"}>@2.5% PER MONTH</Typography>
                        </InputLabel>
                        <Select id={'tenorInput'} value={financeData.tenor}
                            onChange={handleChange}
                            name="tenor"
                            input={<KraneInput />} >
                            <MenuItem value={1} name={"1-month"}>
                                1 month
                            </MenuItem>
                            <MenuItem value={3} name={"3-month"}>
                                3 months
                            </MenuItem>
                            <MenuItem value={6} name={"6-month"}>
                                6 months
                            </MenuItem>
                            <MenuItem value={9} name={"9-month"}>
                                9 months
                            </MenuItem>
                            <MenuItem value={12} name={"12-month"}>
                                12 months
                            </MenuItem>
                        </Select>
                        <FormHelperText>{!!financeErrors.tenorError ?
                            (financeErrors.tenorError) : ('')}</FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    )
}

export default Finance;