import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import toast from 'react-hot-toast'
import Scrollbar from 'perfect-scrollbar-react';
import NGBanks from 'ng-banks';
import { NumericFormat } from 'react-number-format';
import { PatternFormat } from 'react-number-format';
import { IMaskInput } from 'react-imask';

import { Box, Grid, Typography, 
    Button, FormControl, InputLabel, 
    ToggleButton, Select, MenuItem, Divider, FormHelperText, FormControlLabel, Checkbox } from '@mui/material';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Side from "../../components/ind-sidecar";
import CheckIcon from '@mui/icons-material/Check';
import GppGoodOutlined from '@mui/icons-material/GppGoodOutlined'

import { KraneInput } from '../../components/inputs';
import { KraneConnector, KraneStepIcon } from "../../components/stepper";
import TopNavBar from "../../components/navs/topNav";
import noImage from "../../assets/images/no-image.svg"

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
    props,
    ref,
  ) {
    const { onChange, ...other } = props;
  
    return (
      <PatternFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        format="###########"
      />
    );
  });
  
  NumericFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };
  
const CardNumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref,
) {
  const { onChange, ...other } = props;

  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="###################"
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};


const CardExpiryNumericFormatCustom = React.forwardRef(function NumericFormatCustom(
    props,
    ref,
  ) {
    const { onChange, ...other } = props;
  
    return (
      <PatternFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        format="##/##"
      />
    );
  });
  
  NumericFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  const CardCVVNumericFormatCustom = React.forwardRef(function NumericFormatCustom(
    props,
    ref,
  ) {
    const { onChange, ...other } = props;
  
    return (
      <PatternFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        format="###"
      />
    );
  });
  
  NumericFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };



const Information = () => {

    const steps = ['', '', ''];
    const [selected, setSelected] = React.useState(false);
    const [addressSelected, setAddressSelected] = React.useState(false);


    const [bvnImage, setBVNImage] = useState("");
    const [bankName, setBankName] = useState("");
    const [bvn, setBVN] = useState("");
    const [accountName, setAccountName] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [address, setAddress] = useState("");
    const [newAddress, setNewAddress] = useState("");
    const [birthDate, setBirthDate] = useState("");
    const [phone, setPhone] = useState("");

    const [bvnImageError, setBVNImageError] = useState("");
    const [bankNameError, setBankNameError] = useState("");
    const [bvnError, setBVNError] = useState("");
    const [accountNameError, setAccountNameError] = useState("");
    const [accountNumberError, setAccountNumberError] = useState("");
    const [addressError, setAddressError] = useState("");
    const [newAddressError, setNewAddressError] = useState("");
    const [birthDateError, setBirthDateError] = useState("");
    const [phoneError, setPhoneError] = useState("");

    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());

    const isStep = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    function getBanks() {
        const banks = NGBanks.getBanks();
        // console.log(banks);
        return Object.entries(banks).map(bank => (
                <MenuItem value={bank[1].name} name={bank[1].slug} key={bank[1].slug}>
                    {bank[1].name}
                </MenuItem>
        ))
    }

    function openBVNImageDialog(e) {
        e.preventDefault();
        document.getElementById('bvnImageInput').click();
    }

    return (
        <Box>
            <Scrollbar>
                <Grid container spacing={0} className="container-full  vh-100">
                <TopNavBar />
                    <Grid item xs={12} sm={8} className="form-outer">
                            <Box component="form" noValidate autoComplete="off" 
                                pr={6} pl={25} py={5} className="form-container">
                                    <Stack sx={{ width: '30%' }} spacing={0}>
                                        <Stepper activeStep={activeStep} connector={<KraneConnector />}>
                                            {steps.map((label) => (
                                            <Step key={label}>
                                                <StepLabel StepIconComponent={KraneStepIcon}>{label}</StepLabel>
                                            </Step>
                                            ))}
                                        </Stepper>
                                    </Stack>
                                    <Box>
                                        {activeStep === 0 ? (
                                            <>
                                            <Box mt={5} className="form-text">
                                                <Typography variant="h4" color="#000" fontWeight={500} lineHeight={1}>
                                                    Bank <br /> information
                                                </Typography>
                                                <Typography variant="subtitle1" fontWeight={500} mt={1} lineHeight={1.2}>
                                                    Kindly make sure to provide the details of your most active bank account
                                                </Typography>
                                            </Box>
                                            <Grid container mt={2} spacing={3}>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <FormControl variant="standard" fullWidth error={!!bankNameError}>
                                                        <InputLabel shrink htmlFor={'bankNameInput'} sx={{fontSize:'1.2rem'}} >
                                                            Bank Name
                                                        </InputLabel>
                                                        <Select id={'bankNameInput'} value={bankName}
                                                            onChange={e => setBankName(e.target.value)}
                                                            input={<KraneInput />}
                                                            >
                                                            {getBanks()}
                                                        </Select>
                                                        <FormHelperText>{!!bankNameError ? 
                                                            (bankNameError) : ('')}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <FormControl variant="standard" fullWidth error={!!bvnError}>
                                                        <InputLabel shrink htmlFor={'bvnInput'} sx={{fontSize:'1.2rem'}} >
                                                            BVN
                                                        </InputLabel>
                                                        <KraneInput id={'bvnInput'} value={bvn}
                                                            onChange={e => setBVN(e.target.value)}
                                                            inputComponent={NumericFormatCustom}
                                                            />
                                                        <FormHelperText>{!!bvnError ? 
                                                            (bvnError) : ('')}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <FormControl variant="standard" fullWidth error={!!accountNumberError}>
                                                        <InputLabel shrink htmlFor={'accountNumberInput'} sx={{fontSize:'1.2rem'}} >
                                                            Account Number
                                                        </InputLabel>
                                                        <KraneInput id={'accountNumberInput'} value={accountNumber}
                                                            onChange={e => setAccountNumber(e.target.value)}
                                                            inputComponent={NumericFormatCustom}
                                                            />
                                                        <FormHelperText>{!!accountNumberError ? 
                                                            (accountNumberError) : ('')}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <FormControl variant="standard" fullWidth error={!!accountNameError}>
                                                        <InputLabel shrink htmlFor={'accountNameInput'} sx={{fontSize:'1.2rem'}} >
                                                            Account Name
                                                        </InputLabel>
                                                        <KraneInput id={'accountNameInput'} value={accountName}
                                                            onChange={e => setAccountName(e.target.value)} 
                                                            />
                                                        <FormHelperText>{!!accountNameError ? 
                                                            (accountNameError) : ('')}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>                                            
                                            <Typography color={'#6c757d'} variant="subtitle1" fontWeight={500} mt={2} mb={1} lineHeight={1.2}>
                                                We would like to request for your bank statement 
                                            </Typography>
                                            <Grid container mt={0.5}>
                                                <Grid item>
                                                    <FormControlLabel control={<Checkbox />} 
                                                        checked={selected} onChange={() => {
                                                            setSelected(!selected);
                                                        }}
                                                        label="Yes, I consent to this" />
                                                </Grid>
                                            </Grid>
                                            <Grid container mt={8} spacing={3}>
                                                <Grid item xs={12} sm={12} md={6}>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <Button onClick={handleNext} className="w-100 text-capitalize" variant="contained">Submit</Button>
                                                </Grid>
                                            </Grid>
                                            </>
                                        ) : (
                                            <React.Fragment>
                                                {activeStep === 1 ? (
                                                    <>
                                                    <Box mt={5} className="form-text">
                                                        <Typography variant="h4" color="#000" fontWeight={500} lineHeight={1}>
                                                            KYC <br /> information
                                                        </Typography>
                                                        <Typography variant="subtitle1" fontWeight={500} mt={1} lineHeight={1.2}>
                                                            You are almost done with your account
                                                        </Typography>
                                                    </Box>
                                                    <Grid container mt={2} spacing={3}>
                                                        <Grid item xs={12} sm={12}>
                                                                <InputLabel htmlFor={'bvnImageInput'} shrink sx={{fontSize:'1.2rem'}} >
                                                                    BVN Image
                                                                </InputLabel>
                                                                <Box component={"button"} onClick={e => openBVNImageDialog(e)} 
                                                                    bgcolor={'#5806C3'} border={0} width={'8rem'} height={'8rem'}
                                                                    error={!!bvnImageError} htmlFor={'bvnImageInput'}>
                                                                    <img src={noImage} alt="upload icon" 
                                                                            className=""/>
                                                                </Box>                                                                
                                                                <KraneInput onChange={e => {setBVNImage(e.target.files[0])}} hidden 
                                                                    id={'bvnImageInput'} type="file"/>
                                                                <FormHelperText>{!!bvnImageError ? 
                                                                    (bvnImageError) : ('')}</FormHelperText>          
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6}>
                                                            <FormControl variant="standard" fullWidth error={!!birthDateError}>
                                                                <InputLabel shrink htmlFor={'birthDateInput'} sx={{fontSize:'1.2rem'}} >
                                                                    Date of Birth
                                                                </InputLabel>
                                                                <KraneInput id={'birthDateInput'} value={birthDate}
                                                                    type="date"
                                                                    onChange={e => setBirthDate(e.target.value)} 
                                                                    />
                                                                <FormHelperText>{!!birthDateError ? 
                                                                    (birthDateError) : ('')}</FormHelperText>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6}>
                                                            <FormControl variant="standard" fullWidth error={!!phoneError}>
                                                                <InputLabel shrink htmlFor={'phoneInput'} sx={{fontSize:'1.2rem'}} >
                                                                    Phone Number
                                                                </InputLabel>
                                                                <KraneInput id={'phoneInput'} value={phone}
                                                                    onChange={e => setPhone(e.target.value)} 
                                                                    inputComponent={NumericFormatCustom}
                                                                    />
                                                                <FormHelperText>{!!phoneError ? 
                                                                    (phoneError) : ('')}</FormHelperText>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} mt={1}>
                                                            <Divider sx={{border: '1px solid #C4C4C4'}}/>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6}>
                                                            <FormControl variant="standard" fullWidth error={!!addressError}>
                                                                <InputLabel shrink htmlFor={'addressInput'} sx={{fontSize:'1.2rem'}} >
                                                                    Address
                                                                </InputLabel>
                                                                <KraneInput id={'addressInput'} value={address}
                                                                    onChange={e => setAddress(e.target.value)} 
                                                                    />
                                                                <FormHelperText>{!!addressError ? 
                                                                    (addressError) : ('')}</FormHelperText>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                    <Typography color={'#6c757d'} variant="subtitle1" fontWeight={500} mt={2} mb={1} lineHeight={1.2}>
                                                        Is this your current Address ?
                                                    </Typography>
                                                    <Grid container mt={0.5}>
                                                        <Grid item>
                                                            <FormControlLabel control={<Checkbox />} 
                                                                checked={addressSelected} onChange={() => {
                                                                    setAddressSelected(!addressSelected);
                                                                }}
                                                                label="I have a new address" />
                                                        </Grid>
                                                    </Grid>
                                                    {addressSelected ? (
                                                        <Grid container mt={1.5}>
                                                            <Grid item xs={12} sm={12} md={6}>
                                                            <FormControl variant="standard" fullWidth error={!!newAddressError}>
                                                                <InputLabel shrink htmlFor={'newAddressInput'} sx={{fontSize:'1.2rem'}} >
                                                                    New Address
                                                                </InputLabel>
                                                                <KraneInput id={'newAddressInput'} value={address}
                                                                    onChange={e => setNewAddress(e.target.value)} 
                                                                    />
                                                                <FormHelperText>{!!newAddressError ? 
                                                                    (newAddressError) : ('')}</FormHelperText>
                                                            </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    ) : (<></>)}
                                                    <Grid container mt={8} spacing={3}>
                                                            <Grid item xs={12} sm={6} md={6}>
                                                                <Button onClick={handleBack} className="w-100 text-capitalize" variant="text">Go back</Button>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={6}>
                                                                <Button onClick={handleNext} className="w-100 text-capitalize" variant="contained">Submit</Button>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Box mt={5} className="form-text">
                                                            <Typography variant="h4" color="#000" fontWeight={500} lineHeight={1}>
                                                                Card Details
                                                            </Typography>
                                                            <Typography variant="subtitle1" fontWeight={500} mt={1} lineHeight={1.2}>
                                                                Please make sure to provide  the card connected to your main bank account provided earlier
                                                            </Typography>
                                                        </Box>
                                                        <Grid container mt={2} spacing={3}>
                                                            <Grid item xs={12} sm={12} md={6}>
                                                                <FormControl variant="standard" fullWidth error={!!bankNameError}>
                                                                    <InputLabel shrink htmlFor={'bankNameInput'} sx={{fontSize:'1.2rem'}} >
                                                                        Card Number
                                                                    </InputLabel>
                                                                    <KraneInput id={'bankNameInput'} value={bankName}
                                                                        onChange={e => setBankName(e.target.value)}
                                                                        inputComponent={CardNumericFormatCustom}
                                                                        />
                                                                    <FormHelperText>{!!bankNameError ? 
                                                                        (bankNameError) : ('')}</FormHelperText>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6}>
                                                                <FormControl variant="standard" fullWidth error={!!bvnError}>
                                                                    <InputLabel shrink htmlFor={'bvnInput'} sx={{fontSize:'1.2rem'}} >
                                                                        Expiry Date
                                                                    </InputLabel>
                                                                    <KraneInput id={'bvnInput'} value={bvn}
                                                                        onChange={e => setBVN(e.target.value)}
                                                                        inputComponent={CardExpiryNumericFormatCustom}
                                                                        />
                                                                    <FormHelperText>{!!bvnError ? 
                                                                        (bvnError) : ('')}</FormHelperText>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6}>
                                                                <FormControl variant="standard" fullWidth error={!!accountNumberError}>
                                                                    <InputLabel shrink htmlFor={'accountNumberInput'} sx={{fontSize:'1.2rem'}} >
                                                                        CVV2
                                                                    </InputLabel>
                                                                    <KraneInput id={'accountNumberInput'} value={accountNumber}
                                                                        onChange={e => setAccountNumber(e.target.value)} 
                                                                        inputComponent={CardCVVNumericFormatCustom}
                                                                        />
                                                                    <FormHelperText>{!!accountNumberError ? 
                                                                        (accountNumberError) : ('')}</FormHelperText>
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                        <Typography color={"#000"} fontSize={14} variant="subtitle1" fontWeight={500} mt={2} lineHeight={1.2}>
                                                            <GppGoodOutlined color="primary" />The details of your account is secured
                                                        </Typography>
                                                        <Grid container mt={8} spacing={3}>
                                                            <Grid item xs={12} sm={6} md={6}>
                                                                <Button onClick={handleBack} className="w-100 text-capitalize" variant="text">Go back</Button>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={6}>
                                                                <Button onClick={handleNext} className="w-100 text-capitalize" variant="contained">Done</Button>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </Box>
                            </Box>
                    </Grid>
                    <Side />
                </Grid>            
            </Scrollbar>
        </Box>
    )
}

export default Information;