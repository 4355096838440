import { useNavigate } from "react-router-dom";
import { Grid, Typography, Paper, Box, Button, CircularProgress, IconButton, FormControl, InputLabel, Divider } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { useState } from "react";
import { HighlightOff } from "@mui/icons-material";

import Nav from "../../../../components/navs/coopDasboardNav";
import net from "../../../../assets/images/net.svg"
import calculator from "../../../../assets/images/calculator.svg"
import plane from "../../../../assets/images/paper-plane.svg"
import { ReactComponent as CopyIcon } from "../../../../assets/images/copy-icon.svg";
import { KraneInput } from "../../../../components/inputs";
import toast from 'react-hot-toast'
import Salutation from "../../../../components/salutation";


const CoopDashboard = () => {

  const [open, setOpen] = useState(false);
  const [openShare, setOpenShare] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickShareOpen = () => {
    setOpenShare(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenShare(false);
  };

  const Comp = () => {

    const navigate = useNavigate();
    return (
      <Box>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingRight: { xs: 4, md: 5, lg: 5 } }}>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between" }}>
            <Salutation />
          </Box>
          <Button onClick={handleClickShareOpen} size={"small"} sx={{ py: { xs: "0.25rem", sm: "0.5rem", lg: "0.75rem" }, px: { xs: "1rem", sm: "1.5rem", lg: "2rem" }, borderRadius: 0, boxShadow: "none" }} variant="contained">
            Share link
          </Button>
        </Box>
        <Typography variant="subtitle1" fontSize={"0.8rem"} fontWeight={600} mt={6}>
          QUICK ACTIONS
        </Typography>
        <Grid container spacing={{ xs: 2, xl: 4 }} pt={2} sx={{ paddingRight: { xs: 4, md: 5, lg: 5 } }}>
          <Grid item xs={12} sm={6} sx={{ height: "100%" }}>
            <Paper square elevation={0} sx={{ height: { xs: "15rem", md: "18rem", lg: "20rem", xl: "20rem" }, paddingLeft: 3, paddingRight: 1.5, paddingY: 3, display: "flex", flexDirection: "column" }}>
              <Box>
                <Typography variant="h6" fontSize={{ xs: "1.5rem", lg: "2rem", xl: "2.5rem" }} sx={{ width: { xs: "80%", sm: "60%", lg: "70%", xl: "30%" } }} lineHeight={"110%"} fontWeight={400} color={"primary"}>
                  Apply for Vehicle financing
                </Typography>
                <Typography variant="subtitle1" fontSize={{ xs: "0.8rem", lg: "1rem", xl: "1.25rem" }} sx={{ width: { xs: "80%", sm: "45%", lg: "55%", xl: "30%" } }} lineHeight={"100%"} fontWeight={300} color={"#858585"} mt={1}>
                  Get financing for the vehicle that can fund your dreams
                </Typography>
              </Box>
              <Button onClick={e => navigate('/account/individual/credit-application')} size={"small"} sx={{ height: { xs: '50px', sm: '70px' }, mt: "auto", ml: "auto", width: { xs: "90%", sm: "80%", md: "70%", lg: "50%", xl: "40%" }, borderRadius: 0, boxShadow: "none" }} variant="contained">
                Apply for credit
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ height: "100%" }}>
            <Paper square elevation={0} sx={{ height: { xs: "15rem", md: "18rem", lg: "20rem", xl: "20rem" }, paddingLeft: 3, paddingRight: 1.5, paddingY: 3, display: "flex", flexDirection: "column", position: "relative" }}>
              <div
                style={{
                  backgroundImage: `url(${net})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain", backgroundPosition: "center",
                  position: "absolute", top: 0, left: 0, width: "100%", height: "100%"
                }}
              ></div>
              <img src={calculator} alt="loan calculator" width={"40%"} height={"auto"} style={{ position: "absolute", right: "1rem", bottom: "3rem" }} />
              <Box>
                <Typography variant="h6" fontSize={{ xs: "1.5rem", lg: "2rem", xl: "2.5rem" }} sx={{ width: { xs: "80%", sm: "60%", lg: "70%", xl: "30%" } }} lineHeight={"110%"} fontWeight={400} color={"primary"}>
                  Use the krane Calculator
                </Typography>
                <Typography variant="subtitle1" fontSize={{ xs: "0.8rem", lg: "1rem", xl: "1.25rem" }} sx={{ width: { xs: "80%", sm: "45%", lg: "55%", xl: "30%" } }} lineHeight={"100%"} fontWeight={300} color={"#858585"} mt={1}>
                  For as low as 2.5% per month, get to know what you can access in terms of financing.
                </Typography>
              </Box>
              <Button onClick={handleClickOpen} size={"small"} sx={{ height: { xs: '50px', sm: '70px' }, mt: "auto", ml: "auto", width: { xs: "90%", sm: "80%", md: "70%", lg: "50%", xl: "40%" }, zIndex: 3, borderRadius: 0, boxShadow: "none" }} variant="contained">
                Use Loan Calculator
              </Button>
            </Paper>
          </Grid>
        </Grid>
        <Typography variant="subtitle1" fontSize={"0.8rem"} fontWeight={600} mt={10}>
          NOTIFICATION CENTRE
        </Typography>
        <Grid container spacing={4} sx={{ paddingRight: { xs: 4, md: 7, lg: 10 } }} pt={2}>
          <Grid item xs={12} sm={8} lg={6}>
            <Paper square elevation={0} sx={{ height: "8rem", display: "flex" }}>
              <Box sx={{ width: { xs: "2%", md: "3%" }, height: "100%", bgcolor: "#E1BF41" }}></Box>
              <Box px={3} pt={2}>
                <CircularProgress size={"3rem"} />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }} py={2} pr={2}>
                <Typography variant="h6" fontWeight={500} lineHeight={"100%"} fontSize={{ xs: "1rem", md: "1.25rem", xl: "1.75rem" }}>
                  Your application is under review.
                </Typography>
                <Typography variant="subtitle1" color={"#858585"} lineHeight={"100%"} fontSize={{ xs: "0.8rem", md: "1rem", lg: "1.25rem" }}>
                  We are currently going through your credentials
                </Typography>
                <Typography variant="h6" color={"primary"} fontWeight={500} lineHeight={"100%"} fontSize={{ xs: "0.6rem", md: "0.8rem", lg: "1rem" }}>
                  This would take about 20 mins
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Typography variant="subtitle1" fontSize={"0.8rem"} fontWeight={600} mt={10}>
          RECENT TRANSACTIONS
        </Typography>
        <Grid container spacing={4} sx={{ paddingRight: { xs: 4, md: 7, lg: 10 } }} pt={2}>
          <Grid item xs={12} sm={8} lg={6}>
            <Paper square elevation={0} sx={{ height: "5rem", display: "flex" }}>
              <Box sx={{ width: { xs: "2%", md: "3%" }, height: "100%", bgcolor: "#8F8F8F" }}></Box>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }} py={2} pl={4}>
                <Typography variant="subtitle1" lineHeight={"100%"} fontSize={{ xs: "0.8rem", md: "1rem", lg: "1.25rem" }}>
                  Reach out to an agent
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            component: 'form',
            onSubmit: (event) => {
              event.preventDefault();
              // const formData = new FormData(event.currentTarget);
              // const formJson = Object.fromEntries(formData.entries());
              // const email = formJson.email;
              // console.log(email);
              handleClose();
            },
          }}
        >
          <DialogTitle>
            <Box sx={{ backgroundColor: "#FCFCFC", width: "100%", display: "flex", py: "1rem", alignItems: "center", justifyContent: "flex-end" }}>
              <IconButton aria-label="close" onClick={handleClose}><HighlightOff /></IconButton>
            </Box>
          </DialogTitle>
          <DialogContent sx={{ px: "4rem", mb: { xs: "2rem", sm: "4rem" } }}>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: "1rem", maxWidth: "65%", textAlign: "center", alignSelf: "center" }}>
                <img src={calculator} alt="calculator" />
                <Typography color={"primary"} mt={2}>Loan Calculator</Typography>
                <Typography color={"#858585"} mt={1}>Input the following information to calculate how much you can have access to</Typography>
              </Box>
              <Grid container spacing={4} mt={"2rem"}>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="standard" fullWidth required>
                    <InputLabel shrink htmlFor={'inflow'} sx={{ fontSize: '1.2rem' }} >
                      Monthly inflow
                    </InputLabel>
                    <KraneInput id={'inflow'} required />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="standard" fullWidth required>
                    <InputLabel shrink htmlFor={'amount'} sx={{ fontSize: '1.2rem' }} >
                      Request Amount
                    </InputLabel>
                    <KraneInput id={'amount'} required />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel shrink htmlFor={'tenor'} sx={{ fontSize: '1.2rem' }} >
                      Tenor
                    </InputLabel>
                    <KraneInput id={'tenor'} readOnly />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="standard" fullWidth >
                    <InputLabel shrink htmlFor={'rate'} sx={{ fontSize: '1.2rem' }} >
                      Rate
                    </InputLabel>
                    <KraneInput id={'rate'} readOnly />
                  </FormControl>
                </Grid>
                <Grid item xs={0} sm={6}>

                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button size={"small"} type="submit" fullWidth sx={{ py: { xs: "0.25rem", sm: "0.5rem", lg: "0.75rem" }, px: { xs: "1rem", sm: "1.5rem", lg: "2rem" }, borderRadius: 0, boxShadow: "none", textTransform: "none" }} variant="contained">
                    Calculate
                  </Button>
                </Grid>
              </Grid>
              <Divider flexItem sx={{ my: "2rem", borderBottom: "2px solid #C4C4C4" }} />
              <Grid container spacing={4}>
                <Grid item xs={6} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <Typography color={"#858585"} mt={2}>Loan Max Amount</Typography>
                  <Typography color={"primary"} fontFamily={"'Outfit', sans-serif"} fontSize={{ xs: "1rem", md: "1.5rem", lg: "2rem" }}>₦3,000,000</Typography>
                </Grid>
                <Grid item xs={6} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <Typography color={"#858585"} mt={2}>Max Tenor</Typography>
                  <Typography color={"primary"} fontFamily={"'Outfit', sans-serif"} fontSize={{ xs: "1rem", md: "1.5rem", lg: "2rem" }}>₦3,000,000</Typography>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openShare}
          onClose={handleClose}
        >
          <DialogTitle>
            <Box sx={{ backgroundColor: "#FCFCFC", width: "100%", display: "flex", py: "1rem", alignItems: "center", justifyContent: "flex-end" }}>
              <IconButton aria-label="close" onClick={handleClose}><HighlightOff /></IconButton>
            </Box>
          </DialogTitle>
          <DialogContent sx={{ px: "6rem", mb: { xs: "2rem", sm: "4rem" } }}>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: "1rem", maxWidth: "70%", textAlign: "center", alignSelf: "center" }}>
                <img src={plane} alt="Paper plane" />
                <Typography color={"primary"} fontWeight={500} mt={2}>Share secured link</Typography>
                <Typography color={"#858585"} mt={1}>Members of your Cooperative can easily onboard using your link.</Typography>
              </Box>
              <Box sx={{ backgroundColor: "#F7F3FC", display: "flex", py: "1rem", mt: 2, justifyContent: "center", alignItems: "center", width: "100%" }}>
                <Typography>Krane.finance/vio289Cooperativename</Typography>
              </Box>
              <CopyToClipboard text="Krane.finance/vio289Cooperativename" onCopy={()=>toast.success("Link copied to clipboard")}>
              <Button onClick={()=>{}} size={"small"} sx={{ px: { xs: "2rem", sm: "3rem", md: "4rem" }, py: { xs: "0.75rem", sm: "0.875rem", lg: "1rem" }, mt: 4, gap: 1, borderRadius: 0, boxShadow: "none", width: "fit-content" }} variant="contained">
                <CopyIcon /> Copy
              </Button>
              </CopyToClipboard>
            </Box>
          </DialogContent>
          <DialogActions>
          </DialogActions>
        </Dialog>
      </Box>
    )
  }

  return (
    <Nav active="dashboard" activePage={0} comp={Comp} />
  )
}


export default CoopDashboard;