import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { State }  from 'country-state-city';
import toast from 'react-hot-toast'
import Scrollbar from 'perfect-scrollbar-react';
import NGBanks from 'ng-banks';
import { PatternFormat } from 'react-number-format';

import { Box, Grid, Typography, 
    Button, FormControl, InputLabel, 
    Select, MenuItem, Divider, FormHelperText, 
    FormControlLabel, Checkbox } from '@mui/material';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Side from "../../components/coop-side";

import { KraneInput } from '../../components/inputs';
import { KraneConnector, KraneStepIcon } from "../../components/stepper";
import Upload from '../../assets/images/upload.svg';
import { UploadButton } from "../../components/buttons";
import TopNavBar from "../../components/navs/topNav";

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
    props,
    ref,
  ) {
    const { onChange, ...other } = props;
  
    return (
      <PatternFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        format="###########"
      />
    );
  });
  
  NumericFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

const CoopInformation = () => {

    const steps = ['', '', ''];
    const [selected, setSelected] = React.useState(false);

    const [noBranches, setNoBranches] = useState("");
    const [opStates, setOpStates] = useState([]);
    const [name1, setName1] = useState("");
    const [role1, setRole1] = useState("");
    const [number1, setNumber1] = useState("");
    const [email1, setEmail1] = useState("");
    const [bvn1, setBVN1] = useState("");
    const [nin1, setNIN1] = useState("");
    const [name2, setName2] = useState("");
    const [role2, setRole2] = useState("");
    const [number2, setNumber2] = useState("");
    const [email2, setEmail2] = useState("");
    const [bvn2, setBVN2] = useState("");
    const [nin2, setNIN2] = useState("");
    const [name3, setName3] = useState("");
    const [role3, setRole3] = useState("");
    const [number3, setNumber3] = useState("");
    const [email3, setEmail3] = useState("");
    const [bvn3, setBVN3] = useState("");
    const [nin3, setNIN3] = useState("");
    const [bankName, setBankName] = useState("");
    const [bvn, setBVN] = useState("");
    const [accountName, setAccountName] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [address, setAddress] = useState("");
    const [resolution, setResolution] = useState(null);
    const [cac, setCAC] = useState(null);
    const [tin, setTIN] = useState(null);
    const [moa, setMOA] = useState(null);
    const [mou, setMOU] = useState(null);

    const [noBranchesError, setNoBranchesError] = useState("");
    const [opStatesError, setOpStatesError] = useState("");
    const [name1Error, setName1Error] = useState("");
    const [role1Error, setRole1Error] = useState("");
    const [number1Error, setNumber1Error] = useState("");
    const [email1Error, setEmail1Error] = useState("");
    const [bvn1Error, setBVN1Error] = useState("");
    const [nin1Error, setNIN1Error] = useState("");
    const [name2Error, setName2Error] = useState("");
    const [role2Error, setRole2Error] = useState("");
    const [number2Error, setNumber2Error] = useState("");
    const [email2Error, setEmail2Error] = useState("");
    const [bvn2Error, setBVN2Error] = useState("");
    const [nin2Error, setNIN2Error] = useState("");
    const [name3Error, setName3Error] = useState("");
    const [role3Error, setRole3Error] = useState("");
    const [number3Error, setNumber3Error] = useState("");
    const [email3Error, setEmail3Error] = useState("");
    const [bvn3Error, setBVN3Error] = useState("");
    const [nin3Error, setNIN3Error] = useState("");
    const [bankNameError, setBankNameError] = useState("");
    const [bvnError, setBVNError] = useState("");
    const [accountNameError, setAccountNameError] = useState("");
    const [accountNumberError, setAccountNumberError] = useState("");
    const [addressError, setAddressError] = useState("");
    const [resolutionError, setResolutionError] = useState("");
    const [cacError, setCACError] = useState("");
    const [tinError, setTINError] = useState("");
    const [moaError, setMOAError] = useState("");
    const [mouError, setMOUError] = useState("");

    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());

    const roles = ['President', 'Vice President', 'Treasurer', 'General Secretary', 'CFO']

    const isStep = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    function getBanks() {
        const banks = NGBanks.getBanks();
        // console.log(banks);
        return Object.entries(banks).map(bank => (
                <MenuItem value={bank[1].name} name={bank[1].slug} key={bank[1].slug}>
                    {bank[1].name}
                </MenuItem>
        ))
    }

    function openResolutionDialog() {
        document.getElementById('resolutionInput').click();
    }
    function openCACDialog() {
        document.getElementById('cacInput').click();
    }
    function openTINDialog() {
        document.getElementById('tinInput').click();
    }
    function openMOADialog() {
        document.getElementById('moaInput').click();
    }
    function openMOUDialog() {
        document.getElementById('mouInput').click();
    }

    return (
        <Box>
            <Scrollbar>
                <Grid container spacing={0} className="container-full  vh-100">
                    {/* <TopNavBar /> */}
                    <Grid item xs={12} sm={8} className="form-outer">
                            <Box component="form" noValidate autoComplete="off" 
                                pr={6} pl={25} py={5} className="form-container">
                                    <Stack sx={{ width: '30%' }} spacing={0}>
                                        <Stepper activeStep={activeStep} connector={<KraneConnector />}>
                                            {steps.map((label) => (
                                            <Step key={label}>
                                                <StepLabel StepIconComponent={KraneStepIcon}>{label}</StepLabel>
                                            </Step>
                                            ))}
                                        </Stepper>
                                    </Stack>
                                    <Box>
                                        {activeStep === 0 ? (
                                            <>
                                            <Box mt={5} className="form-text">
                                                <Typography variant="h4" color="#000" fontWeight={500} lineHeight={1}>
                                                    Cooperative <br /> information
                                                </Typography>
                                                <Typography variant="subtitle1" fontWeight={500} mt={1} lineHeight={1.2}>
                                                    Kindly make sure to provide the correct details of the cooperative's leadership
                                                </Typography>
                                            </Box>
                                            <Grid container mt={2} spacing={3}>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <FormControl variant="standard" fullWidth error={!!noBranchesError}>
                                                        <InputLabel shrink htmlFor={'noBranchesInput'} sx={{fontSize:'1.2rem'}} >
                                                            No of Branches
                                                        </InputLabel>
                                                        <KraneInput id={'noBranchesInput'} value={noBranches}
                                                            onChange={e => setNoBranches(e.target.value)}
                                                            type="number"
                                                            />
                                                        <FormHelperText>{!!noBranchesError ? 
                                                            (noBranchesError) : ('')}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <FormControl variant="standard" fullWidth error={!!opStatesError}>
                                                        <InputLabel shrink htmlFor={'opStatesInput'} sx={{fontSize:'1.2rem'}} >
                                                            Operating States
                                                        </InputLabel>
                                                        <Select id={'opStatesInput'} value={opStates}
                                                            onChange={e => setOpStates(e.target.value)}
                                                            input={<KraneInput />}
                                                            multiple
                                                            renderValue={(selected) => selected.join(', ')}
                                                            >
                                                            {Object.entries(State.getStatesOfCountry('NG')).map(state => (
                                                                <MenuItem value={state[1].name} id={state[1].isoCode} key={state[1].isoCode}>
                                                                    <Checkbox checked={opStates.indexOf(state[1].name) > -1} />{state[1].name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        <FormHelperText>{!!opStatesError ? 
                                                            (opStatesError) : ('')}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} m={0}>
                                                    <Divider sx={{border: '1px solid #C4C4C4'}}/>
                                                </Grid>
                                                <Grid item xs={12} m={0}>
                                                    <Typography className="text-uppercase">Cooperative leadership</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <FormControl variant="standard" fullWidth error={!!name1Error}>
                                                        <InputLabel shrink htmlFor={'name1Input'} sx={{fontSize:'1.2rem'}} >
                                                            Full Name (Leader 1)
                                                        </InputLabel>
                                                        <KraneInput id={'name1Input'} value={name1}
                                                            onChange={e => setName1(e.target.value)}
                                                            />
                                                        <FormHelperText>{!!name1Error ? 
                                                            (name1Error) : ('')}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <FormControl variant="standard" fullWidth error={!!role1Error}>
                                                        <InputLabel shrink htmlFor={'role1Input'} sx={{fontSize:'1.2rem'}} >
                                                            Role
                                                        </InputLabel>
                                                        <Select id={'role1Input'} value={role1}
                                                            onChange={e => {setRole1(e.target.value)}}
                                                            input={<KraneInput />}>
                                                                {roles.map((position) => (
                                                                    <MenuItem value={position} key={position} name={position}>
                                                                        {position}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                        <FormHelperText>{!!role1Error ? 
                                                            (role1Error) : ('')}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <FormControl variant="standard" fullWidth error={!!number1Error}>
                                                        <InputLabel shrink htmlFor={'number1Input'} sx={{fontSize:'1.2rem'}} >
                                                            Phone Number
                                                        </InputLabel>
                                                        <KraneInput id={'number1Input'} value={number1}
                                                            onChange={e => setNumber1(e.target.value)}
                                                            inputComponent={NumericFormatCustom}
                                                            />
                                                        <FormHelperText>{!!number1Error ? 
                                                            (number1Error) : ('')}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <FormControl variant="standard" fullWidth error={!!email1Error}>
                                                        <InputLabel shrink htmlFor={'email1Input'} sx={{fontSize:'1.2rem'}} >
                                                            Email
                                                        </InputLabel>
                                                        <KraneInput id={'email1Input'} value={email1}
                                                            onChange={e => setEmail1(e.target.value)}
                                                            />
                                                        <FormHelperText>{!!email1Error ? 
                                                            (email1Error) : ('')}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <FormControl variant="standard" fullWidth error={!!bvn1Error}>
                                                        <InputLabel shrink htmlFor={'bvn1Input'} sx={{fontSize:'1.2rem'}} >
                                                            BVN
                                                        </InputLabel>
                                                        <KraneInput id={'bvn1Input'} value={bvn1}
                                                            onChange={e => setBVN1(e.target.value)}
                                                            inputComponent={NumericFormatCustom}
                                                            />
                                                        <FormHelperText>{!!bvn1Error ? 
                                                            (bvn1Error) : ('')}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <FormControl variant="standard" fullWidth error={!!nin1Error}>
                                                        <InputLabel shrink htmlFor={'nin1Input'} sx={{fontSize:'1.2rem'}} >
                                                            NIN
                                                        </InputLabel>
                                                        <KraneInput id={'nin1Input'} value={nin1}
                                                            onChange={e => setNIN1(e.target.value)}
                                                            inputComponent={NumericFormatCustom}
                                                            />
                                                        <FormHelperText>{!!nin1Error ? 
                                                            (nin1Error) : ('')}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={1} m={0}>
                                                    <Divider sx={{border: '1px solid #5634D1'}}/>
                                                </Grid>
                                                <Grid item xs={11} m={0}>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <FormControl variant="standard" fullWidth error={!!name2Error}>
                                                        <InputLabel shrink htmlFor={'name2Input'} sx={{fontSize:'1.2rem'}} >
                                                            Full Name (Leader 2)
                                                        </InputLabel>
                                                        <KraneInput id={'name2Input'} value={name2}
                                                            onChange={e => setName2(e.target.value)}
                                                            />
                                                        <FormHelperText>{!!name2Error ? 
                                                            (name2Error) : ('')}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <FormControl variant="standard" fullWidth error={!!role2Error}>
                                                        <InputLabel shrink htmlFor={'role2Input'} sx={{fontSize:'1.2rem'}} >
                                                            Role
                                                        </InputLabel>
                                                        <Select id={'role2Input'} value={role2}
                                                            onChange={e => {setRole2(e.target.value)}}
                                                            input={<KraneInput />}>
                                                                {roles.map((position) => (
                                                                    <MenuItem value={position} key={position} name={position}>
                                                                        {position}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                        <FormHelperText>{!!role2Error ? 
                                                            (role2Error) : ('')}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <FormControl variant="standard" fullWidth error={!!number2Error}>
                                                        <InputLabel shrink htmlFor={'number2Input'} sx={{fontSize:'1.2rem'}} >
                                                            Phone Number
                                                        </InputLabel>
                                                        <KraneInput id={'number2Input'} value={number2}
                                                            onChange={e => setNumber2(e.target.value)}
                                                            inputComponent={NumericFormatCustom}
                                                            />
                                                        <FormHelperText>{!!number2Error ? 
                                                            (number2Error) : ('')}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <FormControl variant="standard" fullWidth error={!!email2Error}>
                                                        <InputLabel shrink htmlFor={'email2Input'} sx={{fontSize:'1.2rem'}} >
                                                            Email
                                                        </InputLabel>
                                                        <KraneInput id={'email2Input'} value={email2}
                                                            onChange={e => setEmail2(e.target.value)}
                                                            />
                                                        <FormHelperText>{!!email2Error ? 
                                                            (email2Error) : ('')}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <FormControl variant="standard" fullWidth error={!!bvn2Error}>
                                                        <InputLabel shrink htmlFor={'bvn2Input'} sx={{fontSize:'1.2rem'}} >
                                                            BVN
                                                        </InputLabel>
                                                        <KraneInput id={'bvn2Input'} value={bvn2}
                                                            onChange={e => setBVN2(e.target.value)}
                                                            inputComponent={NumericFormatCustom}
                                                            />
                                                        <FormHelperText>{!!bvn2Error ? 
                                                            (bvn2Error) : ('')}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <FormControl variant="standard" fullWidth error={!!nin2Error}>
                                                        <InputLabel shrink htmlFor={'nin2Input'} sx={{fontSize:'1.2rem'}} >
                                                            NIN
                                                        </InputLabel>
                                                        <KraneInput id={'ninInput'} value={nin2}
                                                            onChange={e => setNIN2(e.target.value)}
                                                            inputComponent={NumericFormatCustom}
                                                            />
                                                        <FormHelperText>{!!nin2Error ? 
                                                            (nin2Error) : ('')}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={1} m={0}>
                                                    <Divider sx={{border: '1px solid #5634D1'}}/>
                                                </Grid>                                                
                                                <Grid item xs={11} m={0}>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <FormControl variant="standard" fullWidth error={!!name3Error}>
                                                        <InputLabel shrink htmlFor={'name3Input'} sx={{fontSize:'1.2rem'}} >
                                                            Full Name (Leader 3)
                                                        </InputLabel>
                                                        <KraneInput id={'name3Input'} value={name3}
                                                            onChange={e => setName3(e.target.value)}
                                                            />
                                                        <FormHelperText>{!!name3Error ? 
                                                            (name3Error) : ('')}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <FormControl variant="standard" fullWidth error={!!role3Error}>
                                                        <InputLabel shrink htmlFor={'role3Input'} sx={{fontSize:'1.2rem'}} >
                                                            Role
                                                        </InputLabel>
                                                        <Select id={'role3Input'} value={role3}
                                                            onChange={e => {setRole3(e.target.value)}}
                                                            input={<KraneInput />}>
                                                                {roles.map((position) => (
                                                                    <MenuItem value={position} key={position} name={position}>
                                                                        {position}
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                        <FormHelperText>{!!role3Error ? 
                                                            (role3Error) : ('')}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <FormControl variant="standard" fullWidth error={!!number3Error}>
                                                        <InputLabel shrink htmlFor={'number3Input'} sx={{fontSize:'1.2rem'}} >
                                                            Phone Number
                                                        </InputLabel>
                                                        <KraneInput id={'number3Input'} value={number3}
                                                            onChange={e => setNumber3(e.target.value)}
                                                            inputComponent={NumericFormatCustom}
                                                            />
                                                        <FormHelperText>{!!number3Error ? 
                                                            (number3Error) : ('')}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <FormControl variant="standard" fullWidth error={!!email3Error}>
                                                        <InputLabel shrink htmlFor={'email3Input'} sx={{fontSize:'1.2rem'}} >
                                                            Email
                                                        </InputLabel>
                                                        <KraneInput id={'email3Input'} value={email3}
                                                            onChange={e => setEmail3(e.target.value)}
                                                            />
                                                        <FormHelperText>{!!email3Error ? 
                                                            (email3Error) : ('')}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <FormControl variant="standard" fullWidth error={!!bvn3Error}>
                                                        <InputLabel shrink htmlFor={'bvn3Input'} sx={{fontSize:'1.2rem'}} >
                                                            BVN
                                                        </InputLabel>
                                                        <KraneInput id={'bvn3Input'} value={bvn3}
                                                            onChange={e => setBVN3(e.target.value)}
                                                            inputComponent={NumericFormatCustom}
                                                            />
                                                        <FormHelperText>{!!bvn3Error ? 
                                                            (bvn3Error) : ('')}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <FormControl variant="standard" fullWidth error={!!nin3Error}>
                                                        <InputLabel shrink htmlFor={'nin3Input'} sx={{fontSize:'1.2rem'}} >
                                                            NIN
                                                        </InputLabel>
                                                        <KraneInput id={'nin3Input'} value={nin3}
                                                            onChange={e => setNIN3(e.target.value)}
                                                            inputComponent={NumericFormatCustom}
                                                            />
                                                        <FormHelperText>{!!nin3Error ? 
                                                            (nin3Error) : ('')}</FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>                                            
                                            <Typography color={'#6c757d'} variant="subtitle1" fontWeight={500} mt={2} mb={1} lineHeight={1.2}>
                                                We would like to request for your bank statement 
                                            </Typography>
                                            <Grid container mt={0.5}>
                                                <Grid item>
                                                    <FormControlLabel control={<Checkbox />} 
                                                        checked={selected} onChange={() => {
                                                            setSelected(!selected);
                                                        }}
                                                        label="I hereby agree to the terms and conditions of adding my cooperative's account to the Krane Platform" />
                                                </Grid>
                                            </Grid>
                                            <Grid container mt={8} spacing={3}>
                                                <Grid item xs={12} sm={12} md={6}>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <Button sx= {{height: {xs: '70px', sm: '50px'}}} onClick={handleNext} className="w-100 text-capitalize" variant="contained">Submit</Button>
                                                </Grid>
                                            </Grid>
                                            </>
                                        ) : (
                                            <React.Fragment>
                                                {activeStep === 1 ? (
                                                    <>
                                                    <Box mt={5} className="form-text">
                                                        <Typography variant="h4" color="#000" fontWeight={500} lineHeight={1}>
                                                            Bank <br /> information
                                                        </Typography>
                                                        <Typography variant="subtitle1" fontWeight={500} mt={1} lineHeight={1.2}>
                                                            Kindly make sure to provide the details of your most active bank account
                                                        </Typography>
                                                    </Box>
                                                    <Grid container mt={2} spacing={3}>
                                                        <Grid item xs={12} sm={12} md={6}>
                                                            <FormControl variant="standard" fullWidth error={!!bankNameError}>
                                                                <InputLabel shrink htmlFor={'bankNameInput'} sx={{fontSize:'1.2rem'}} >
                                                                    Bank Name
                                                                </InputLabel>
                                                                <Select id={'bankNameInput'} value={bankName}
                                                                    onChange={e => setBankName(e.target.value)}
                                                                    input={<KraneInput />}
                                                                    >
                                                                    {getBanks()}
                                                                </Select>
                                                                <FormHelperText>{!!bankNameError ? 
                                                                    (bankNameError) : ('')}</FormHelperText>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6}>
                                                            <FormControl variant="standard" fullWidth error={!!bvnError}>
                                                                <InputLabel shrink htmlFor={'bvnInput'} sx={{fontSize:'1.2rem'}} >
                                                                    BVN
                                                                </InputLabel>
                                                                <KraneInput id={'bvnInput'} value={bvn}
                                                                    onChange={e => setBVN(e.target.value)}
                                                                    inputComponent={NumericFormatCustom}
                                                                    />
                                                                <FormHelperText>{!!bvnError ? 
                                                                    (bvnError) : ('')}</FormHelperText>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6}>
                                                            <FormControl variant="standard" fullWidth error={!!accountNumberError}>
                                                                <InputLabel shrink htmlFor={'accountNumberInput'} sx={{fontSize:'1.2rem'}} >
                                                                    Account Number
                                                                </InputLabel>
                                                                <KraneInput id={'accountNumberInput'} value={accountNumber}
                                                                    onChange={e => setAccountNumber(e.target.value)}
                                                                    inputComponent={NumericFormatCustom}
                                                                    />
                                                                <FormHelperText>{!!accountNumberError ? 
                                                                    (accountNumberError) : ('')}</FormHelperText>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={6}>
                                                            <FormControl variant="standard" fullWidth error={!!accountNameError}>
                                                                <InputLabel shrink htmlFor={'accountNameInput'} sx={{fontSize:'1.2rem'}} >
                                                                    Account Name
                                                                </InputLabel>
                                                                <KraneInput id={'accountNameInput'} value={accountName}
                                                                    onChange={e => setAccountName(e.target.value)} 
                                                                    />
                                                                <FormHelperText>{!!accountNameError ? 
                                                                    (accountNameError) : ('')}</FormHelperText>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                    <Typography color={'#6c757d'} variant="subtitle1" fontWeight={500} mt={2} mb={1} lineHeight={1.2}>
                                                        We would like to request for your bank statement 
                                                    </Typography>
                                                    <Grid container mt={0.5}>
                                                        <Grid item>
                                                            <FormControlLabel control={<Checkbox />} 
                                                                checked={selected} onChange={() => {
                                                                    setSelected(!selected);
                                                                }}
                                                                label="I hereby agree to the terms and conditions of adding my cooperative's account to the Krane Platform" />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container mt={8} spacing={3}>
                                                            <Grid item xs={12} sm={6} md={6}>
                                                                <Button sx= {{height: {xs: '70px', sm: '50px'}}} onClick={handleBack} className="w-100 text-capitalize" variant="text">Go back</Button>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={6}>
                                                                <Button sx= {{height: {xs: '70px', sm: '50px'}}} onClick={handleNext} className="w-100 text-capitalize" variant="contained">Submit</Button>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Box mt={5} className="form-text">
                                                            <Typography variant="h4" color="#000" fontWeight={500} lineHeight={1}>
                                                                Cooperative Documentations
                                                            </Typography>
                                                            <Typography variant="subtitle1" fontWeight={500} mt={1} lineHeight={1.2}>
                                                                Kindly make sure to provide the details of your most active bank account
                                                            </Typography>
                                                        </Box>
                                                        <Grid container mt={2} spacing={3}>
                                                            <Grid item xs={12} sm={12} md={6}>
                                                                <FormControl variant="standard" fullWidth error={!!addressError}>
                                                                    <InputLabel shrink htmlFor={'addressInput'} sx={{fontSize:'1.2rem'}} >
                                                                        Head Office Address
                                                                    </InputLabel>
                                                                    <KraneInput id={'addressInput'} value={address}
                                                                        onChange={e => setAddress(e.target.value)}
                                                                        />
                                                                    <FormHelperText>{!!addressError ? 
                                                                        (addressError) : ('')}</FormHelperText>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6}>
                                                                    <InputLabel htmlFor={'resolutionInput'} shrink sx={{fontSize:'1.2rem'}} >
                                                                        Board of Directors Resolution
                                                                    </InputLabel>
                                                                    <UploadButton id={'resolutionButton'}
                                                                        htmlFor={'resolutionInput'} 
                                                                        error={!!resolutionError} fullWidth
                                                                        onClick={openResolutionDialog}>
                                                                            <img src={Upload} alt="upload icon" 
                                                                            className="me-2"/> {resolution ? resolution.name :'Upload'}
                                                                        </UploadButton>
                                                                    <KraneInput onChange={e => {setResolution(e.target.files[0])}} hidden id={'resolutionInput'} type="file"
                                                                        />
                                                                    <FormHelperText>{!!resolutionError ? 
                                                                        (resolutionError) : ('')}</FormHelperText>          
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6}>
                                                                    <InputLabel htmlFor={'cacInput'} shrink sx={{fontSize:'1.2rem'}} >
                                                                        Cooperative CAC
                                                                    </InputLabel>
                                                                    <UploadButton id={'cacButton'}
                                                                        htmlFor={'cacInput'}
                                                                        fullWidth error={!!cacError}
                                                                        onClick={openCACDialog}>
                                                                            <img src={Upload} alt="upload icon" 
                                                                            className="me-2"/> {cac ? cac.name :'Upload'}
                                                                        </UploadButton>
                                                                    <KraneInput onChange={e => {setCAC(e.target.files[0])}} hidden id={'cacInput'} type="file"
                                                                        />
                                                                    <FormHelperText>{!!cacError ? 
                                                                        (cacError) : ('')}</FormHelperText>                         
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6}>
                                                                    <InputLabel htmlFor={'tinInput'} shrink sx={{fontSize:'1.2rem'}} >
                                                                        Cooperative TIN
                                                                    </InputLabel>
                                                                    <UploadButton id={'tinButton'}
                                                                        htmlFor={'tinInput'} 
                                                                        fullWidth error={!!tinError}
                                                                        onClick={openTINDialog}>
                                                                            <img src={Upload} alt="upload icon" 
                                                                            className="me-2"/> {tin ? tin.name :'Upload'}
                                                                        </UploadButton>
                                                                    <KraneInput onChange={e => {setTIN(e.target.files[0])}} hidden id={'tinInput'} type="file"
                                                                        />
                                                                    <FormHelperText>{!!tinError ? 
                                                                        (tinError) : ('')}</FormHelperText>                         
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6}>
                                                                    <InputLabel htmlFor={'moaInput'} shrink sx={{fontSize:'1.2rem'}} >
                                                                        Memorandum of Association
                                                                    </InputLabel>
                                                                    <UploadButton id={'moaButton'}
                                                                        htmlFor={'moaInput'} 
                                                                        fullWidth error={!!moaError}
                                                                        onClick={openMOADialog}>
                                                                            <img src={Upload} alt="upload icon" 
                                                                            className="me-2"/> {moa ? moa.name :'Upload'}
                                                                        </UploadButton>
                                                                    <KraneInput onChange={e => {setMOA(e.target.files[0])}} hidden id={'moaInput'} type="file"
                                                                        />
                                                                    <FormHelperText>{!!moaError ? 
                                                                        (moaError) : ('')}</FormHelperText>                               
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6}>
                                                                    <InputLabel htmlFor={'mouInput'} shrink sx={{fontSize:'1.2rem'}} >
                                                                        Signed MoU
                                                                    </InputLabel>
                                                                    <UploadButton id={'mouButton'}
                                                                        htmlFor={'mouInput'} 
                                                                        fullWidth error={!!mouError}
                                                                        onClick={openMOUDialog}>
                                                                            <img src={Upload} alt="upload icon" 
                                                                            className="me-2"/> {mou ? mou.name :'Upload'}
                                                                        </UploadButton>
                                                                    <KraneInput onChange={e => {setMOU(e.target.files[0])}} hidden id={'mouInput'} type="file"
                                                                        />
                                                                    <FormHelperText>{!!mouError ? 
                                                                        (mouError) : ('')}</FormHelperText>                      
                                                            </Grid>
                                                        </Grid>
                                                        <Typography color={'#6c757d'} variant="subtitle1" fontWeight={500} mt={2} mb={1} lineHeight={1.2}>
                                                            We would like to request for your bank statement 
                                                        </Typography>
                                                        <Grid container mt={0.5}>
                                                            <Grid item>
                                                                <FormControlLabel control={<Checkbox />} 
                                                                    checked={selected} onChange={() => {
                                                                        setSelected(!selected);
                                                                    }}
                                                                    label="I hereby agree to the terms and conditions of adding my cooperative's account to the Krane Platform" />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container mt={8} spacing={3}>
                                                            <Grid item xs={12} sm={6} md={6}>
                                                                <Button sx= {{height: {xs: '70px', sm: '50px'}}} onClick={handleBack} className="w-100 text-capitalize" variant="text">Go back</Button>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={6}>
                                                                <Button sx= {{height: {xs: '70px', sm: '50px'}}} onClick={handleNext} className="w-100 text-capitalize" variant="contained">Done</Button>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </Box>
                            </Box>
                    </Grid>
                    <Side />
                </Grid>            
            </Scrollbar>
        </Box>
    )
}

export default CoopInformation;