import { Box, Grid, Typography } from "@mui/material"
import plantmoney from "../assets/images/plantmoney.svg";
import logo from "../assets/images/krane-logo.png"

const Side = () => {

    return (
        
        <Grid item xs={0} sm={4} className="sideCar w-100">
            <Box sx={{ backgroundColor: 'primary.main' }} pb={10} pt={5} className="w-100 vh-100 car-container d-flex flex-column align-items-center">
                <Box className='side-text-container' color={'#fff'} px={10}>
                    <img src={logo} alt="Krane logo" width={'30%'} height={'auto'}/>
                    <Typography className="side-text-1" fontSize={'3rem'} fontWeight={700} lineHeight={'100%'} mt={5}>
                        Drive value within your communities
                    </Typography>
                    <Typography className="side-text-2" fontWeight={500} fontSize={'0.8rem'} mt={2} mr={12}>
                        Cooperatives are known for driving value and creating prosperity. Krane is here for that.
                    </Typography>
                </Box>
                <img src={plantmoney} alt="krane plant money" width="auto" height="45%" className="position-absolute b-5"/>
            </Box>
    </Grid>
    )
}

export default Side;