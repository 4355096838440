import React, { useState } from "react";
import axios from "axios";
import toast from 'react-hot-toast'
import Scrollbar from 'perfect-scrollbar-react';

import { Box, Grid, Typography, 
    Link, Button, FormControl, 
    InputAdornment, InputLabel,
    IconButton, CircularProgress,
    FormHelperText} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { KraneInput } from '../components/inputs';
import Side from "../components/ind-sidecar";
import TopNavBar from "../components/navs/topNav";
import logoDark from "../assets/images/logo2.png";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleLogin = async () => {
        setLoading(true);
        setEmailError("");
        setPasswordError("");

        const userData = {
            email: email,
            password: password
        }

        try {
            await axios
            .post(`https://krane-staging.herokuapp.com/auth/login`, userData)
            .then((res) => {
              setLoading(false);
              toast.success("Login successful");
            });
        } catch (error) {
            let errorData = (error.response.data.message);
            console.log(errorData);
        }
    }

    return (
        <Box>
            <Scrollbar>
                <Grid container spacing={0} className="container-full vh-100">
                {/* <TopNavBar /> */}
                    <Grid item xs={12} sm={8} className="form-outer">
                            <Box component="form" noValidate autoComplete="off" 
                                pr={6} pl={25} py={5} className="form-container">
                                <Typography sx={{ display: {xs: 'none', sm: 'block'} }} className="text-end" variant="subtitle2" fontSize="1rem">
                                    Don't have an account?.
                                    <Link href='/account/individual/registration' underline="none">Create an account</Link>
                                </Typography>
                                <Box mt={{xs: 2}} sx={{ display: {xs: 'block', sm: 'none'} }}>
                                    <img src={logoDark} alt="Krane logo dark" width='40%' height={'auto'}/>
                                </Box>
                                <Box mt={{xs: 7, sm: 10}} height={"auto"} className="form-text">
                                    <Typography variant="h4" color="primary" fontWeight={500}>
                                        Log into your account
                                    </Typography>
                                    <Typography variant="subtitle1" fontWeight={500} mt={1}>
                                        Kindly input the correct credentials to access your account.
                                    </Typography>
                                </Box>
                                <Grid container mt={2} spacing={3}>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <FormControl variant="standard" fullWidth error={!!emailError}>
                                            <InputLabel shrink htmlFor={'emailInput'} sx={{fontSize:'1.2rem'}} >
                                                Email
                                            </InputLabel>
                                            <KraneInput id={'emailInput'} value={email}
                                                onChange={e => setEmail(e.target.value)}
                                                type="email"
                                                helperText={!!emailError ? 
                                                (emailError) : ('')}
                                                inputProps={{
                                                    autoComplete: 'new-password',
                                                }}/>
                                            <FormHelperText className="Mui-error">
                                                {!!passwordError ? (passwordError) : ('')}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container mt={0.1} spacing={3}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl variant="standard" fullWidth error={!!passwordError}>
                                        <InputLabel shrink htmlFor='password1Input' sx={{fontSize:'1.2rem'}} >
                                            Password
                                        </InputLabel>
                                        <KraneInput id='password1Input' 
                                            type={showPassword ? 'text' : 'password'}
                                            value={password}
                                            onChange={e => setPassword(e.target.value)} 
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end">
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>}                                            
                                            inputProps={{
                                                autoComplete: 'new-password',
                                            }}
                                            />
                                            <FormHelperText className="Mui-error">{!!passwordError ? (passwordError) : ('')}</FormHelperText>
                                    </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container mt={4} spacing={3}>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Button sx= {{height: {xs: '70px', sm: '50px'}}} className="w-100 text-capitalize" variant="contained" 
                                            disabled={loading} onClick={handleLogin}>
                                            {loading ? (
                                                <CircularProgress size={20} />
                                                ) : (
                                                'Submit'
                                            )}
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Typography sx={{ display: {xs: 'block', sm: 'none'} }} className="text-center mt-3" variant="subtitle2" fontSize="0.9rem">
                                    Don't have an account?.
                                    <Link href='/account/individual/registration' underline="none">Create an account</Link>
                                </Typography>
                            </Box>
                    </Grid>
                    <Side />
                </Grid>            
            </Scrollbar>
        </Box>
    )
}

export default Login;