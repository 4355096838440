import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { Tracker } from "@tremor/react";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: theme.palette.error.light,
    },
  }));


export default function LoanProgress() {
    return (
        <Grid
          container
          spacing={{ xs: 2, xl: 4 }}
          pt={2}
          sx={{ paddingRight: { xs: 4, md: 5, lg: 5 } }}
        >
          <Grid item xs={12} sm={10} md={8} xl={4} sx={{ height: "100%" }}>
            <Paper
              square
              elevation={0}
              sx={{
                minHeight: {
                  xs: "15rem",
                  md: "18rem",
                  lg: "20rem",
                  xl: "20rem",
                },
                paddingX: { xs: 2, sm: 3, lg: 5 },
                paddingY: 3,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography color={"#858585"} mt={2}>
                    Amount Paid <span style={{ color: "yellow-500" }}>₦</span>
                  </Typography>
                  <Typography
                    color={"primary"}
                    fontFamily={"'Aventa', sans-serif"}
                    fontWeight={700}
                    fontSize={{
                      xs: "1rem",
                      md: "1.5rem",
                      lg: "2.5rem",
                      xl: "3rem",
                    }}
                  >
                    500,000
                  </Typography>
                </Box>
                <Divider
                  flexItem
                  orientation="vertical"
                  sx={{ borderRight: "3px solid #C4C4C4" }}
                />
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography color={"#858585"} mt={2}>
                    Amount Left <span style={{ color: "yellow-500" }}>₦</span>
                  </Typography>
                  <Typography
                    color={"primary"}
                    fontFamily={"'Aventa', sans-serif"}
                    fontWeight={700}
                    fontSize={{
                      xs: "1rem",
                      md: "1.5rem",
                      lg: "2.5rem",
                      xl: "3rem",
                    }}
                  >
                    2,500,000
                  </Typography>
                </Box>
              </Box>
              <Box>
                <div className="w-full mt-4">
                  <Tracker
                    className="*:!rounded-0 *:!rounded-l-0 gap-[0.25rem] sm:gap-[0.5rem] lg:gap-[0.75rem]"
                    data={[
                      { color: "red", tooltip: "Tracker Info" },
                      { color: "red", tooltip: "Tracker Info" },
                      { color: "red", tooltip: "Tracker Info" },
                      { color: "red", tooltip: "Tracker Info" },
                      { color: "red", tooltip: "Tracker Info" },
                      { color: "yellow-500", tooltip: "Tracker Info" },
                      { color: "yellow-500", tooltip: "Tracker Info" },
                      { color: "yellow-500", tooltip: "Tracker Info" },
                      { color: "yellow-500", tooltip: "Tracker Info" },
                      { color: "yellow-500", tooltip: "Tracker Info" },
                      { color: "yellow-500", tooltip: "Tracker Info" },
                      { color: "green", tooltip: "Tracker Info" },
                      { color: "green", tooltip: "Tracker Info" },
                      { color: "green", tooltip: "Tracker Info" },
                      { color: "green", tooltip: "Tracker Info" },
                      { color: "green", tooltip: "Tracker Info" },
                      { color: "green", tooltip: "Tracker Info" },
                      { color: "green", tooltip: "Tracker Info" },
                      { color: "tremor-brand", tooltip: "Tracker Info" },
                      { color: "tremor-brand", tooltip: "Tracker Info" },
                      { color: "tremor-brand", tooltip: "Tracker Info" },
                      { color: "tremor-brand", tooltip: "Tracker Info" },
                      { color: "tremor-brand", tooltip: "Tracker Info" },
                      { color: "tremor-brand", tooltip: "Tracker Info" },
                    ]}
                  />
                </div>
                <Box className="w-full">
                  <BorderLinearProgress
                    value={20}
                    variant="determinate"
                    className="mb-2 mt-1 rounded-0"
                  />
                  <Typography fontFamily={"'Outfit', sans-serif"}>
                    20% Paid
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
    )
}