import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import toast from 'react-hot-toast'
import Scrollbar from 'perfect-scrollbar-react';
import NGBanks from 'ng-banks';
import { NumericFormat } from 'react-number-format';
import { PatternFormat } from 'react-number-format';
import { IMaskInput } from 'react-imask';

import { Box, Grid, Typography, 
    Button, FormControl, InputLabel, 
    ToggleButton, Select, MenuItem, Divider, FormHelperText, FormControlLabel, Checkbox, Card, ListItemText, Paper, ListItem } from '@mui/material';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Side from "../../../components/ind-sidecar";
import CheckIcon from '@mui/icons-material/Check';
import GppGoodOutlined from '@mui/icons-material/GppGoodOutlined'

import { KraneInput } from '../../../components/inputs';
import { KraneConnector, KraneStepIcon } from "../../../components/stepper";
import TopNavBar from "../../../components/navs/topNav";
import Upload from '../../../assets/images/upload.svg';
import { UploadButton } from "../../../components/buttons";
import noImage from "../../../assets/images/no-image.svg";
import { ReactComponent as SideCrane } from "../../../assets/images/crane.svg";
import { ReactComponent as Logo } from "../../../assets/images/logo.svg";
import Guarantors from "./guarantorsInformation";
import Finance from "./financeInformation";
import LoanSummary from "./loanSummary";
import CarDealerInfo from "./carDealerInformation";



const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
    props,
    ref,
  ) {
    const { onChange, ...other } = props;
  
    return (
      <PatternFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        format="###########"
      />
    );
  });
  
  NumericFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };
  
const CardNumericFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref,
) {
  const { onChange, ...other } = props;

  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="###################"
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};


const CardExpiryNumericFormatCustom = React.forwardRef(function NumericFormatCustom(
    props,
    ref,
  ) {
    const { onChange, ...other } = props;
  
    return (
      <PatternFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        format="##/##"
      />
    );
  });
  
  NumericFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  const CardCVVNumericFormatCustom = React.forwardRef(function NumericFormatCustom(
    props,
    ref,
  ) {
    const { onChange, ...other } = props;
  
    return (
      <PatternFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        format="###"
      />
    );
  });
  
  NumericFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };



const CreditApplication = () => {

    const steps = ['', '', '', ''];

    const [guarantorsData, setGuarantorsData] = useState({
        name1: "",
        phone1: "",
        email1: "",
        profession1: "",
        statement1: null,

        name2: "",
        phone2: "",
        email2: "",
        profession2: "",
        statement2: null
    })

    const [financeData, setFinanceData] = useState({
        image: null,
        profile: "",
        downPayment: "",
        downPaymentAmount: "",
        maxOffer: "",
        amount: "",
        tenor: "",
    })

    const [loanData, setLoanData] = useState({
        FOD: "",
        DOD: ""
    })

    const [carDealerData, setCarDealerData] = useState({
      selection: "krane",
      carPhotos: null,
      carName: "",
      transmission: "",
      model: "",
      engineType: "",
      mileage: "",
      year: "",
      color: "",
      carType: "",

      ownerName: "",
      ownerNumber: "",
      NINPassport: null,
      CoO: null,
      bankName: "",
      accountNumber: "",
      accountName: ""
    })

    const [guarantorsErrorData, setGuarantorsErrorData] = useState({
        name1Error: "",
        phone1Error: "",
        email1Error: "",
        profession1Error: "",
        statement1Error: "",

        name2Error: "",
        phone2Error: "",
        email2Error: "",
        profession2Error: "",
        statement2Error: ""
    })

    const [financeErrorData, setFinanceErrorData] = useState({
        imageError: null,
        profileError: "",
        downPaymentError: "",
        downPaymentAmountError: "",
        maxOfferError: "",
        amountError: "",
        tenorError: "",
    })

    const [loanErrorData, setLoanErrorData] = useState({
        FODError: "",
        DODError: ""
    })

    const [carDealerErrorData, setCarDealerErrorData] = useState({
      selectionError: "",
      carPhotosError: "",
      carNameError: "",
      transmissionError: "",
      modelError: "",
      engineTypeError: "",
      mileageError: "",
      yearError: "",
      colorError: "",
      carTypeError: "",

      ownerNameError: "",
      ownerNumberError: "",
      NINPassportError: null,
      CoOError: null,
      bankNameError: "",
      accountNumberError: "",
      accountNameError: ""
    })


    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());

    const isStep = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const guarantorInputChange = (e) => {
        setGuarantorsData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const guarantorFileChange = (e) => {
        setGuarantorsData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.files[0]
        }))
    }

    const financeInputChange = (e) => {
        setFinanceData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const financeFileChange = (e) => {
        setFinanceData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.files[0]
        }))
    }

    const loanInputChange = (e) => {
        setLoanData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const carDealerInputChange = (e) => {
        setCarDealerData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    }

    const carDealerFileChange = (e) => {
        setCarDealerData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.files[0]
        }))
    }

    return (
        <Box>
            <Scrollbar>
                <Grid container spacing={0} className="container-full vh-100">
                {/* <TopNavBar /> */}
                    <Grid item xs={12} sm={8} className="form-outer">
                            <Box component="form" noValidate autoComplete="off" 
                                pr={6} pl={25} py={5} className="form-container">
                                    <Stack sx={{ width: '30%', mt: {xs: 0, md: 2, lg: 10} }} spacing={0}>
                                        <Stepper activeStep={activeStep} connector={<KraneConnector />}>
                                            {steps.map((label) => (
                                            <Step key={label}>
                                                <StepLabel StepIconComponent={KraneStepIcon}>{label}</StepLabel>
                                            </Step>
                                            ))}
                                        </Stepper>
                                    </Stack>
                                    <Box>
                                        {activeStep === 0 ? (
                                            <>
                                            <Guarantors guarantorData={guarantorsData} guarantorErrors={guarantorsErrorData} handleChange={guarantorInputChange} handleFiles={guarantorFileChange} />
                                            <Grid container mt={8} spacing={3}>
                                                <Grid item xs={22} sm={22} md={6}>
                                                </Grid>
                                                <Grid item xs={22} sm={22} md={6}>
                                                    <Button fullWidth sx= {{height: {xs: '50px', sm: '70px'}}} onClick={handleNext} className="w-100 text-capitalize" variant="contained">Submit</Button>
                                                </Grid>
                                            </Grid>
                                            </>
                                        ) : (
                                            <>
                                                {activeStep === 1 ? (
                                                    <>
                                                    <Finance financeData={financeData} financeErrors={financeErrorData} handleChange={financeInputChange} handleFiles={financeFileChange} />
                                                    <Grid container mt={8} spacing={3}>
                                                            <Grid item xs={12} sm={6} md={6}>
                                                                <Button sx= {{height: {xs: '50px', sm: '70px'}}} onClick={handleBack} className="w-100 text-capitalize" variant="text">Go back</Button>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6} md={6}>
                                                                <Button sx= {{height: {xs: '50px', sm: '70px'}}} onClick={handleNext} className="w-100 text-capitalize" variant="contained">Submit</Button>
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                ) : (
                                                    <>
                                                        {activeStep === 2 ? (
                                                            <>
                                                                <LoanSummary loanData={loanData} loanErrors={loanErrorData} handleChange={loanInputChange} />
                                                                <Grid container mt={8} spacing={3}>
                                                                    <Grid item xs={12} sm={6} md={6}>
                                                                        <Button sx= {{height: {xs: '50px', sm: '70px'}}} onClick={handleBack} className="w-100 text-capitalize" variant="text">Go back</Button>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6} md={6}>
                                                                        <Button sx= {{height: {xs: '50px', sm: '70px'}}} onClick={handleNext} className="w-100 text-capitalize" variant="contained">Next</Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <CarDealerInfo carDealerData={carDealerData} carDealerErrors={carDealerErrorData} handleChange={carDealerInputChange} handleFiles={carDealerFileChange} />
                                                                <Grid container mt={8} spacing={3}>
                                                                    <Grid item xs={12} sm={6} md={6}>
                                                                        <Button sx= {{height: {xs: '50px', sm: '70px'}}} onClick={handleBack} className="w-100 text-capitalize" variant="text">Go back</Button>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6} md={6}>
                                                                        <Button sx= {{height: {xs: '50px', sm: '70px'}}} onClick={handleNext} className="w-100 text-capitalize" variant="contained">Next</Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </Box>
                            </Box>
                    </Grid>
                    <Grid item xs={0} sm={4} sx={{position: "fixed", right: 0, top: 0, width: "100%", display: {xs: "none", sm: "block"}}}>
                        <Box sx={{ bgcolor: 'primary.main' }} pb={10} pt={5} className="w-100 vh-100 d-flex flex-column align-items-center">
                            <Box sx={{px: {xs: 2, md: 5, lg: 10}, width: "100%"}} color={'#fff'}>
                                <Box sx={{mt: {xs: 2, md: 5} }}>
                                    <Logo />
                                </Box>
                                <Typography sx={{mt: {sm: 5, lg: 10}, fontSize: {md: "4rem", lg: "4rem", xl: "5rem"}, 
                                                width: {xs: "90%", lg: "80%", xl: "70%"}, fontWeight: 700, lineHeight: "100%"}}>
                                    We are krane
                                </Typography>
                                <Typography sx={{fontSize: {xs: "0.8rem", lg: "1rem", xl: "1.25rem"}, 
                                                width: {xs: "90%", lg: "75%", xl: "60%"}, 
                                                fontWeight: 500, mt: 2 }}>
                                    The best growth partner you could ever wish for
                                </Typography>
                            </Box>
                            <Box sx={{height: "60%", mt: {xs: 2, lg: 5, xl: 7}}}>
                                <SideCrane className="h-100 w-100" />
                            </Box>
                        </Box>
                </Grid>
                </Grid>            
            </Scrollbar>
        </Box>
    )
}

export default CreditApplication;