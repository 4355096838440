import React, { useState } from "react";
import PropTypes from "prop-types";
import { PatternFormat, NumericFormat } from "react-number-format";

import {
  Typography,
  Box,
  Grid,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  Button,
  Radio,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  MenuItem,
  Divider,
  ImageList,
  ImageListItem,
  Skeleton,
  ImageListItemBar,
  Pagination,
  ListItemButton,
  Paper,
  List,
  ListSubheader,
  ListItemText,
  Menu,
  Avatar,
  ListItemIcon,
  Collapse,
  Checkbox,
  Modal,
  Link,
  Breadcrumbs,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  ArrowForward,
  ArrowRight,
  ArrowRightAlt,
  ArrowForwardIos,
  KeyboardArrowRight,
  CheckOutlined,
  CheckCircleOutline,
} from "@mui/icons-material";
// import ExpandMore from "@mui/icons-material/ExpandMore";

import { KraneInput } from "../../../components/inputs";
import { UploadButton } from "../../../components/buttons";
import Upload from "../../../assets/images/upload.svg";
import noImage from "../../../assets/images/no-image.svg";
import toyotaLogo from "../../../assets/images/toyota.svg";
import hondaLogo from "../../../assets/images/honda.svg";
import carFront from "../../../assets/images/car-front.png";
import carFrontside from "../../../assets/images/car-frontside.png";
import carBackside from "../../../assets/images/car-backside.png";
import carInterior from "../../../assets/images/car-interior.png";
import { ReactComponent as SearchIcon } from "../../../assets/images/search.svg";
import { ReactComponent as ToyotaIcon } from "../../../assets/images/toyota.svg";
import { ReactComponent as HondaIcon } from "../../../assets/images/honda.svg";
import { ReactComponent as NissanIcon } from "../../../assets/images/nissan.svg";

const MileageFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      suffix=" km"
    />
  );
});

MileageFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const YearFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <PatternFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format="####"
      min={"1999"}
      max={"2023"}
    />
  );
});

YearFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const CarDealerInfo = ({
  carDealerData,
  carSearch,
  carDealerErrors,
  handleChange,
  handleFiles,
}) => {
  function openImageDialog(e) {
    e.preventDefault();
    document.getElementById("imageInput").click();
  }

  function openNINPassporDialog() {
    document.getElementById("NINPassportInput").click();
  }

  function openCoODialog() {
    document.getElementById("CoOInput").click();
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openToyota, setOpenToyota] = React.useState(true);

  const handleToyotaClick = () => {
    setOpenToyota(!openToyota);
  };

  const [openCar, setOpenCar] = useState(false);

  const handleCarClose = () => setOpenCar(false);

  const openCarInfo = (e) => {
    e.preventDefault();
    setOpenCar(true);
  };

  return (
    <>
      <Box mt={5} className="form-text">
        <Typography variant="h4" color="#000" fontWeight={500} lineHeight={1}>
          Car & dealer Info
        </Typography>
        <Typography
          variant="subtitle1"
          fontWeight={500}
          mt={1}
          lineHeight={1.2}
        >
          Great job, lets know the kind of car you want to get
        </Typography>
      </Box>
      <Grid container mt={5} spacing={3}>
        <Grid item xs={12} sm={12} md={6}>
          <FormControl>
            <FormLabel id="car-selections">
              I want to explore options from?
            </FormLabel>
            <RadioGroup
              aria-labelledby="car-selections-radio-buttons-group"
              name="selection"
              value={carDealerData.selection}
              onChange={handleChange}
            >
              <FormControlLabel
                value="krane"
                control={<Radio />}
                label="Krane selection"
              />
              <FormControlLabel
                value="personal"
                control={<Radio />}
                label="Personal preference"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {carDealerData.selection === "personal" ? (
          <>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{ display: "flex", flexDirection: "row", alignItems: "end" }}
            >
              <Box>
                <InputLabel
                  htmlFor={"bvnImageInput"}
                  shrink
                  sx={{ fontSize: "1.2rem" }}
                >
                  Car photos
                </InputLabel>
                <Box
                  component={"button"}
                  onClick={(e) => openImageDialog(e)}
                  bgcolor={"#5806C3"}
                  border={0}
                  width={"8rem"}
                  height={"8rem"}
                  error={!!carDealerErrors.carPhotoError}
                  htmlFor={"imageInput"}
                >
                  <img src={noImage} alt="upload icon" className="" />
                </Box>
                <KraneInput
                  onChange={handleFiles}
                  hidden
                  name="carPhotos"
                  id={"imageInput"}
                  type="file"
                  accept="image/*"
                />
                <FormHelperText>
                  {!!carDealerErrors.carPhotoError
                    ? carDealerErrors.carPhotoError
                    : ""}
                </FormHelperText>
              </Box>
              <Box>
                <Box
                  component={"button"}
                  htmlFor={"imageInput"}
                  bgcolor={"rgba(88, 6, 195, 0.05)"}
                  ml={2}
                  border={0}
                  py={2}
                  px={4}
                  sx={{
                    textTransform: "none",
                    color: "#000000",
                    height: "30%",
                  }}
                >
                  upload images
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl
                variant="standard"
                fullWidth
                error={!!carDealerErrors.carNameError}
              >
                <InputLabel
                  shrink
                  htmlFor={"carNameInput"}
                  sx={{ fontSize: "1.2rem" }}
                >
                  Car Name
                </InputLabel>
                <KraneInput
                  id={"carNameInput"}
                  value={carDealerData.carName}
                  name="carName"
                  onChange={handleChange}
                />
                <FormHelperText>
                  {!!carDealerErrors.carNameError
                    ? carDealerErrors.carNameError
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl
                variant="standard"
                fullWidth
                error={!!carDealerErrors.transmissionError}
              >
                <InputLabel
                  shrink
                  htmlFor={"transmissionInput"}
                  sx={{ fontSize: "1.2rem" }}
                >
                  Transmission
                </InputLabel>
                <Select
                  id={"transmissionInput"}
                  value={carDealerData.transmission}
                  onChange={handleChange}
                  input={<KraneInput />}
                  name="transmission"
                  placeholder=""
                >
                  <MenuItem value={"Manual"} name={"manual"}>
                    Manual Transmission
                  </MenuItem>
                  <MenuItem value={"Automatic"} name={"automatic"}>
                    Automatic Transmission
                  </MenuItem>
                </Select>
                <FormHelperText>
                  {!!carDealerErrors.transmissionError
                    ? carDealerErrors.transmissionError
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl
                variant="standard"
                fullWidth
                error={!!carDealerErrors.modelError}
              >
                <InputLabel
                  shrink
                  htmlFor={"modelInput"}
                  sx={{ fontSize: "1.2rem" }}
                >
                  Model
                </InputLabel>
                <KraneInput
                  id={"modelInput"}
                  value={carDealerData.model}
                  name="model"
                  onChange={handleChange}
                />
                <FormHelperText>
                  {!!carDealerErrors.modelError
                    ? carDealerErrors.modelError
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl
                variant="standard"
                fullWidth
                error={!!carDealerErrors.engineType}
              >
                <InputLabel
                  shrink
                  htmlFor={"engineTypeInput"}
                  sx={{ fontSize: "1.2rem" }}
                >
                  Engine Type
                </InputLabel>
                <Select
                  id={"engineTypeInput"}
                  value={carDealerData.engineType}
                  onChange={handleChange}
                  input={<KraneInput />}
                  name="engineType"
                >
                  <MenuItem value={"Combustion"} name={"combustion"}>
                    Internal Combustion Engine
                  </MenuItem>
                  <MenuItem value={"Electric"} name={"electric"}>
                    Electric Engine
                  </MenuItem>
                  <MenuItem value={"Hybrid"} name={"hybrid"}>
                    Hybrid Engine
                  </MenuItem>
                </Select>
                <FormHelperText>
                  {!!carDealerErrors.engineType
                    ? carDealerErrors.engineType
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl
                variant="standard"
                fullWidth
                error={!!carDealerErrors.mileageError}
              >
                <InputLabel
                  shrink
                  htmlFor={"mileageInput"}
                  sx={{ fontSize: "1.2rem" }}
                >
                  Mileage
                </InputLabel>
                <KraneInput
                  id={"mileageInput"}
                  value={carDealerData.mileage}
                  name="mileage"
                  onChange={handleChange}
                  inputComponent={MileageFormatCustom}
                />
                <FormHelperText>
                  {!!carDealerErrors.mileageError
                    ? carDealerErrors.mileageError
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl
                variant="standard"
                fullWidth
                error={!!carDealerErrors.yearError}
              >
                <InputLabel
                  shrink
                  htmlFor={"yearInput"}
                  sx={{ fontSize: "1.2rem" }}
                >
                  Year
                </InputLabel>
                <KraneInput
                  id={"yearInput"}
                  value={carDealerData.year}
                  name="year"
                  onChange={handleChange}
                  inputComponent={YearFormatCustom}
                />
                <FormHelperText>
                  {!!carDealerErrors.yearError ? carDealerErrors.yearError : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl
                variant="standard"
                fullWidth
                error={!!carDealerErrors.colorError}
              >
                <InputLabel
                  shrink
                  htmlFor={"colorInput"}
                  sx={{ fontSize: "1.2rem" }}
                >
                  Color
                </InputLabel>
                <KraneInput
                  id={"colorInput"}
                  value={carDealerData.color}
                  name="color"
                  onChange={handleChange}
                />
                <FormHelperText>
                  {!!carDealerErrors.colorError
                    ? carDealerErrors.colorError
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl
                variant="standard"
                fullWidth
                error={!!carDealerErrors.carType}
              >
                <InputLabel
                  shrink
                  htmlFor={"carTypeInput"}
                  sx={{ fontSize: "1.2rem" }}
                >
                  Car Type
                </InputLabel>
                <Select
                  id={"carTypeInput"}
                  value={carDealerData.carType}
                  onChange={handleChange}
                  input={<KraneInput />}
                  name="carType"
                >
                  <MenuItem value={"Sedan"} name={"sedan"}>
                    Sedan
                  </MenuItem>
                  <MenuItem value={"SUV"} name={"suv"}>
                    SUV
                  </MenuItem>
                  <MenuItem value={"Sports"} name={"sports"}>
                    Sports
                  </MenuItem>
                  <MenuItem value={"Coupe"} name={"coupe"}>
                    Coupe
                  </MenuItem>
                </Select>
                <FormHelperText>
                  {!!carDealerErrors.carType ? carDealerErrors.carType : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} mt={1}>
              <Divider sx={{ border: "1px solid #C4C4C4" }} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl
                variant="standard"
                fullWidth
                error={!!carDealerErrors.ownerNameError}
              >
                <InputLabel
                  shrink
                  htmlFor={"ownerNameInput"}
                  sx={{ fontSize: "1.2rem" }}
                >
                  Owner's Full Name
                </InputLabel>
                <KraneInput
                  id={"ownerNameInput"}
                  value={carDealerData.ownerName}
                  name="ownerName"
                  onChange={handleChange}
                />
                <FormHelperText>
                  {!!carDealerErrors.ownerNameError
                    ? carDealerErrors.ownerNameError
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl
                variant="standard"
                fullWidth
                error={!!carDealerErrors.ownerNumberError}
              >
                <InputLabel
                  shrink
                  htmlFor={"ownerNumberInput"}
                  sx={{ fontSize: "1.2rem" }}
                >
                  Phone Number
                </InputLabel>
                <KraneInput
                  id={"ownerNumberInput"}
                  value={carDealerData.ownerNumber}
                  name="ownerNumber"
                  onChange={handleChange}
                />
                <FormHelperText>
                  {!!carDealerErrors.ownerNumberError
                    ? carDealerErrors.ownerNumberError
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <InputLabel
                htmlFor={"NINPassportInput"}
                shrink
                sx={{ fontSize: "1.2rem" }}
              >
                NIN/Passport Upload
              </InputLabel>
              <UploadButton
                id={"NINPassportButton"}
                htmlFor={"NINPassportInput"}
                error={!!carDealerErrors.NINPassportError}
                fullWidth
                onClick={openNINPassporDialog}
              >
                <img src={Upload} alt="upload icon" className="me-2" />{" "}
                {carDealerData.NINPassport
                  ? carDealerData.NINPassport.name
                  : "Upload"}
              </UploadButton>
              <KraneInput
                onChange={handleFiles}
                name="NINPassport"
                hidden
                id={"NINPassportInput"}
                type="file"
              />
              <FormHelperText>
                {!!carDealerErrors.NINPassportError
                  ? carDealerErrors.NINPassportError
                  : ""}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <InputLabel
                htmlFor={"CoOInput"}
                shrink
                sx={{ fontSize: "1.2rem" }}
              >
                Certificate of Ownership doc
              </InputLabel>
              <UploadButton
                id={"CoOButton"}
                htmlFor={"CoOInput"}
                error={!!carDealerErrors.CoOError}
                fullWidth
                onClick={openCoODialog}
              >
                <img src={Upload} alt="upload icon" className="me-2" />{" "}
                {carDealerData.CoO ? carDealerData.CoO.name : "Upload"}
              </UploadButton>
              <KraneInput
                onChange={handleFiles}
                name="CoO"
                hidden
                id={"CoOInput"}
                type="file"
              />
              <FormHelperText>
                {!!carDealerErrors.CoOError ? carDealerErrors.CoOError : ""}
              </FormHelperText>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl
                variant="standard"
                fullWidth
                error={!!carDealerErrors.bankNameError}
              >
                <InputLabel
                  shrink
                  htmlFor={"bankNameInput"}
                  sx={{ fontSize: "1.2rem" }}
                >
                  Bank Name
                </InputLabel>
                <KraneInput
                  id={"bankNameInput"}
                  value={carDealerData.bankName}
                  name="bankName"
                  onChange={handleChange}
                />
                <FormHelperText>
                  {!!carDealerErrors.bankNameError
                    ? carDealerErrors.bankNameError
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl
                variant="standard"
                fullWidth
                error={!!carDealerErrors.accountNumberError}
              >
                <InputLabel
                  shrink
                  htmlFor={"accountNumberInput"}
                  sx={{ fontSize: "1.2rem" }}
                >
                  Account Number
                </InputLabel>
                <KraneInput
                  id={"accountNumberInput"}
                  value={carDealerData.accountNumber}
                  name="accountNumber"
                  onChange={handleChange}
                />
                <FormHelperText>
                  {!!carDealerErrors.accountNumberError
                    ? carDealerErrors.accountNumberError
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FormControl
                variant="standard"
                fullWidth
                error={!!carDealerErrors.accountNameError}
              >
                <InputLabel
                  shrink
                  htmlFor={"accountNameInput"}
                  sx={{ fontSize: "1.2rem" }}
                >
                  Account Name
                </InputLabel>
                <KraneInput
                  id={"accountNameInput"}
                  value={carDealerData.accountName}
                  name="accountName"
                  onChange={handleChange}
                />
                <FormHelperText>
                  {!!carDealerErrors.accountNameError
                    ? carDealerErrors.accountNameError
                    : ""}
                </FormHelperText>
              </FormControl>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={5}>
                  <KraneInput
                    id={"carSearchInput"}
                    value={carSearch}
                    fullWidth
                    name="carSearch"
                    onChange={handleChange}
                    sx={{ border: "none!important" }}
                    startAdornment={<SearchIcon />}
                  />
                </Grid>
                <Grid item xs={7} md={3}>
                  <Button
                    fullWidth
                    size="large"
                    className="text-capitalize"
                    sx={{
                      bgcolor: "#5806C30D",
                      height: "3.6rem",
                      color: "#000",
                    }}
                  >
                    Search
                  </Button>
                </Grid>
                <Grid item xs={5} md={4}>
                  {/* <Paper sx={{ height: "100%" }}>
                    <List>
                      <ListItemButton onClick={handleClick}>
                        <ListItemText primary="Car Filters" />
                        {open ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                    </List>
                  </Paper> */}
                  <Button
                    onClick={handleClick}
                    fullWidth
                    sx={{ height: "3.6rem" }}
                  >
                    Car Filter
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    sx={{ width: "100%", maxWidth: "100%" }}
                  >
                    <MenuItem
                      sx={{ width: "100%" }}
                      onClick={handleToyotaClick}
                    >
                      <ToyotaIcon /> Toyota{" "}
                      {openToyota ? (
                        <ExpandMore sx={{ ml: 10 }} />
                      ) : (
                        <KeyboardArrowRight sx={{ ml: 10 }} />
                      )}
                    </MenuItem>
                    <Collapse in={openToyota} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }}>
                          <ListItemIcon>
                            <Checkbox />
                          </ListItemIcon>
                          <ListItemText primary="Toyota Corolla 2002" />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }}>
                          <ListItemIcon>
                            <Checkbox />
                          </ListItemIcon>
                          <ListItemText primary="Toyota Corolla 2003" />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }}>
                          <ListItemIcon>
                            <Checkbox />
                          </ListItemIcon>
                          <ListItemText primary="Toyota Corolla 1999" />
                        </ListItemButton>
                        <ListItemButton sx={{ pl: 4 }}>
                          <ListItemIcon>
                            <Checkbox />
                          </ListItemIcon>
                          <ListItemText primary="Toyota Corolla 2001" />
                        </ListItemButton>
                      </List>
                    </Collapse>
                    <Divider />
                    <MenuItem>
                      <NissanIcon /> Nissan{" "}
                      <KeyboardArrowRight sx={{ ml: 10 }} />
                    </MenuItem>
                    <Divider />
                    <MenuItem>
                      <HondaIcon /> Honda <KeyboardArrowRight sx={{ ml: 10 }} />
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {carData.map((car) => (
                  <Grid
                    item
                    xs={6}
                    md={4}
                    lg={3}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      border: 0,
                      background: 0,
                    }}
                    component={"button"}
                    index={car.title}
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenCar(true);
                    }}
                  >
                    <Box
                      sx={{
                        bgcolor: "#F2F2F2",
                        width: "100%",
                        height: {
                          xs: "8rem",
                          md: "10rem",
                          lg: "10rem",
                          xl: "16rem",
                        },
                        border: "1px solid #F8F8F8",
                      }}
                    ></Box>
                    <Box
                      sx={{
                        bgcolor: "#F8F8F8",
                        width: "100%",
                        height: { xs: "3rem", lg: "4rem", xl: "6rem" },
                        display: "flex",
                        p: { xs: 0.5, lg: 1 },
                      }}
                    >
                      <img src={car.logo} height={"50%"} width={"auto"} />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          ml: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: {
                              xs: "0.6rem",
                              md: "0.7rem",
                              xl: "1.25rem",
                            },
                            fontFamily: "'Outfit', sans-serif",
                            lineHeight: "100%",
                          }}
                          className="fw-bold"
                        >
                          {car.title}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: {
                              xs: "0.5rem",
                              md: "0.6rem",
                              xl: "1rem",
                            },
                            fontFamily: "'Outfit', sans-serif",
                            lineHeight: "100%",
                          }}
                          className="text-muted"
                        >
                          {car.subtitle}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: {
                              xs: "0.8rem",
                              md: "1rem",
                              xl: "1.5rem",
                            },
                            fontFamily: "'Outfit', sans-serif",
                            lineHeight: "100%",
                          }}
                          className="fw-normal mt-auto d-flex"
                        >
                          <Typography
                            sx={{
                              color: "#E1BF41",
                              lineHeight: "100%",
                              fontSize: {
                                xs: "0.6rem",
                                md: "0.8rem",
                                xl: "1.25rem",
                              },
                              fontFamily: "'Outfit', sans-serif",
                            }}
                          >
                            &#8358;&nbsp;
                          </Typography>
                          {car.price}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
              <Box
                sx={{
                  width: "100%",
                  display: "grid",
                  ml: "auto",
                  mr: { xs: "auto", md: 0 },
                  mt: 4,
                }}
              >
                <Pagination
                  sx={{ justifySelf: "end" }}
                  count={10}
                  variant="outlined"
                  color="primary"
                />
              </Box>
            </Grid>
          </>
        )}
      </Grid>
      <Modal
        open={openCar}
        onClose={handleCarClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper sx={modalStyle}>
          <Breadcrumbs>
            <Link underline="hover" color="primary" href="/">
              Krane Car Shop
            </Link>
            <Typography>Car Selection</Typography>
          </Breadcrumbs>
          <Typography
            variant="h3"
            mt={1}
            sx={{ fontSize: { xs: "2rem", md: "3rem" } }}
          >
            Choose Car
          </Typography>
          <Grid container spacing={2} mt={3}>
            <Grid item xs={12} md={7} sx={{ p: 1 }}>
              <Box
                sx={{ bgcolor: "#F8F8F8", border: "1px solid #F8F8F8", p: 2 }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <img src={carFront} width={"100%"} height={"auto"} />
                  </Grid>
                  <Grid item xs={3}>
                    <Paper
                      elevation={0}
                      sx={{
                        p: { xs: 1, md: 2 },
                        width: "100%",
                        height: { xs: "2rem", sm: "5rem", xl: "8rem" },
                      }}
                    >
                      <Box
                        width={"100%"}
                        height={"100%"}
                        sx={{ overflow: "hidden" }}
                      >
                        <img
                          src={carFrontside}
                          width={"100%"}
                          height={"auto"}
                        />
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid item xs={3}>
                    <Paper
                      elevation={0}
                      sx={{
                        p: { xs: 1, md: 2 },
                        width: "100%",
                        height: { xs: "2rem", sm: "5rem", xl: "8rem" },
                      }}
                    >
                      <Box
                        width={"100%"}
                        height={"100%"}
                        sx={{ overflow: "hidden" }}
                      >
                        <img src={carBackside} width={"100%"} height={"auto"} />
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid item xs={3}>
                    <Paper
                      elevation={0}
                      sx={{
                        p: { xs: 1, md: 2 },
                        width: "100%",
                        height: { xs: "2rem", sm: "5rem", xl: "8rem" },
                      }}
                    >
                      <Box
                        width={"100%"}
                        height={"100%"}
                        sx={{ overflow: "hidden" }}
                      >
                        <img src={carInterior} width={"100%"} height={"auto"} />
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={5} sx={{ p: 1 }}>
              <Box
                sx={{ bgcolor: "#F8F8F8", border: "1px solid #F8F8F8", p: 2 }}
              >
                <Typography
                  variant="h4"
                  sx={{ fontSize: { xs: "1.75rem", md: "2.25rem" } }}
                >
                  Car Specifications
                </Typography>
                <Divider
                  sx={{
                    border: "1px solid #C4C4C4",
                    mb: { xs: 1, md: 2 },
                    mt: { xs: 1, md: 2 },
                  }}
                />
                <Typography
                  variant="h6"
                  sx={{ fontSize: { xs: "1rem", md: "1.25rem" } }}
                >
                  <ToyotaIcon /> Toyota
                </Typography>
                <Typography
                  variant="h4"
                  mt={1}
                  sx={{ fontSize: { xs: "1.75rem", md: "2.25rem" } }}
                >
                  Toyota Corolla 2002
                </Typography>
                <Grid container sx={{ mt: 3 }}>
                  <Grid item xs={6} md={5}>
                    <Typography
                      mb={1}
                      className="text-muted"
                      sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
                    >
                      Transmission
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={5}>
                    <Typography
                      fontWeight={500}
                      mb={1}
                      sx={{
                        fontFamily: "Outfit, 'sans-serif'",
                        fontSize: { xs: "0.8rem", md: "1rem" },
                      }}
                    >
                      Automatic
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={5}>
                    <Typography
                      mb={1}
                      className="text-muted"
                      sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
                    >
                      Engine Type
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={5}>
                    <Typography
                      fontWeight={500}
                      mb={1}
                      sx={{
                        fontFamily: "Outfit, 'sans-serif'",
                        fontSize: { xs: "0.8rem", md: "1rem" },
                      }}
                    >
                      Cylinder V4
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={5}>
                    <Typography
                      mb={1}
                      className="text-muted"
                      sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
                    >
                      Fuel Type
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={5}>
                    <Typography
                      fontWeight={500}
                      mb={1}
                      sx={{
                        fontFamily: "Outfit, 'sans-serif'",
                        fontSize: { xs: "0.8rem", md: "1rem" },
                      }}
                    >
                      PMS(Petrol)
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={5}>
                    <Typography
                      mb={1}
                      className="text-muted"
                      sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
                    >
                      Mileage
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={5}>
                    <Typography
                      fontWeight={500}
                      mb={1}
                      sx={{
                        fontFamily: "Outfit, 'sans-serif'",
                        fontSize: { xs: "0.8rem", md: "1rem" },
                      }}
                    >
                      186,411
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={5}>
                    <Typography
                      mb={1}
                      className="text-muted"
                      sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
                    >
                      Engine Size
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={5}>
                    <Typography
                      fontWeight={500}
                      mb={1}
                      sx={{
                        fontFamily: "Outfit, 'sans-serif'",
                        fontSize: { xs: "0.8rem", md: "1rem" },
                      }}
                    >
                      Engine Type
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={5}>
                    <Typography
                      mb={1}
                      className="text-muted"
                      sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
                    >
                      Engine Capacity
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={5}>
                    <Typography
                      fontWeight={500}
                      mb={1}
                      sx={{
                        fontFamily: "Outfit, 'sans-serif'",
                        fontSize: { xs: "0.8rem", md: "1rem" },
                      }}
                    >
                      1.8 Litres
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={5}>
                    <Typography
                      mb={1}
                      className="text-muted"
                      sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
                    >
                      Year
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={5}>
                    <Typography
                      fontWeight={500}
                      mb={1}
                      sx={{
                        fontFamily: "Outfit, 'sans-serif'",
                        fontSize: { xs: "0.8rem", md: "1rem" },
                      }}
                    >
                      2002
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={5}>
                    <Typography
                      mb={1}
                      className="text-muted"
                      sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
                    >
                      Color
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={5}>
                    <Typography
                      fontWeight={500}
                      mb={1}
                      sx={{
                        fontFamily: "Outfit, 'sans-serif'",
                        fontSize: { xs: "0.8rem", md: "1rem" },
                      }}
                    >
                      Silver
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={5}>
                    <Typography
                      mb={1}
                      className="text-muted"
                      sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
                    >
                      Drive Type
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={5}>
                    <Typography
                      fontWeight={500}
                      mb={1}
                      sx={{
                        fontFamily: "Outfit, 'sans-serif'",
                        fontSize: { xs: "0.8rem", md: "1rem" },
                      }}
                    >
                      Front Wheel Drive (FWD)
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={5}>
                    <Typography
                      mb={1}
                      className="text-muted"
                      sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
                    >
                      Airbag
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={5}>
                    <Typography
                      fontWeight={500}
                      mb={1}
                      sx={{
                        fontFamily: "Outfit, 'sans-serif'",
                        fontSize: { xs: "0.8rem", md: "1rem" },
                      }}
                    >
                      Yes
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={5}>
                    <Typography
                      mb={1}
                      className="text-muted"
                      sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
                    >
                      Tokunbo/Nig. used
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={5}>
                    <Typography
                      fontWeight={500}
                      mb={1}
                      sx={{
                        fontFamily: "Outfit, 'sans-serif'",
                        fontSize: { xs: "0.8rem", md: "1rem" },
                      }}
                    >
                      Tokunbo
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={5}>
                    <Typography
                      mb={1}
                      className="text-muted"
                      sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
                    >
                      Diagnostic Report
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={5}>
                    <Typography
                      fontWeight={500}
                      mb={1}
                      sx={{
                        fontFamily: "Outfit, 'sans-serif'",
                        fontSize: { xs: "0.8rem", md: "1rem" },
                      }}
                    >
                      Available
                    </Typography>
                  </Grid>
                </Grid>
                <Typography
                  className="text-muted"
                  mt={2}
                  sx={{ fontSize: { xs: "0.8rem", md: "1rem" } }}
                >
                  PRICE
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "1rem",
                      md: "1.5rem",
                      xl: "2rem",
                    },
                    fontFamily: "'Outfit', sans-serif",
                    lineHeight: "100%",
                  }}
                  className="fw-bold mt-auto d-flex"
                >
                  <Typography
                    sx={{
                      color: "#E1BF41",
                      lineHeight: "100%",
                      fontSize: {
                        xs: "0.8rem",
                        md: "1.25rem",
                        xl: "1.5rem",
                      },
                      fontFamily: "'Outfit', sans-serif",
                    }}
                  >
                    &#8358;&nbsp;
                  </Typography>
                  3,500,000.00
                </Typography>
                <Divider
                  sx={{
                    border: "1px solid #C4C4C4",
                    mb: { xs: 1, md: 2 },
                    mt: { xs: 2, md: 4 },
                  }}
                />
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={4} lg={4} className="d-flex">
                    <CheckCircleOutline
                      sx={{
                        fill: "#17D614",
                        fontSize: { xs: "0.5rem", md: "0.8rem" },
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "0.8rem",
                          md: "1rem",
                          lineHeight: "100%",
                        },
                      }}
                    >
                      Car Tracker
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={4} lg={4} className="d-flex">
                    <CheckCircleOutline
                      sx={{
                        fill: "#17D614",
                        fontSize: { xs: "0.5rem", md: "0.8rem" },
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "0.8rem",
                          md: "1rem",
                          lineHeight: "100%",
                        },
                      }}
                    >
                      Car Papers
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={4} lg={4} className="d-flex">
                    <CheckCircleOutline
                      sx={{
                        fill: "#17D614",
                        fontSize: { xs: "0.5rem", md: "0.8rem" },
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "0.8rem",
                          md: "1rem",
                          lineHeight: "100%",
                        },
                      }}
                    >
                      Insurance Papers
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={4} lg={4} className="d-flex">
                    <CheckCircleOutline
                      sx={{
                        fill: "#17D614",
                        fontSize: { xs: "0.5rem", md: "0.8rem" },
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "0.8rem",
                          md: "1rem",
                          lineHeight: "100%",
                        },
                      }}
                    >
                      Custom Papers
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    </>
  );
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", lg: "75%" },
  // bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: { xs: 2, md: 5 },
  maxHeight: "90%",
  overflowY: "scroll",
};

const carData = [
  {
    title: "Toyota Corolla 2002",
    subtitle: "Silver 2000M",
    logo: toyotaLogo,
    price: "3,500,000.00",
  },
  {
    title: "Toyota Corolla 2001",
    subtitle: "Grey 2000M",
    logo: toyotaLogo,
    price: "3,500,000.00",
  },
  {
    title: "Honda Accord 2020",
    subtitle: "Silver 2000M",
    logo: hondaLogo,
    price: "3,000,000.00",
  },
  {
    title: "Toyota Corolla 2002",
    subtitle: "Silver 2000M",
    logo: toyotaLogo,
    price: "3,500,000.00",
  },
  {
    title: "Honda Accord 1999",
    subtitle: "Silver 2000M",
    logo: hondaLogo,
    price: "3,500,000.00",
  },
  {
    title: "Toyota Corolla 2001",
    subtitle: "Silver 2000M",
    logo: toyotaLogo,
    price: "3,500,000.00",
  },
  {
    title: "Toyota Corolla 2002",
    subtitle: "Black 2000M",
    logo: toyotaLogo,
    price: "3,500,000.00",
  },
  {
    title: "Honda Civic 2003",
    subtitle: "Silver 2000M",
    logo: hondaLogo,
    price: "3,500,000.00",
  },
];

export default CarDealerInfo;
