import { Box, Grid, Typography } from "@mui/material"
import Sidecar from "../assets/images/sidecar.png";
import logo from "../assets/images/krane-logo.png"

const Side = () => {

    return (
        
        <Grid item xs={0} sm={4} className="sideCar w-100">
            <Box className="w-100 vh-100 car-container d-flex flex-column align-items-center">
                <Box className='car-text-box' color={'#fff'}>
                    <img src={logo} alt="Krane logo" width={'50%'} height={'auto'}/>
                    <Typography fontSize={'3rem'} fontWeight={700} lineHeight={'100%'} mt={6}>
                        Get on board
                    </Typography>
                    <Typography fontWeight={500} fontSize={'0.8rem'} mt={3}>
                        For all transport entrepreneurs, Bolt, Uber, indriver, Rida and so on
                    </Typography>
                </Box>
                <img src={Sidecar} alt="krane car side" width="100%" height="100%"/>
            </Box>
    </Grid>
    )
}

export default Side;