import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Country, State } from 'country-state-city';
import Scrollbar from 'perfect-scrollbar-react';

import {
    Box, Grid, Typography,
    Link, Button, FormControl,
    InputAdornment, InputLabel,
    IconButton, Select, MenuItem,
    CircularProgress, FormHelperText
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { KraneInput } from '../../components/inputs';
import Side from "../../components/ind-sidecar";

import logoDark from "../../assets/images/logo2.png";


const Signup = () => {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [country, setCountry] = useState("");
    const [states, setStates] = useState("");
    const [email, setEmail] = useState("");
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");

    const [firstNameError, setFirstNameError] = useState("");
    const [middleNameError, setMiddleNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [countryError, setCountryError] = useState("");
    const [statesError, setStatesError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [password1Error, setPassword1Error] = useState("");
    const [password2Error, setPassword2Error] = useState("");

    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const [allStates, setAllStates] = useState([]);
    const [loading, setLoading] = useState(false);

    function getCountries() {
        const allCountries = Country.getAllCountries();
        return Object.entries(allCountries).map(country => (
            <MenuItem value={country[1].name} name={country[1].isoCode} key={country[1].isoCode}>
                {country[1].name}
            </MenuItem>
        ))
    }

    function renderStates() {
        return (allStates.length ? (
            <><Select id={'stateInput'} value={states}
                onChange={e => setStates(e.target.value)}
                input={<KraneInput />}
                error={!!statesError}>
                {Object.entries(allStates).map(state => (
                    <MenuItem value={state[1].name} id={state[1].isoCode} key={state[1].isoCode}>{state[1].name}</MenuItem>
                ))}
            </Select>
                <FormHelperText sx={{ color: "danger" }}>{!!statesError ?
                    (statesError) : ('')}</FormHelperText></>
        ) : (
            <Select id={'stateInput'} value={"Select a country"}
                input={<KraneInput />}
                error={!!statesError}
                helperText={!!statesError ?
                    (statesError) : ('')}>
                <MenuItem value="Select a country">Select a country</MenuItem>
            </Select>
        ))
    }

    const getStates = (e) => {
        const el = document.querySelector(`[data-value='${e}']`);
        setAllStates(State.getStatesOfCountry(el.getAttribute('name')));
        renderStates();
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const handleSignup = async () => {
        setLoading(true);
        setFirstNameError("");
        setLastNameError("");
        setMiddleNameError("");
        setEmailError("");
        setCountryError("");
        setStatesError("");
        setPassword1Error("");
        setPassword2Error("");

        navigate('/account/individual/registration/verification');

        // if (password1 === password2)
        // {
        //     const userData = {
        //         first_name: firstName,
        //         last_name: lastName,
        //         middle_name: middleName,
        //         email: email,
        //         password: password1,
        //         country: country,
        //         state: states
        //     }
        //     try {
        //         await axios
        //         .post(`https://krane-staging.herokuapp.com/auth/signup`, userData)
        //         .then((res) => {
        //           setLoading(false);
        //           toast.success("Account successfully created");
        //         });
        //       } catch (error) {
        //         let errorData = (error.response.data.message);
        //         // errorData = errorData;
        //         console.log(errorData);
        //         toast.error("You have errors in your form");
        //         setLoading(false);
        //         if (errorData.indexOf('first_name')>=0)
        //         {
        //             setFirstNameError(errorData[errorData.indexOf('first_name')+1])
        //         }
        //         if (errorData.indexOf('last_name')>=0)
        //         {
        //             setFirstNameError(errorData[errorData.indexOf('last_name')+1])
        //         }
        //         if (errorData.indexOf('middle_name')>=0)
        //         {
        //             setFirstNameError(errorData[errorData.indexOf('middle_name')+1])
        //         }
        //       }
        // } else
        // {
        //     setPassword1Error("Passwords do not match");
        //     setPassword2Error("Passwords do not match");
        //     toast.error("You have errors in your form");
        //     setLoading(false);
        // }
    }


    return (
        <Box>
            <Scrollbar>
                <Grid container spacing={0} className="container-full  vh-100">
                    {/* <TopNavBar /> */}
                    <Grid item xs={12} sm={8} className="form-outer">
                        <Box pr={6} pl={25} py={5} className="form-container" component="form" onSubmit={handleSignup}>
                            <Typography sx={{ display: { xs: 'none', sm: 'block' } }} className="text-end" variant="subtitle2" fontSize="1rem">
                                Already have an account?.
                                <Link href='/account/login' underline="none">Log in</Link>
                            </Typography>
                            <Box mt={{ xs: 2 }} sx={{ display: { xs: 'block', sm: 'none' } }}>
                                <Link href="/" underline="none">
                                    <img src={logoDark} alt="Krane logo dark" width='40%' height={'auto'} />
                                </Link>
                            </Box>
                            <Box mt={{ xs: 7, sm: 10 }} height={"auto"} className="form-text">
                                <Typography variant="h4" color="primary" fontWeight={500}>
                                    Create your account
                                </Typography>
                                <Typography variant="subtitle1" fontWeight={500} mt={1}>
                                    Kindly input the following information to create your account
                                </Typography>
                            </Box>
                            <Grid container mt={2} spacing={3}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl variant="standard" fullWidth error={!!firstNameError}>
                                        <InputLabel shrink htmlFor={'firstNameInput'} sx={{ fontSize: '1.2rem' }} >
                                            First Name
                                        </InputLabel>
                                        <KraneInput id={'firstNameInput'} value={firstName}
                                            onChange={e => setFirstName(e.target.value)}
                                        />
                                        <FormHelperText>{!!firstNameError ?
                                            (firstNameError) : ('')}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl variant="standard" fullWidth error={!!middleNameError}>
                                        <InputLabel shrink htmlFor={'middleNameInput'} sx={{ fontSize: '1.2rem' }} >
                                            Middle Name
                                        </InputLabel>
                                        <KraneInput id={'middleNameInput'} value={middleName}
                                            onChange={e => setMiddleName(e.target.value)}
                                        />
                                        <FormHelperText>{!!middleNameError ?
                                            (middleNameError) : ('')}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl variant="standard" fullWidth error={!!lastNameError}>
                                        <InputLabel shrink htmlFor={'lastNameInput'} sx={{ fontSize: '1.2rem' }} >
                                            Last Name
                                        </InputLabel>
                                        <KraneInput id={'lastNameInput'} value={lastName}
                                            onChange={e => setLastName(e.target.value)}
                                        />
                                        <FormHelperText>{!!lastNameError ?
                                            (lastNameError) : ('')}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl variant="standard" fullWidth error={!!countryError}>
                                        <InputLabel shrink htmlFor={'countryInput'} sx={{ fontSize: '1.2rem' }} >
                                            Country
                                        </InputLabel>
                                        <Select id={'countryInput'} value={country}
                                            onChange={e => { setCountry(e.target.value); getStates(e.target.value) }}
                                            input={<KraneInput />}
                                        >
                                            {getCountries()}
                                        </Select>
                                        <FormHelperText>{!!countryError ?
                                            (countryError) : ('')}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl variant="standard" fullWidth>
                                        <InputLabel shrink htmlFor={'stateInput'} sx={{ fontSize: '1.2rem' }} >
                                            State
                                        </InputLabel>
                                        {renderStates()}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl variant="standard" fullWidth error={!!emailError}  >
                                        <InputLabel shrink htmlFor={'emailInput'} sx={{ fontSize: '1.2rem' }} >
                                            Email
                                        </InputLabel>
                                        <KraneInput id={'emailInput'} value={email}
                                            onChange={e => setEmail(e.target.value)}
                                            type="email"
                                            autoComplete="off"
                                            inputProps={{
                                                autoComplete: 'new-password',
                                            }} />
                                        <FormHelperText>{!!emailError ?
                                            (emailError) : ('')}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl variant="standard" fullWidth error={!!password1Error}>
                                        <InputLabel shrink htmlFor='password1Input' sx={{ fontSize: '1.2rem' }} >
                                            Password
                                        </InputLabel>
                                        <KraneInput id='password1Input'
                                            type={showPassword ? 'text' : 'password'}
                                            value={password1}
                                            // input={<OutlinedInput />}
                                            autoComplete="off"
                                            onChange={e => setPassword1(e.target.value)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end">
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>}
                                            inputProps={{
                                                autoComplete: 'new-password',
                                            }}
                                        />
                                        <FormHelperText>{!!password1Error ? (password1Error) : ('')}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <FormControl variant="standard" fullWidth error={!!password2Error}>
                                        <InputLabel shrink htmlFor='password2Input' sx={{ fontSize: '1.2rem' }} >
                                            Password
                                        </InputLabel>
                                        <KraneInput id='password2Input'
                                            type={showPassword2 ? 'text' : 'password'}
                                            value={password2}
                                            autoComplete="off"
                                            onChange={e => setPassword2(e.target.value)}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword2}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword2 ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>}
                                            inputProps={{
                                                autoComplete: 'new-password',
                                            }}
                                            aria-describedby="password2-error-text"
                                        />
                                        <FormHelperText>{!!password2Error ?
                                            (password2Error) : ('')}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container mt={4} spacing={3}>
                                <Grid item xs={0} sm={0} md={6}></Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Button sx={{ height: { xs: '70px', sm: '50px' } }} className="w-100 text-capitalize" disabled={loading} variant="contained" onClick={handleSignup}>
                                        {loading ? (
                                            <CircularProgress size={20} />
                                        ) : (
                                            'Submit'
                                        )}
                                    </Button>
                                </Grid>
                            </Grid>
                            <Typography sx={{ display: { xs: 'block', sm: 'none' } }} className="text-center mt-3" variant="subtitle2" fontSize="1rem">
                                Already have an account?.
                                <Link href='/account/login' underline="none">Log in</Link>
                            </Typography>
                        </Box>
                    </Grid>
                    <Side />
                </Grid>
            </Scrollbar>
        </Box>
    )
}

export default Signup;