import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { Paper, Switch } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Dashboards from "./dashboardPage/Dashboards";

import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { navbarStyles } from "./styles";

import { dashboardItems } from "./dashboardItems";
import { Outlet, Route, useNavigate } from "react-router-dom";

import logo from "../../../assets/images/logo2.png";
import Profile from "./dashboardPage/Profile";
import { useLocation } from "react-router-dom";
import Payment from "./dashboardPage/Payment";

const drawerWidth = 240;

const Layout = ({ children }, props) => {
  const navigate = useNavigate();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();
  const pathname = location.pathname;

  // console.log({ pathname });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "3rem",
        }}
      >
        <img src={logo} className="w-[60px]" />
      </Box>

      <Divider />
      <List>
        {dashboardItems.map((item, index) => (
          <ListItemButton key={item.id} onClick={() => navigate(item.route)}>
            <ListItemIcon sx={navbarStyles.icons}>{item.icon}</ListItemIcon>
            <ListItemText sx={navbarStyles.text} primary={item.label} />
          </ListItemButton>
        ))}
      </List>
      <Divider />
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex", overflow: "hidden" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          backgroundColor: "#ffffff",
          color: "black",
          // height: "100px",
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "1rem",
              padding: "0.5rem",
            }}
          >
            <Paper
              elevation={1}
              sx={{
                backgroundColor: "#F8F8F8",
                padding: "0.2rem",
                color: "#292D32",
                height: "58px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "58px",
              }}
            >
              <NotificationsNoneIcon />
            </Paper>

            <Paper
              elevation={1}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                backgroundColor: "#F8F8F8",
                padding: "0.2rem",
                height: "58px",
                width: "198px",
              }}
            >
              <Paper
                elevation={1}
                sx={{
                  backgroundColor: "white",
                  color: "#292D32",
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <PermIdentityIcon />
              </Paper>

              <Box>
                <Typography
                  variant="subtitle1"
                  component="h6"
                  sx={{ fontSize: "14px", fontWeight: "normal" }}
                >
                  {" "}
                  Damilola Olayiola
                </Typography>
                <Typography
                  variant="body1"
                  component="body1"
                  sx={{ fontSize: "14px", fontWeight: "light" }}
                >
                  {" "}
                  user
                </Typography>
              </Box>
            </Paper>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundColor: "#F5F5F5",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />

        <div>{children}</div>
      </Box>
    </Box>
  );
};

export default Layout;
