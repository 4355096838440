import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import logo from "../../assets/images/logo2.png"
import { Divider, Link, useTheme, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { ReactComponent as IndIcon } from "../../assets/images/individual.svg"
import { ReactComponent as CoopIcon } from "../../assets/images/coop.svg"
import { ChevronRight } from '@mui/icons-material';


function TopNavBar() {
  const navigate = useNavigate();
  const [anchorElIndividual, setAnchorElIndividual] = React.useState(null);

  const handleOpenIndividualMenu = (event) => {
    setAnchorElIndividual(event.currentTarget);
  };
  const handleCloseIndividualMenu = () => {
    setAnchorElIndividual(null);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container maxWidth="xl" sx={{ color: "#000", paddingLeft: { xs: 0, xl: "24px" }, paddingRight: { xs: 0, xl: "24px" } }}>
      <AppBar position="static" sx={{ boxShadow: "none", backgroundColor: "#fff", paddingX: { xs: "2rem", md: "3rem", xl: "5rem" }, }}>
        <Toolbar disableGutters>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
            <Link
              href="/"
            >
              <img src={logo} alt="krane logo" width={isMobile ? "80%" : "100%"} />
            </Link>
            <Box sx={{ display: "flex", alignItems: "center", gap: {xs:1,sm:2} }}>
              <Button
                onClick={handleOpenIndividualMenu}
                aria-label="Individual menu"
                aria-controls="individual-menu"
                aria-haspopup="true"
                sx={{
                  my: 6, backgroundColor: "#F8F8F8",
                  fontSize: "1rem", fontWeight: 600, borderRadius: 0,
                  padding: { xs: "0.5rem 1rem", sm: "1.25rem 3.5rem", lg: "1.25rem 5rem" },
                  display: "flex", gap: 1, alignItems: "center", textTransform: "capitalize"
                }}
              >
                Join Us {anchorElIndividual ? <ExpandLess /> : <ExpandMore />}
              </Button>
              <Menu
                id="individual-menu"
                anchorEl={anchorElIndividual}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElIndividual)}
                onClose={handleCloseIndividualMenu}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    borderRadius: 0,
                    mt: 1.5,
                    '&::before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      left: 40,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
              >
                <MenuItem>
                  <Link underline='none' href='/account/individual/registration' textAlign="center"
                    sx={{ display: "flex", alignItems: "center", fontWeight: 600, gap: 1 }}
                  >
                    <IndIcon />Individual <ChevronRight sx={{ ml: { xs: "3rem", md: "4.3rem" } }} />
                  </Link>
                </MenuItem>
                <Divider variant='middle' />
                <MenuItem>
                  <Link underline='none' href='/account/cooperative/registration' textAlign="center"
                    sx={{ display: "flex", alignItems: "center", fontWeight: 600, gap: 1 }}
                  >
                    <CoopIcon />Cooperative <ChevronRight sx={{ ml: { xs: "3rem", md: "3rem" } }} />
                  </Link>
                </MenuItem>
              </Menu>
              <Button
                onClick={() => navigate('/account/login')}
                aria-label="Cooperative menu"
                aria-controls="cooperative-menu"
                aria-haspopup="true"
                variant='contained'
                disableElevation
                sx={{
                  my: 6, fontSize: "1rem", fontWeight: 600, borderRadius: 0,
                  padding: { xs: "0.5rem 1rem", sm: "1.25rem 3.5rem", lg: "1.25rem 5rem" }, textTransform: "capitalize"
                }}
              >
                Sign In
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Container>
  );
}
export default TopNavBar;