import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

const FaqList = [
  {
    question: "Do you make provisions for insurance ?",
    answer: "Yes, and we would be offering comprehensive insurance for all the cars we finance.",
  },
  {
    question: "Do you have a list of cars that you finance ?",
    answer: "From our research we understand that transport entrepreneurs love the Toyota Corolla 03/04, but we have a list that also includes other brands and years of Toyota, Nissan, Hyundai and Kia. We are also open to any other car interests.",
  },
  {
    question: "Do you sell the vehicles ?",
    answer: "No, we only provide financing for the vehicles. But we have a list of trusted car dealers that customers can choose get their cars from.",
  },
  {
    question: "How long can car repayments last for ?",
    answer: "The car repayment is dependent on the amount borrowed and the tenure, but we have a max payment period of 36 months.",
  },
  {
    question: "How long does it take to process and get an asset financed ?",
    answer: "We have an optimal time of 1 day(24hrs). This includes application to request, to inspection and to car papers.",
  },
  {
    question: "Do you accept Upfront/Deposit payments ?",
    answer: "Yes, for certain loan amounts. But not always.",
  },
];

const Faq = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      {FaqList.map((item) => {
        return (
          <Box sx={{ marginBottom: "1rem" }}>
            <Accordion defaultExpanded={true} sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: isMobile ? "20px" : "24px",
                    fontWeight: "bold",
                    marginY: "1rem",
                    marginX: "2rem",
                    color: "#1C1C1C",
                    fontFamily: "Aventa"
                  }}
                >
                  {item.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: { xs: "0.75rem", md: "1rem" },
                    fontWeight: "bold",
                    marginBottom: "1rem",
                    marginX: "2rem",
                    width: {xs:"80%", sm:"500px"},
                    color: "#8F8F8F",
                    fontFamily: "Aventa"
                  }}
                >
                  {item.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>

          </Box>
        );
      })}
    </Box>
  );
};

export default Faq;
