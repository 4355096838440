import { Box, Typography } from "@mui/material"


export default function Salutation() {
    return (
        <Box>
        <Typography
          variant="h3"
          fontWeight={700}
          sx={{
            fontSize: { xs: "2.25rem", md: "2.75rem", lg: "3rem" },
            pr: { xs: 2, sm: 0 },
          }}
        >
          Hello Damilola 👋
        </Typography>
        <Typography
          variant="subtitle1"
          fontWeight={500}
          mt={1}
          color={"#858585"}
          pr={{ xs: 2, sm: 0 }}
        >
          An apple a day keeps the Doctor away 🍏. Eat an apple today
        </Typography>
        </Box>
    )
}